// //utils
import { Mobile, Desktop, Tablet } from "../../utils/responsive";

//components
import { TableFileForm } from "../components/form/tableFile";
//data
import {  dataColumnsFile, dataSelectFile } from "../data/file";

//translate
import { useTranslation } from "react-i18next"

export const TableFile = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Mobile>
                <TableFileForm
                    colum={dataColumnsFile(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleFile")} 
                    select={dataSelectFile(t)}
                />
            </Mobile>
            <Tablet>
                <TableFileForm
                    colum={dataColumnsFile(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleFile")} 
                    select={dataSelectFile(t)}
                />
            </Tablet>
            <Desktop>
                <TableFileForm
                    colum={dataColumnsFile(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleFile")}
                    select={dataSelectFile(t)}
                />
            </Desktop>
        </>
    );
};
