import { memo } from 'react'

import { StyledMultiline } from "../styles/materialUi";
import { WrapperInput } from "../../zUsers/styles/Input";
import { InterfaceSliderForm } from "../interfaces/score";

export  const UseFormControl = memo(({
    value,
    name,
    handleChange,
    disable
}: InterfaceSliderForm) => {
    return (
        <WrapperInput>
            <StyledMultiline
                id="outlined-multiline-static"
                style={{ width: "100%" }}
                multiline
                rows={4}
                value={value}
                disabled= { disable }
                onChange={(e) =>
                    handleChange({ field: name, value: e.target.value })
                }
            />
        </WrapperInput>
    );
})