//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'

//components
import { Loginn } from '../components/form/login'
  
export const Login = () => {

  return (
    <>   
    
    <Mobile>
        <Loginn width='80%;' heigth='auto'/>
    </Mobile>

    <Tablet>
        <Loginn width='65%;' heigth='auto' />
    </Tablet>
    
    <Desktop>
         <Loginn width='40%;' heigth='auto'/>
    </Desktop>

    </>
  )
}

