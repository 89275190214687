//import modules
import { Dropdown, DropdownItem } from "styled-dropdown-component";

import { useState, useEffect } from 'react';

//translate
import { useTranslation } from "react-i18next"

//styles components
import {
    NavbarContainer,
    LeftContainer,
    RightContainer,
    NavbarInnerContainer,
    NavbarLinkContainer,
    NavbarLink,
    MenuAnimation,
    CheckEmail,
    ImgComponent,
} from "../styles/nav";

//styles icons
import { Account, More } from "../styles/nav";

//assets
import logo from "../assets/img/eustory.webp";

//logout
import { logoutAction } from "../zUsers/slices/auth";
import { useAppDispatch } from "../hooks/redux";

//hooks
import { useAppSelector } from "../hooks/redux";

//slices
import { selectAuth,selectUser } from "../zUsers/slices/auth";
import { ContryTranslate } from './country';

export const Navbar = () => {
    const [hidden, setHidden] = useState(true);

    const [color, setColor] = useState("red");

    const dispatch = useAppDispatch();
    //users
    //users 
    const user = useAppSelector(selectUser);
    const isAuth = useAppSelector(selectAuth);

    const CheckConfirmEmail = () => {
        if (user.confirm){
            setColor("green");
        } else {
            setColor("red")
        }
    };

    useEffect(() => {
        CheckConfirmEmail()
    
    }, [])
    
    const [t, i18n] = useTranslation("global")

    return (
        <NavbarContainer>
            <NavbarInnerContainer>
                <LeftContainer>
                    <NavbarLink to="/">
                        {" "}
                        <ImgComponent src={logo} alt="eustory" />{" "}
                    </NavbarLink>
                    
                </LeftContainer>
                {!isAuth && (<ContryTranslate/>)}
                {isAuth && (
                    <RightContainer>
                        <ContryTranslate/>
                        <NavbarLinkContainer>
                            <CheckEmail color={color} />
                            <NavbarLink to="/profile">
                                <Account />
                            </NavbarLink>
                            <Dropdown>
                                <More onClick={() => setHidden(!hidden)} />
                                <MenuAnimation
                                    right={true}
                                    hidden={hidden}
                                    toggle={() => setHidden(!hidden)}
                                >
                                    <DropdownItem
                                        onClick={() => dispatch(logoutAction())}
                                        style={{ 
                                            cursor: "pointer" }}
                                    >
                                        { t("components.navBar.logout") }
                                    </DropdownItem>
                                </MenuAnimation>
                            </Dropdown>
                        </NavbarLinkContainer>
                    </RightContainer>
                )}
            </NavbarInnerContainer>
        </NavbarContainer>
    );
};
