//interfaces
import {
    InterfaceErrorsInputProfile,
    InterfaceInputProfile,
    InterfaceProfileProps,
} from "../interfaces/profile";
//translate
import { TFunction } from "react-i18next";


export const dataProfile = (t: TFunction<"global", undefined>,
    errors: InterfaceErrorsInputProfile
): Array<InterfaceInputProfile[] | InterfaceInputProfile> => [

        {
            registerLabel: "email",
            errors: errors.email,
            label: t("zUsers.data.profile.email"),
            type: "email",
            placeholder: t("zUsers.data.profile.holder-email"),
            disable: true,
        },
        [
            {
                registerLabel: "firstName",
                errors: errors.firstName,
                label: t("zUsers.data.profile.name"),
                type: "text",
            },
            {
                registerLabel: "lastName",
                errors: errors.lastName,
                label: t("zUsers.data.profile.last-name"),
                type: "text",
            },
        ],
        {
            registerLabel: "phone",
            errors: errors.phone,
            label: t("zUsers.data.profile.phone"),
            type: "text",
            placeholder: t("zUsers.data.profile.holder-phone"),
        },
        {
            registerLabel: "street",
            errors: errors.street,
            label: t("zUsers.data.profile.street"),
            type: "text",
        },
        {
            registerLabel: "grade",
            subLabel: t("zUsers.data.register.grade-not-required"),
            errors: errors.grade,
            label: t("zUsers.data.register.grade"),
            type: "text",
        },
        {
            registerLabel: 'birthday',
            errors: errors.birthday,
            label: t("zUsers.data.register.birthday"),
            type: 'text',
        },
        {
            registerLabel: "schoolCenter",
            errors: errors.schoolCenter,
            label: t("zUsers.data.register.schoolCenter"),
            type: "text",
        },
        {
            registerLabel: "schoolEmail",
            errors: errors.schoolEmail,
            label: t("zUsers.data.register.schoolEmail"),
            type: "text",
        },
        {
            registerLabel: "population",
            errors: errors.grade,
            label: t("zUsers.data.register.population"),
            type: "text",
        },

        {
            registerLabel: "province",
            errors: errors.province,
            label: t("zUsers.data.profile.province"),
            type: "text",
        },

        [
            {
                registerLabel: "country",
                errors: errors.country,
                label: t("zUsers.data.profile.country"),
                type: "text",
            },
            {
                registerLabel: "zipCode",
                errors: errors.zipCode,
                label: t("zUsers.data.profile.zip-code"),
                type: "text",
            },
        ],
        
        {
            registerLabel: "roleType",
            errors: errors.roleType,
            label: t("zUsers.data.register.roleType"),
            type: "text",
        }
    ];

export const dataPassword = (t: TFunction<"global", undefined>,
    errors: InterfaceErrorsInputProfile
): Array<InterfaceInputProfile> => [
        {
            registerLabel: "password",
            errors: errors.password,
            label: t("zUsers.data.profile.password"),
            subLabel: t("zUsers.data.profile.password-sL"),
            type: "password",
            placeholder: t("zUsers.data.profile.holder-password"),
        },
        {
            registerLabel: "confirmPassword",
            errors: errors.confirmPassword,
            label: t("zUsers.data.profile.check-password"),
            type: "password",
            placeholder: t("zUsers.data.profile.check-holder"),
        },
    ];

export const INIT_DATA_USER_FORM_PROFILE: InterfaceProfileProps = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    grade: "",
    street: "",
    province: "",
    country: "",
    zipCode: "",
    schoolCenter: "",
    schoolEmail: "",
    roleType:"",
    population: "",
    birthday: ""
};
