import { Autocomplete, CircularProgress, DialogActions, DialogContent, TextField } from "@mui/material";
import { useState, MouseEvent, Fragment, useRef } from 'react';
import { ModalGeneric } from "../../../components/modalGeneric";
import {
    ButtonComponent,
    DoubleContainer,
} from "../../../styles/globalComponents";
import { InputComponent } from "../../../zUsers/components/Input";
import { AutocompleteComponent } from "../../../components/autocomplete";

//translate
import { useTranslation } from "react-i18next"

import { useFormHooks } from "../../../zUsers/hooks/login";
import {
    LabelInput,
    SubLabelInput,
    WrapperInput,
} from "../../../zUsers/styles/Input";
import { TFunction } from "react-i18next";

import { StyledMultiline } from "../../styles/materialUi";
import { dataTypeUser } from "../../data/users";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaInvitedUser } from "../../validators/users";
import { InterfaceUserFormInvited } from "../../interfaces/users";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { selectRole } from "../../../zUsers/slices/role";
import { useGetListProjectAdminQuery } from "../../api/project";
import { InterfaceResponseTable } from "../../interfaces/table";
import { InterfaceProject } from "../../../interfaces/project";
import { projectsActiveConvocations, rolesChangues } from "../../utils/users";
import { usePostInvitedUserMutation } from "../../api/users";
import { activeAlertAction } from "../../../slices/alerts";
import { Loading } from "../../../components/loading";
import { usePostAnnouncementsSendEmailMutation } from "../../api/announcements";

export const ButtonUser = () => {

    const [openInvitedUser, setOpenInvitedUser] = useState(false);
    const [openSendEmail, setOpenSendEmail] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [open, setOpen] = useState(false)
    const [openRole, setOpenRole] = useState(false)

    const [t, i18n] = useTranslation("global")

    const textEmail = useRef(t("zAdmin.components.buttom.user.reminder"))

    //roles
    const roles = useAppSelector(selectRole);

    //apis
    const {
        isLoading,
        currentData: projectsData,
    } = useGetListProjectAdminQuery({
        skip: 0
    });
    const [postInvitedUser, { isLoading: isLoadingIU }] =
        usePostInvitedUserMutation()

    const [postSendEmailAnnoun, { isLoading: isLoadingSEA }] =
        usePostAnnouncementsSendEmailMutation()


    const {
        register,
        handleSubmit,
        formState: { errors },
        control,

    } = useForm<InterfaceUserFormInvited>({
        defaultValues: {
            email: '',
            project: '',
            role: ''
        },
        resolver: yupResolver(schemaInvitedUser(t)),
    });

    //Dispath redux
    const dispatch = useAppDispatch();


    const handleClickOpenInvitedUser = (
        event: MouseEvent<HTMLButtonElement> | null
    ) => setOpenInvitedUser(true);

    const handleClickOpenSendEmail = (
        event: MouseEvent<HTMLButtonElement> | null
    ) => setOpenSendEmail(true);
    const handleClickOpenAlery = (
        event: MouseEvent<HTMLButtonElement> | null
    ) => setOpenAlert(true);

    //handle close
    const handleCloseInvitedUser = () => setOpenInvitedUser(false);
    const handleCloseSendEmail = () => setOpenSendEmail(false);
    const handleCloseAlert = () => setOpenAlert(false);

    const handleSumbitSendInvitation = async (data: InterfaceUserFormInvited) => {
        try {

            setOpenInvitedUser(false);

            const responseInvited = await postInvitedUser(data).unwrap();

            if ('response' in responseInvited) {
                if (responseInvited.message == 'email in use') {
                    return setOpenAlert(true)
                } else {
                    dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            isText: t("zAdmin.components.buttom.user.error-inv"),
                        })
                    );
                }
            }
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "success",
                    isText: t("zAdmin.components.buttom.user.inv-success"),
                })
            );
        } catch (error) {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zAdmin.components.buttom.user.error-inv"),
                })
            );
        }
    };

    const handleSumbitSendEmailAnnoun = async () => {
        try {

            setOpenSendEmail(false);

            const textInformation = textEmail.current

            const proyectsTotal = projectsActiveConvocations(projectsData ? projectsData.results : [])

            const announcement = proyectsTotal[0].announcement._id

            const responseInvited = await postSendEmailAnnoun({ textInformation, announcement }).unwrap();

            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "success",
                    isText: t("zAdmin.components.buttom.user.email-all"),
                })
            );

        } catch (error) {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zAdmin.components.buttom.user.error-email"),
                })
            );
        }
    };




    const dataButtomUser = [
        {
            name: t("zAdmin.components.buttom.user.invite-user"),
            handle: handleClickOpenInvitedUser,
        },
        {
            name: t("zAdmin.components.buttom.user.email-announcement"),
            handle: handleClickOpenSendEmail,
        },
    ];

    return (
        <DoubleContainer width="50%" display="flex">
            {dataButtomUser.map((items) => {
                return (
                    <ButtonComponent
                        paddingLeft="1%"
                        width="auto"
                        heigth="45px"
                        className="btn-hover"
                        type="button"
                        onClick={items.handle}
                    >
                        {items.name}
                    </ButtonComponent>
                );
            })}
            {/* Invited user modal*/}
            <ModalGeneric
                isOpen={openInvitedUser}
                handleClose={handleCloseInvitedUser}
            >
                <form onSubmit={handleSubmit(handleSumbitSendInvitation)}>
                    <DialogContent>
                        <InputComponent
                            {...register('email')}
                            errors={errors.email}
                            label={t("zAdmin.components.buttom.user.invite-user")}
                            subLabel={t("zAdmin.components.buttom.user.assing-user-subL")}
                            type={"email"}
                            placeholder={t("zAdmin.components.buttom.user.email-user")}
                        />
                        <LabelInput>{t("zAdmin.components.buttom.user.assing-project")}</LabelInput>
                        <SubLabelInput>{t("zAdmin.components.buttom.user.assign-project-subL")}</SubLabelInput>
                        <Controller
                            control={control}
                            name={"project"}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => {
                                return (
                                    <Autocomplete
                                        open={open}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        ref={ref}
                                        // value={value as unknown as InterfaceConvocationsTableBase}
                                        size="small"
                                        onChange={(event, item) =>
                                            onChange(item ? item._id : "")
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option._id === value._id
                                        }
                                        getOptionLabel={(option) =>
                                            option.name
                                        }
                                        options={projectsData ? projectsActiveConvocations(projectsData.results) : [] as InterfaceProject[]}
                                        loading={isLoading}
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!error}
                                                helperText={
                                                    error
                                                        ? t("zAdmin.components.buttom.user.project-required")
                                                        : ""
                                                }
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <Fragment>
                                                            {isLoading ? (
                                                                <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                />
                                                            ) : null}
                                                            {
                                                                params.InputProps
                                                                    .endAdornment
                                                            }
                                                        </Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option._id}>
                                                {option.name}
                                            </li>
                                        )}
                                    />
                                );
                            }}
                        />

                        <br />
                        <LabelInput>{t("zAdmin.components.buttom.user.assing-rol")}
                        </LabelInput>
                        <SubLabelInput>{t("zAdmin.components.buttom.user.assing-rol-subL")}</SubLabelInput>
                        <Controller
                            control={control}
                            name={"role"}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => {
                                return (
                                    <Autocomplete
                                        open={openRole}
                                        onOpen={() => {
                                            setOpenRole(true);
                                        }}
                                        onClose={() => {
                                            setOpenRole(false);
                                        }}
                                        ref={ref}
                                        // value={value as unknown as InterfaceConvocationsTableBase}
                                        size="small"
                                        onChange={(event, item) =>
                                            onChange(item ? item._id : "")
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option._id === value._id
                                        }
                                        getOptionLabel={(option) =>
                                            option.name
                                        }
                                        options={roles ? rolesChangues(roles, t) : []}
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!error}
                                                helperText={
                                                    error
                                                        ? t("zAdmin.components.buttom.user.rol-required")
                                                        : ""
                                                }
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <Fragment>

                                                            {
                                                                params.InputProps
                                                                    .endAdornment
                                                            }
                                                        </Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                );
                            }}
                        />

                    </DialogContent>
                    <DialogActions style={{ padding: "2%" }}>
                        <ButtonComponent
                            className="btn-hover"
                        >
                            {t("zAdmin.components.buttom.user.send-invitation")}
                        </ButtonComponent>
                    </DialogActions>

                </form>
            </ModalGeneric>

            <ModalGeneric
                isOpen={openSendEmail}
                handleClose={handleCloseSendEmail}
            >
                <DialogContent>
                    <WrapperInput>
                        <LabelInput htmlFor={"sendEmail"}>
                            {t("zAdmin.components.buttom.user.send-email")}
                        </LabelInput>
                        <SubLabelInput>
                            {t("zAdmin.components.buttom.user.text-email")}
                        </SubLabelInput>
                        <StyledMultiline
                            id="outlined-multiline-static"
                            onChange={(e) => {
                                textEmail.current = e.target.value;
                            }}
                            style={{ width: "100%" }}
                            multiline
                            rows={4}
                            defaultValue={t("zAdmin.components.buttom.user.close-deadlines")}
                        />
                    </WrapperInput>
                </DialogContent>
                <DialogActions style={{ padding: "2%" }}>
                    <ButtonComponent
                        className="btn-hover"
                        type="button"
                        onClick={() => handleSumbitSendEmailAnnoun()}
                    >
                        {t("zAdmin.components.buttom.user.send")}
                    </ButtonComponent>
                </DialogActions>
            </ModalGeneric>

            <ModalGeneric isOpen={openAlert} handleClose={handleCloseAlert}>
                <DialogContent>
                    <SubLabelInput>
                        {t("zAdmin.components.buttom.user.user-w-rol")}
                    </SubLabelInput>
                </DialogContent>
                <DialogActions style={{ padding: "2%" }}>
                    <ButtonComponent
                        className="btn-hover"
                        type="button"
                        onClick={handleCloseAlert}
                    >
                        {t("zAdmin.components.buttom.user.accept")}
                    </ButtonComponent>
                </DialogActions>
            </ModalGeneric>
            <Loading open={isLoadingIU || isLoadingSEA} />
        </DoubleContainer>
    );
};
