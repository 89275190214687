//utils
import { Mobile, Desktop, Tablet } from "../../utils/responsive";
//translate
import { useTranslation } from "react-i18next"
//components
import { TableProjectJuryForm } from "../components/form/tableProjectJury";
//data
import { dataColumnsProjectJury } from "../data/projectJury";

export const TableProjectJury = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Mobile>
                <TableProjectJuryForm
                    colum={dataColumnsProjectJury(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleProy")}
                />
            </Mobile>
            <Tablet>
                <TableProjectJuryForm
                    colum={dataColumnsProjectJury(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleProy")}
                />
            </Tablet>
            <Desktop>
                <TableProjectJuryForm
                    colum={dataColumnsProjectJury(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleProy")}
                />
            </Desktop>
        </>
    );
};
