//modules react
import { Controller, FieldValues } from "react-hook-form";

//components material
import { Select, MenuItem, FormControl } from "@mui/material";

//styles
import {
    WrapperInput,
    LabelInput,
    SubLabelInput,
} from "../zUsers/styles/Input";

//interfaces
import {
    InterfaceSelectComponent,
} from "../interfaces/interface";

export const SelectComponent =<T extends FieldValues>({
    data,
    props,
    placeholder,
    subLabel,
    label,
    formControl:{control,name},
}: InterfaceSelectComponent<T>) => {
    return (
        <WrapperInput heigth={"100"} {...props}>
            {label && <LabelInput htmlFor={name}>{label.toUpperCase()}</LabelInput>}
            {subLabel && <SubLabelInput>{subLabel}</SubLabelInput>}
            <FormControl sx={{ minWidth: 102 }} size="small" fullWidth>
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, value,ref, onBlur } }) => {
                        return (
                            <Select
                                displayEmpty
                                onBlur={onBlur}
                                id={name}
                                value={value}
                                onChange={onChange}
                                inputRef={ref   }
                                renderValue={(selected) => {
                                    if (!selected && selected === "") {
                                        return <em>{placeholder}</em>;
                                    }

                                    return selected;
                                }}
                            >
                                <MenuItem value="">
                                    <em>{placeholder}</em>
                                </MenuItem>
                                {data.map((items) => {
                                   
                                    return (
                                        <MenuItem key={items} value={items}>
                                            {items}
                                        </MenuItem>
                                    );
                                  
                                })}
                            </Select>
                        );
                    }}
                ></Controller>
            </FormControl>
        </WrapperInput>
    );
};
