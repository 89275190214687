//style
import {
    DoubleContainer,
    TripleContainer,
    SubTitleWrapperGeneral,
    TitleWrapperGeneral,
    WrapperGeneral,
    Container,
} from "../../styles/globalComponents";
import { NavbarLink } from "../../styles/nav";

//interfaces
import { InterfaceHomeAdmin } from "../interfaces/homeAdmin";

export const Items = ({
    props,
    text1,
    text2,
    ComponentIcon,
    link
}: InterfaceHomeAdmin) => {
    return (
        <Container width={props.width || "100%"} heigth={props.heigth}>
            <WrapperGeneral padding="1% 3% 1% 3%">
            <NavbarLink textDecoration="auto" to={link}>
                <TripleContainer
                    width="100%"
                    gridTemplateColumns="1fr 6fr"
                    justifyItems="center"
                >
                  
                    <ComponentIcon style={{ width: props.imgWidth }} />

                    <DoubleContainer gridTemplateColumns="auto" display="block">
                        <TitleWrapperGeneral>{text1}</TitleWrapperGeneral>
                        <SubTitleWrapperGeneral>{text2}</SubTitleWrapperGeneral>
                    </DoubleContainer>
                </TripleContainer>
            </NavbarLink>
            </WrapperGeneral>
        </Container>
    );
};
