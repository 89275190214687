import {
    InterfaceProject,
} from "../../interfaces/project";
import {  dateYears, filesProjectState,  } from "../../utils/utils";

import { TFunction } from "react-i18next";


interface InterfaceKeyDinamic {
    [key: string]: unknown;
}

export const filterDataProjects = (dataUser: InterfaceProject,  t: TFunction<"global", undefined>) => {
    const objectEnd: InterfaceKeyDinamic = {};

    for (let [key, value] of Object.entries(dataUser)) {
        const keyUser = key as keyof InterfaceProject;

        if (
            keyUser === "representative" ||
            keyUser === "announcement" ||
            keyUser === "createdAt" ||
            keyUser === "name" ||
            keyUser === 'status'
        ) {
            if (keyUser === "createdAt") {
                value =  filesProjectState(value,t)
                key = 'statusProject'
    
            } else if ( keyUser === "representative"){
                value = value? value.email: t("zAdmin.components.form.tableProject.w-representative") 
            } else if (keyUser === 'announcement'){
                value = value
                    ? `${dateYears(
                          value.initialDate
                      )}-${value.name}`
                    : t("zAdmin.components.form.tableProject.w-convocation")
            } else if ( keyUser === "status"){
                value = value? value: "Sin status"
            }
            objectEnd[key] = value;
        // if (typeof value != "string") {
        //     continue;
        // }
        }
    }
    return objectEnd;
};
