import { ActionCreatorWithPayload } from "@reduxjs/toolkit/dist/createAction";
import { useState } from "react";
import { InterfaceUseForm } from "../interfaces/interface";
import { useAppDispatch } from "./redux";

export const useForm = <T extends Object>(initState: T) => {
    const [formulario, setformulario] = useState(initState);

    const handleChange = ({ field, value,aditionals  }: InterfaceUseForm) => {
        
        setformulario({
            ...formulario,
            ...aditionals,
            [field]: value,
        });
    };
    return {
        ...formulario,
        handleChange,
        formulario,
    };
};

export const useFormCache = <T extends Object>(
    initState: T,
    action: ActionCreatorWithPayload<T, string>
) => {
    const [formulario, setformulario] = useState(initState);
    //Event
    const dispatch = useAppDispatch();

    const handleChange = ({ field, value }: InterfaceUseForm) => {
        setformulario({
            ...formulario,
            [field]: value,
        });

        dispatch(action(formulario));
    };
    return {
        ...formulario,
        handleChange,
        formulario,
    };
};
