import { TFunction } from "react-i18next";
import * as Yup from 'yup';
import {
    validateDigitAndLetters
} from '../../utils/validatesInput';

export const schemaArchive = ( t: TFunction<"global", undefined>) => Yup.object().shape(
    {
        
        nameFile: Yup.string()
            .required(t("zProjects.validators.schemaArchive.error"))
            .test(
                t("zProjects.validators.schemaArchive.only"),
                t("zProjects.validators.schemaArchive.character"),
                (value: string | undefined) => validateDigitAndLetters(value)
            ),
  
    },
);
