//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'

//components
import { Member } from '../components/form/member'


export const RepresentanteA = ( ) => {
  return (
    <>
    <Mobile>
        <Member     
            props= { {width:'80%;', heigth:'auto'} } 
        />
    </Mobile>
    <Tablet>
        <Member
            props= { {width:'65%;', heigth: 'auto'} } 
        />
    </Tablet>
    <Desktop>
        <Member     
            props= { {width:'50%;', heigth:'auto'} } 
        />            
    </Desktop>
    </>
  )
}


