import { TFunction } from "react-i18next";
import { 
    InterfaceRegisterNPArray,
    InterfaceErrorsInputRegisterNP

} from "../interfaces/registerNP";


export const dataRegisterNP = (t: TFunction<"global", undefined>,
        errors: InterfaceErrorsInputRegisterNP
    ): InterfaceRegisterNPArray => [

    
    {
        registerLabel: 'nameProject',
        errors: errors.nameProject,
        label: t("zUsers.data.registerNP.name-project"),
        subLabel: t("zUsers.data.registerNP.project-sL"),
        type: 'text'
    }
    

]