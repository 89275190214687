import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { INITIAL_AUTH_USER, INITIAL_STATE_USER } from "../../data/data";
import { InterfaceProject } from "../../interfaces/project";
import { InterfaceRecoveryProps } from "../interfaces/recovery";
import { InterfaceRauthConfirm } from "../interfaces/respAuth";
import { InterfaceDataUser } from '../../interfaces/user';

export const { reducer: userReducer, actions: userActions } = createSlice({
    name: "users",
    initialState: INITIAL_AUTH_USER,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        logout: (state) => {
            state.user = INITIAL_STATE_USER;
            state.isLoggin = false;
            state.token = "";
            state.restorePW ={
                confirm: false,
                email: ""
            }
        },
        singup: (state, action: PayloadAction<InterfaceRauthConfirm>) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isLoggin = true;
        },
        putUser:(state, action: PayloadAction<InterfaceDataUser>) => {
            const { user } = state
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        },
        project: (state, action: PayloadAction<InterfaceProject>) => {
            state.user.records[state.user.records.length - 1] = action.payload;
        },
        sendEmail: (state, action: PayloadAction<InterfaceRecoveryProps>) => {
            return {
                ...state,
                restorePW: {
                    email: action.payload.email,
                    confirm: true
                }
            };
        },
        cleanRestore: (state) => {
            return {
                ...state,
                restorePW: {
                    confirm: false,
                    email: ""
                }
            };
        },
        deleteProfile:(state, action: PayloadAction<InterfaceDataUser>) => {
            const { user } = state
            return {
                ...state,
                user:{
                    ...action.payload
                }
            }
        },
    },
});

export const selectUser = (state: RootState) => state.user.user;
export const selectRestore = (state: RootState) => state.user.restorePW;
export const selectAuth = (state: RootState) => state.user.isLoggin;

export const {
    logout: logoutAction,
    singup: singupAction,
    putUser: putUserAction,
    project: projectAction,
    sendEmail: sendEmailAction,
    deleteProfile: deleteProfileAction,
    cleanRestore: cleanRestoreAction
} = userActions;
