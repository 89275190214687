import { TFunction } from "react-i18next";
import * as Yup from "yup";
import {
    validateDigitLettersAndSimbols,
    validateInputPhone,
    validateOnlyLetters,
    validatePassword,
} from "../../utils/validatesInput";

export const schemaProfile = (t: TFunction<"global", undefined>) => Yup.object().shape(
    {
        firstName: Yup.string()
            .min(0)
            .required(t("zUsers.validators.schemaProfile.name-req"))
            .test(
                t("zUsers.validators.schemaProfile.onlyq"),
                t("zUsers.validators.schemaProfile.characters"),
                (value: string | undefined) => validateOnlyLetters(value)
            ),
        lastName: Yup.string()
            .required(
                t("zUsers.validators.schemaProfile.last-req")
            )
            .test(
                t("zUsers.validators.schemaProfile.only"),
                t("zUsers.validators.schemaProfile.characters"),
                (value: string | undefined) => validateOnlyLetters(value)
            ),
        phone: Yup.string().when(
            "phone",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string().test(
                            t("zUsers.validators.schemaProfile.only-d"),
                            t("zUsers.validators.schemaProfile.enter-d"),
                            (value: string | undefined) =>
                                validateInputPhone(value)
                        );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        password: Yup.string().when(
            "password",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string()
                            .min(
                                6,
                                t("zUsers.validators.schemaProfile.password-char")
                            )
                            .required("Contraseña requerida")
                            .test(
                                t("zUsers.validators.schemaProfile.masc-char"),
                                t("zUsers.validators.schemaProfile.password-n-l"),
                                (value: string | undefined) =>
                                    validatePassword(value)
                            );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        confirmPassword: Yup.string().when(
            "confirmPassword",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string()
                            .required(t("zUsers.validators.schemaProfile.check-password"))
                            .oneOf(
                                [Yup.ref("password"), null],
                                t("zUsers.validators.schemaProfile.password-agree")
                            );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        street: Yup.string().when(
            "street",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string().test(
                            t("zUsers.validators.schemaProfile.only"),
                            t("zUsers.validators.schemaProfile.characters"),
                            (value: string | undefined) =>
                                validateOnlyLetters(value)
                        );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        province: Yup.string().when(
            "province",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string().test(
                            t("zUsers.validators.schemaProfile.only"),
                            t("zUsers.validators.schemaProfile.characters"),
                            (value: string | undefined) =>
                                validateOnlyLetters(value)
                        );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        country: Yup.string().when(
            "country",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string();
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        roleType: Yup.string()
        .when('role', {
            is: (role: string) => role === 'tutor',
            then: Yup.string().required(t("zUsers.validators.schemaRegister.roleType")).test(t("zUsers.validators.schemaRegister.roleType"), t("zUsers.validators.schemaRegister.invalid"), (value: string | undefined) =>
                validateDigitLettersAndSimbols(value)
            ),
            otherwise: Yup.string().notRequired()
        }),
        zipCode: Yup.string().when(
            "zipCode",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string();
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
    },
    [
        ["phone", "phone"],
        ["street", "street"],
        ["province", "province"],
        ["zipCode", "zipCode"],
        ["country", "country"],
        ["password", "password"],
        ["confirmPassword", "confirmPassword"],
    ]
);
