// React Modules
import { Controller, FieldValues } from "react-hook-form";

// Components Modules
import { Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {es, enUS} from 'date-fns/locale'


//styles
import {
    LabelInput,
    CssTextField,
    SubLabelInput,
    ErrorMessage
} from "../zUsers/styles/Input";
import { styled } from "@mui/material/styles";


//interfaces
import { InterfaceCalendarComponent } from "../interfaces/interface";

import { useTranslation } from "react-i18next"


const StyledCssTextField = styled(CssTextField)`
    height: 40px
    & .MuiInputBase-input {
        padding: 2%;
    }
`;

export const CalendarComponent = <T extends FieldValues>({
    formControl: { control, name },
    label,
    subLabel,
    plataform
}: InterfaceCalendarComponent<T>) => {

    const [t, i18n] = useTranslation("global")

    const lenguage = i18n.language

    const changueLenuage = () => {
        switch (lenguage) {
            case 'es':
                return es
            case 'en':
                return enUS
            default:
                return es;
        }
    }

    return (
        <LocalizationProvider adapterLocale={changueLenuage()} dateAdapter={AdapterDateFns}>
            <Stack
                whiteSpace={"normal"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
                paddingTop="3%"
            >
                <LabelInput htmlFor={name}>{label.toUpperCase()}</LabelInput>
                {subLabel && <SubLabelInput style={{marginTop:'2.6%'}}>{subLabel}</SubLabelInput>}
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, value, ref }, fieldState:{error} }) => {
                        return (<> 

                            { plataform === "desktop" ? (

                                <DesktopDatePicker

                                    inputFormat="dd/MM/yyyy"
                                    value={value}
                                    onChange={onChange}
                                    ref={ref}
                                    renderInput={(params) => (
                                        <StyledCssTextField size="small" {...params} error={error? true: false} />
                                    )}
                                    
                                    />

                                ) : (
                                <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={value}
                                onChange={onChange}
                                ref={ref}
                                renderInput={(params) => (
                                    <StyledCssTextField size="small" {...params}  error={error? true: false}/>
                                    
                                )}
                                />
                                )}
                            {error && <ErrorMessage>{error.message}</ErrorMessage>}
                        </>)
                    }}
                ></Controller>
            </Stack>
        </LocalizationProvider>
    );
};
