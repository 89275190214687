//utils
import { Mobile, Desktop, Tablet } from "../../utils/responsive";

//components
import { TableEvaluationsForm } from "../components/form/tableEvaluations";
import {
    dataColumnsEvaluations,
} from "../data/evaluations";
//translate
import { useTranslation } from "react-i18next"

export const TableEvaluations = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Mobile>
                <TableEvaluationsForm
                    colum={dataColumnsEvaluations(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleEva")} 
                />
            </Mobile>
            <Tablet>
                <TableEvaluationsForm
                    colum={dataColumnsEvaluations(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleEva")} 
                />
            </Tablet>
            <Desktop>
                <TableEvaluationsForm
                    colum={dataColumnsEvaluations(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleEva")} 
                />
            </Desktop>
        </>
    );
};
