import { ChangeEvent } from 'react'

import VMasker from "vanilla-masker";


export const hooksInputHandler = (
    masks: string | string[],
    max: number,
    event: ChangeEvent<HTMLInputElement>
    ): void => {
        const c = event.target;
        const v = c.value.replace(/\D/g, "");
        const m = masks.length > 1 && c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
};