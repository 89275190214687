
//utils
import { Mobile, Desktop, Tablet } from "../../utils/responsive";

//components
import { TableAsignationsForm } from "../components/form/tableAsignations";
import {
    dataColumnsAsignations,
    dataSelectAsignations,
} from "../data/asignations";
//data


//translate
import { useTranslation } from "react-i18next";

export const TableAsignations = () => {

    const [t, i18n] = useTranslation("global")

    return (
        <>
            <Mobile>
                <TableAsignationsForm
                    colum={dataColumnsAsignations(t)}
                   props={{}}
                    select={dataSelectAsignations(t)}
                    title={t("zAdmin.views.asignations.projects-rol")}
                />
            </Mobile>
            <Tablet>
                <TableAsignationsForm
                    colum={dataColumnsAsignations(t)}
                   props={{}}
                    select={dataSelectAsignations(t)}
                    title={t("zAdmin.views.asignations.projects-rol")}
                />
            </Tablet>
            <Desktop>
                <TableAsignationsForm
                   props={{}}
                    colum={dataColumnsAsignations(t)}
                    select={dataSelectAsignations(t)}
                    title={t("zAdmin.views.asignations.projects-rol")}
                />
            </Desktop>
        </>
    );
};
