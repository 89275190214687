import { InterfaceScore } from '../interfaces/score';
//interfaces
import {
    InterfaceColumnEvaluations,
    InterfaceEvaluationsTableBase,
} from "../interfaces/evaluations";
import { TFunction } from 'react-i18next';


export const dataColumnsEvaluations=(t: TFunction<"global", undefined>): InterfaceColumnEvaluations[] => [
    { id: "name", label: "Evaluación", align: "center", minWidth: 100, fontWeight: "bold" },
    {
        id: "email",
        label: t("zAdmin.data.evaluations.jurado"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "createdAt",
        label: t("zAdmin.data.evaluations.date-create"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "score",
        label: t("zAdmin.data.evaluations.score"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toFixed(2),
        fontWeight: "bold",
    },
    {
        id: "status",
        label: t("zAdmin.data.evaluations.state"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "see",
        label: t("zAdmin.data.evaluations.editScore"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
];

export const INITIAL_SCORE: InterfaceScore = {
    procedureReport: 0,
    collectionMaterial: 0,
    sources: 0,
    conclusions: 0,
    presentation: 0,
    comment: ''
}