//views
import { RepresentanteA } from './views/representante'

//interace
import { InterfaceRoutesArray } from '../interfaces/interface'

//views


export const RouterMember = (): InterfaceRoutesArray => 
    [
        {
            path:"/member/:idProject",
            element: <RepresentanteA />
        }
    ]