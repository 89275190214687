//styles react
import styled  from 'styled-components'

//iconos
import { RiDownload2Fill } from 'react-icons/ri' 
import { TiEdit } from 'react-icons/ti' 


//styles info
import { dataImgBase } from '../../styles/Info';

//Interfaces
import { InterfaceStyle } from '../../interfaces/interface';


export const DownloadIcon = styled(RiDownload2Fill)<InterfaceStyle>`
    width: ${(props) => props.width || 'auto'};
    ${dataImgBase}
`

export const TiEditIcon = styled(TiEdit)<InterfaceStyle>`
    width: ${(props) => props.width || 'auto'};
    ${dataImgBase}
`