
//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'

//components
import { FormRegister } from '../components/form/register'
  
export const Register = () => {

  return (
    <>
   
    
    <Mobile>
    
        <FormRegister key={"2311"} width='80%;' heigth='auto'/>
    </Mobile>
    <Tablet>
        <FormRegister key={"21321"} width='65%;' heigth='auto' />
    </Tablet>
    <Desktop>
         {/* Un comentario dentro JSX, esto si servirá*/}
 
         <FormRegister key={"434"} width='50%;' heigth='auto'/>
            
    </Desktop>

    </>
  )
}
