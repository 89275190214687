import { Autocomplete, Box, TextField } from "@mui/material";
import { COUNTRY } from "../data/country";
import { useState } from 'react';
import { InterfaceCountryTranslate } from "../interfaces/interface";

//translate
import { useTranslation } from "react-i18next"

export const ContryTranslate = () => {

    const [countryTranslate, setCountryTranslate] = useState<InterfaceCountryTranslate>({label:'español'} as InterfaceCountryTranslate)
    const { t, i18n } = useTranslation();

    return (
        <Autocomplete
        id="country-select-demo"
        sx={{ width: 150 }}
        options={COUNTRY}
        disableClearable
        value={countryTranslate}
        onChange={(event: any, newValue: InterfaceCountryTranslate) => {
            setCountryTranslate(newValue);
            i18n.changeLanguage(newValue.translate)

        }}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.codigo.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.codigo.toLowerCase()}.png 2x`}
                alt=""

            />
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Translate"
            size="small"
            variant="standard"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    );
};
