import { TFunction } from "react-i18next";
import * as Yup from "yup";

function getMinDate() {
    return new Date();
}

export const schemaConvocations = (t: TFunction<"global", undefined>) =>
    Yup.object().shape({
        name: Yup.string().required(t("zAdmin.validators.convocations.name-required")),
        initialDate: Yup.date()
            .nullable()
            .transform((curr: any, orig: string) => (orig === "" ? null : curr))
            .min(getMinDate(), t("zAdmin.validators.convocations.init-date") + getMinDate())
            .max(
                Yup.ref("endDate"),
                t("zAdmin.validators.convocations.end-date")
            )
            .required(t("zAdmin.validators.convocations.init-date-required")),

        limitDate: Yup.lazy(() =>
            Yup.date()
                .nullable()
                .transform((curr: any, orig: string) =>
                    orig === "" ? null : curr
                )
                .min(
                    Yup.ref("initialDate"),
                    t("zAdmin.validators.convocations.init-date")
                )
                .max(Yup.ref("endDate"),t("zAdmin.validators.convocations.end-date"))
                .required(t("zAdmin.validators.convocations.limit-date-required"))
        ),

        endDate: Yup.lazy(() =>
            Yup.date()
                .nullable()
                .transform((curr: any, orig: string) =>
                    orig === "" ? null : curr
                )
                .min(
                    Yup.ref("initialDate"),
                    t("zAdmin.validators.convocations.init-date")
                )
                .required(t("zAdmin.validators.convocations.end-date-required"))
        ),
    });
