import styled from "styled-components";
import { fonts } from "../../styles/Info";
import { colors } from '../../styles/Info';
import { Link } from "react-router-dom";

export const DoubleContainer = styled.div<InterfaceStyle>`
    display: ${(props) => props.display || 'grid'} ;
    grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};
    gap: 1.2rem;
    width: ${(props) => props.width || 'auto'};
    padding: ${(props) => props.padding || '3% 0 0 0'};
    word-break: break-all;
`

export const ButtonComponent = styled.button<InterfaceStyle>`
    width: ${(props) => props.width || '100%'};
    font-weight: 600;
    background-color : ${(props) => props.background || colors.background.button};
    color: ${(props) => props.color || colors.background.dark};
    cursor: pointer;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    outline: none;
   
    text-transform: uppercase;

    &:hover { 
        box-shadow: 0 4px 15px 0 rgba(109, 109, 110, 0.75);
    }

`

export const Wrapper = styled.div<InterfaceStyle>`
    
    background: ${colors.background.light};
    display: flex;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px black;
    flex-wrap: wrap;

    form {
        padding: 2rem;
        gap: 0.3rem;
        width:100%;
        height:100%;
    }
   
`;


export const TitleWrapper = styled.h1`
    padding: ${(props) => props.padding || '0 5% 0 5%'};
    margin: 0;
    font-size: ${(props:InterfaceStyle) => props.fontSize || '2em'};
    color: ${colors.textIcons.dark};
    display: inline-block;
    text-decoration: underline;

`
export const Texto = styled.p`  
    padding: 0 5% 0 5%;
    align: flex;
`

export interface InterfaceStyle {
    color?: string
    background?:string
    width?: string
    heigth?: string
    fontSize?: string
    responsive?: 'desktop' | 'mobile'
    margin?: string
    padding?: string
    paddingLeft?: string
    gridTemplateColumns?:string
    display?:string
    paddingBottom?: string
    paddingTop?: string
    lineHeight?: string
    boxShadow?: string
    textDecoration?: string
}


export const Container = styled.div<InterfaceStyle>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.heigth || 'auto'};
    padding: ${(props) => props.padding || '0'};
    margin: 0 auto;
    margin-top: 1.0rem;
    position:relative;
`
export const NavbarLink = styled(Link)<InterfaceStyle>`
    color: ${colors.textIcons.dark};
    text-decoration: underline;
    margin: ${(props) => props.margin || '10px'};
    padding-left: ${(props) => props.paddingLeft || '0'};
    padding-top: ${(props) => props.paddingTop || '5%'};
`;

export const WrapperButton = styled.div`
    padding-top: 5%;
    display: flex;
    justify-content: center;
`;

export const WrapperGeneral= styled.div<InterfaceStyle>`
    
    background: ${colors.background.light};
    display: flex;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px black;
    flex-wrap: wrap;
    transition: all .4s ease-in-out;
    padding: ${(props) => props.padding || 'auto'};
    outline: none;
    word-break: break-all;
   
    &:hover { 
        box-shadow: 0 4px 15px 0 rgba(109, 109, 110, 0.75);
    }
   
`;