//interace
import { InterfaceRoutesArray } from '../interfaces/interface'

//views
import { TableUser } from './views/users'
import { TableProject } from './views/project';
import { TableFile } from './views/file';
import { TableConvocations } from './views/convocations';
import { TableEvaluations } from './views/evaluations';
import { ScoreV } from './views/score';
import { TableAsignations } from './views/asignations';



export const RouterAdmin = (): InterfaceRoutesArray => 
    [
        {
            path:'/users',
            element: <TableUser/>
        },
         {
            path:'/project',
            element: <TableProject/> 
        },
        {
            path:'/file',
            element: <TableFile/>
        },
        {
            path:'/convocations',
            element: <TableConvocations/>
        },
        {
            path:'/evaluations/:idProjectEvaluate',
            element: <TableEvaluations/>
        },
        {
            path:'/asignations/:idUserAsignations',
            element: <TableAsignations/>
        },
        {
            path:'/score/:idPE/:idEvaluate',
            element: <ScoreV/>
        }
    ]


