import {
    InterfaceProject,
    InterfaceProjectLarge,
} from "../../interfaces/project";
import { dateComplet, dateYears, filesProjectState,  } from "../../utils/utils";
import { TFunction } from "react-i18next";



interface InterfaceKeyDinamic {
    [key: string]: unknown;
}

export const filterDataAsignations = (dataUser: InterfaceProject,  t: TFunction<"global", undefined>) => {
    const objectEnd: InterfaceKeyDinamic = {};

    for (let [key, value] of Object.entries(dataUser)) {
        const keyUser = key as keyof InterfaceProject;

        if (
            keyUser === "tutors" ||
            keyUser === "announcement" ||
            keyUser === "createdAt" ||
            keyUser === "name" 
        ) {
            if (keyUser === "createdAt") {
                value =  filesProjectState(value,t)
                key = 'statusProject'
    
            } else if ( keyUser === "tutors"){
                value = value? value.email: 'Sin Tutor' 
            } else if (keyUser === 'announcement'){
                value = value
                    ? `${dateYears(
                          value.initialDate
                      )}-${value.name}`
                    : t("zAdmin.utils.asignations.w-convocation")
            }
            objectEnd[key] = value;
        // if (typeof value != "string") {
        //     continue;
        // }
        }
    }
    objectEnd['tutor'] = objectEnd['tutor'] || 'Sin Tutor';
    return objectEnd;
};
