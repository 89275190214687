import { createGlobalStyle } from "styled-components"

//styles Info
import { colors } from './Info'

//Estilos global de toda la app

export default createGlobalStyle`
    html {
        background-color: ${colors.background.light};
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
        font-family: sans-serif; 
        white-space: pre-line;
    }
    #root{
        margin:0 auto;
    }
    
`