import { useState, memo } from "react";

import { Dropdown, DropdownItem } from "styled-dropdown-component";

import { More, MenuAnimation, NavbarLink } from "../../styles/nav";

//interfaces
import { InterfaceMoreComponents } from "../interfaces/table";

export const MoreComponent = memo(({ props, items, data }: InterfaceMoreComponents) => {
    const [hidden, setHidden] = useState(true);

    return (
        <Dropdown>
            <More
                style={{ margin: props.margin }}
                onClick={() => setHidden(!hidden)}
            />
            <MenuAnimation
                right={true}
                hidden={hidden}
                toggle={() => setHidden(!hidden)}
            >
                {items.map((item, index) => {
                    return (
                        <DropdownItem key={`${index}`} value={item.action} onClick={item.onClick}>
                            {item.redirect ? (
                                <NavbarLink
                                    color="#212529"
                                    margin="0"
                                    textDecoration="none"
                                    to={item.redirect}
                                >
                                    {" "}
                                    {item.text}
                                </NavbarLink>
                            ) : (
                                item.text
                            )}
                        </DropdownItem>
                    );
                })}
            </MenuAnimation>
        </Dropdown>
    );
});
