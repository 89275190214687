import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import {
    validatePassword,
} from '../../utils/validatesInput';

export const schemaRecovery2 =  (t: TFunction<"global", undefined>) => Yup.object().shape(
    {
        
        password: Yup.string()
        .min(6, t("zUsers.validators.schemaRecovery2.password"))
        .required(t("zUsers.validators.schemaRecovery2.password-req"))
        .test(
            t("zUsers.validators.schemaRecovery2.char-masc"),
            t("zUsers.validators.schemaRecovery2.password-masc"),
            (value: string | undefined) => validatePassword(value)
        ),
        confirmPassword: Yup.string()
        .required(t("zUsers.validators.schemaRecovery2.check-password"))
        .oneOf([Yup.ref('password'), null], t("zUsers.validators.schemaRecovery2.error-password")),
    
    },
);
