import { Box,Typography } from "@mui/material";
import {  Wrapper } from '../../styles/globalComponents';
//translate
import { useTranslation } from "react-i18next"

export const NotProject = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="89vh"
        >
            <Wrapper margin="3%" heigth="50vh" padding="3%" alingItems="center">
            <Typography   variant="h6" gutterBottom component="div" style={{textAlign:'center'}} >
                {t("zProjects.components.notProject.text")}
            </Typography>
         
             
            </Wrapper>
        </Box>
    );
};
