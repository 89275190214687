//utils
import { Mobile, Desktop, Tablet } from "../../utils/responsive";
//translate
import { useTranslation } from "react-i18next"

//components
import { TableProjectForm } from "../components/form/tableProject";
//data
import { dataColumnsProject, dataSelectProject } from "../data/project";

export const TableProject = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Mobile>
                <TableProjectForm
                    colum={dataColumnsProject(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleProy")}
                    select={dataSelectProject(t)}
                />
            </Mobile>
            <Tablet>
                <TableProjectForm
                    colum={dataColumnsProject(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleProy")}
                    select={dataSelectProject(t)}
                />
            </Tablet>
            <Desktop>
                <TableProjectForm
                    colum={dataColumnsProject(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleProy")}
                    select={dataSelectProject(t)}
                />
            </Desktop>
        </>
    );
};
