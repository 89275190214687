import { InterfaceCountryTranslate } from "../interfaces/interface";
// import { en } from '../assets/img/en.webp'
// import { es } from '../assets/img/es.webp'


export const COUNTRY: Array<InterfaceCountryTranslate> = [

    {
        codigo: "ES",
        label: 'Español',
        // img: es,
        translate:'es'

    },
    // {
        // codigo: "LR",
        // label: 'Ingles',
        // translate:'en',
        // img: en

    // },
    {
        codigo: "PT",
        label: 'Portugués',
        translate:'pt',
        // img: en

    }
  
]