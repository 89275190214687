import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { InfoIcon } from "../../zProjects/styles/project";
import { Container, Wrapper } from "../styles/score";

//translate
import { useTranslation } from "react-i18next"

export function PopoverPopupStateI() {

  const [t, i18n] = useTranslation("global")

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <InfoIcon {...bindTrigger(popupState)}>Info</InfoIcon>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {" "}
              <Wrapper>
                <Typography sx={{ p: 2 }}>
                  {t("zAdmin.components.popover.info-text-1")}
                </Typography>
              </Wrapper>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export function PopoverPopupStateR() {
  const [t, i18n] = useTranslation("global")
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <InfoIcon {...bindTrigger(popupState)}>Info</InfoIcon>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 2 }}>
              {t("zAdmin.components.popover.info-text-2")}
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export function PopoverPopupStateF() {
  const [t, i18n] = useTranslation("global")
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <InfoIcon {...bindTrigger(popupState)}>Info</InfoIcon>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 2 }}>
              {t("zAdmin.components.popover.info-text-3")} 
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export function PopoverPopupStateO() {
  const [t, i18n] = useTranslation("global")
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <InfoIcon {...bindTrigger(popupState)}>Info</InfoIcon>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 2 }}>
              {t("zAdmin.components.popover.info-text-4")}
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export function PopoverPopupStateP() {
  const [t, i18n] = useTranslation("global")
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <InfoIcon {...bindTrigger(popupState)}>Info</InfoIcon>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 2 }}>
              {t("zAdmin.components.popover.info-text-5")}
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
export function PopoverPopupStateC() {
  const [t, i18n] = useTranslation("global")
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <InfoIcon {...bindTrigger(popupState)}>Info</InfoIcon>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 2 }}>
              {t("zAdmin.components.popover.info-text-6")}
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
