//React Module
import { FieldValues, Controller } from "react-hook-form";
import {
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
} from "@mui/material";

//translate
import { useTranslation } from "react-i18next";

//styles
import { ErrorMessage } from "../styles/Input";

//interfaces
import { InterfaceStateRadioComponent } from "../interfaces/register";


export const RadioComponent = <T extends FieldValues>({
    formControl: { control, name },
    errors,
    radio
}: InterfaceStateRadioComponent<T>) => {
    const [t, i18n] = useTranslation("global");
    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
                {t("zUsers.components.radio.type-user")}{" "}
            </FormLabel>
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                {...field}
                            >
                                { radio && radio.map(({value,name,disabled})=>(
                                     <FormControlLabel
                                        value={value}
                                        control={<Radio />}
                                        label={name}
                                        key= {value}
                                        disabled={disabled}
                                        />
                                )) }
                            </RadioGroup>
                            {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
                        
                        </>
                    );
                }}
            ></Controller>
        </FormControl>
    );
};
