//modules react
import { useEffect, useState, ChangeEvent } from "react";
import {
  Skeleton,
  Stack,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

//style
import {
  Container,
  ButtonComponent,
  TitleWrapper,
  ReturnIco,
  ContainerGeneral,
} from "../../../styles/globalComponents";
import { Wrapper, DoubleContainer } from "../../../styles/globalComponents";
import { NavbarLink } from "../../../styles/nav";
import { LabelInput, WrapperInput } from "../../../zUsers/styles/Input";

//components
import { Archivo } from "../archivo";
import { ModalGeneric } from "../../../components/modalGeneric";
import { BasicModal } from "../../../components/basicModal";
import { Loading } from "../../../components/loading";
import { AlertComponent } from "../../../components/alert";

//api
import {
  useGetProjectsQuery,
  usePutChangeNameProjectMutation,
} from "../../api/project";

//slices
import { selectUser } from "../../../zUsers/slices/auth";

//translate
import { useTranslation } from "react-i18next";

//hooks
import { useAppSelector } from "../../../hooks/redux";

//interfaces
import { InterfaceHomeStyle } from "../../interfaces/project";
import { InterfaceRoleUser } from "../../../interfaces/user";

//data
import { dataTutor } from "../../data/tutor";
import { dataJury } from "../../data/jurado";
import { baseFileInfo } from "../../data/project";

//utils
import { dateComplet, pathFile } from "../../../utils/utils";
import { useParams } from "react-router-dom";

export const ProjectTutor = ({ props }: InterfaceHomeStyle) => {
  const [t, i18n] = useTranslation("global");

  //user
  const user = useAppSelector(selectUser);

  const { idPA } = useParams();

  const { idRepresentante } = useParams();

  //project
  const project = user.records[user.records.length - 1];

  const idP = idPA
    ? idPA
    : "project" in project
      ? typeof project.project === "string"
        ? project.project
        : project.project._id
      : project._id;

  const userRole = user.role as InterfaceRoleUser;

  //apis
  const { isLoading, isError, data } = useGetProjectsQuery({
    id: idRepresentante as string,
  });
  const [putChangeName, { isLoading: isLoadingRenameProject }] =
    usePutChangeNameProjectMutation();

  //data
  const {
    ComponentAction: Check,
    ComponentIcon: Icon,
    props: propsCheck,
  } = baseFileInfo(t);

  //state
  const [openInfo, setOpenInfo] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [nameProject, setNameProject] = useState("");
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [sucessText, setSucessText] = useState("");

  const [openForm, setOpenModalForm] = useState(false);

  //useEffect
  useEffect(() => {
    if (isError) {
      setIsErrorModal(true);
    }
  }, [isError]);

  //handle
  const handleRenameProject = async () => {
    const { _id } = project;
    if (nameProject.length < 3) {
      return setOpenError(true);
    }
    if (userRole.name === "representative" || userRole.name === "admin") {
      try {
        const responseRenameFile = await putChangeName({
          user_id: userRole._id,
          id: _id,
          name: nameProject,
        }).unwrap();
        setSucessText(t("zProjects.components.form.projectTutor.name-project"));
        setIsSuccessModal(true);
      } catch (error) {
        setIsErrorModal(true);
      }
    } else {
      setOpenInfo(true);
    }

    setOpenModalForm(false);
  };

  const handleChangeRename = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNameProject(e.target.value);
  };

  const dataTypeRole = userRole.name === "jury" ? dataJury : dataTutor;

  return (
    <Container width={props.width} heigth={props.heigth}>


      <Wrapper>
        <DoubleContainer gridTemplateColumns="auto" display="grid" width="100%">

          {(userRole.name === "tutor" || userRole.name === "jury") && (
            <TitleWrapper fontSize="1.5em">
              <NavbarLink to="#" onClick={() => window.history.back()}>
                <ReturnIco />
                {t("zProjects.components.form.projectRepresentative.back")}
              </NavbarLink>
            </TitleWrapper>
          )}



          <TitleWrapper>
            {" "}
            {(isLoading || isError) && (
              <Skeleton width={"95%"} variant="text" />
            )}
            {data && data.name}
          </TitleWrapper>
        </DoubleContainer>
        <DoubleContainer width="100%" padding="5%">
          {data && (
            <>
              {/*<NavbarLink textDecoration="none" margin="0" to={`/member/${idP}`}>
                <ButtonComponent
                  className="btn-hover"
                  type="button"
                  disabled={userRole.name === "jury" ? true : false}
                >
                  {t(
                    "zProjects.components.form.projectTutor.see-members-project"
                  )}
                </ButtonComponent>{" "}
                  </NavbarLink>*/}
              <NavbarLink
                textDecoration="none"
                margin="0"
                to={`/member/${idP}`}
              >
                <ButtonComponent
                  className="btn-hover"
                  type="button"
                >
                  {t(
                    "zProjects.components.form.projectTutor.see-members-project"
                  )}
                </ButtonComponent>{" "}
              </NavbarLink>
              <NavbarLink textDecoration="none" margin="0" to={`/logs/${data._id}/${data.name}`}>
                <ButtonComponent className="btn-hover" type="button">
                  {t(
                    "zProjects.components.form.projectTutor.change-history"
                  )}
                </ButtonComponent>
              </NavbarLink>
            </>
          )}
          {(isLoading || isError) && (
            <>
              <Skeleton variant="rectangular" width={"100%"} height={60} />

              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </>
          )}
        </DoubleContainer>
        <ContainerGeneral width="90%;" heigth={props.heigth}>
          {(isLoading || isError) && <Skeleton width={"20%"} variant="text" />}
          <TitleWrapper padding="0">{data && "Archivos"}</TitleWrapper>
          {(isLoading || isError) && (
            <Stack spacing={2}>
              <Skeleton variant="rectangular" width={"100%"} height={110} />
              <Skeleton variant="rectangular" width={"100%"} height={100} />
              <Skeleton variant="rectangular" width={"100%"} height={90} />
            </Stack>
          )}
          {data &&
            dataTypeRole(data, t).map(
              (
                {
                  props: propsRender,
                  ComponentIcon,
                  ComponentAction,
                  text1,
                  text2,
                  text3,
                  typeFile: typeFile1,
                  data: dataFile,
                },
                index
              ) => {
                return (
                  <Archivo
                    props={{
                      ...propsRender,
                      imgWidth: props.imgWidth,
                    }}
                    ComponentIcon={ComponentIcon}
                    ComponentAction={ComponentAction}
                    text1={text1}
                    text2={text2}
                    text3={text3}
                    data={dataFile}
                    typeFile={typeFile1}
                    key={index}
                  />
                );
              }
            )}
          {data &&
            data.supplementaryFiles.map(({ key, date, url, uploadedBy }) => {
              return (
                <Archivo
                  props={{
                    ...propsCheck,
                    imgWidth: props.imgWidth,
                  }}
                  ComponentIcon={Icon}
                  ComponentAction={Check}
                  text1={pathFile(key)}
                  text2={t("zProjects.components.form.projectTutor.uploader") + (dateComplet(date))}
                  key={date}
                  typeFile={"check"}
                  data={{ key, date, url, uploadedBy }}
                />
              );
            })}
        </ContainerGeneral>
      </Wrapper>

      {/* Modal rename project */}

      <ModalGeneric
        isOpen={openForm}
        handleClose={() => setOpenModalForm(false)}
      >
        <DialogContent>
          <WrapperInput>
            <LabelInput>
              {t(
                "zProjects.components.form.projectTutor.change-name-project"
              )}
            </LabelInput>

            <TextField
              margin="dense"
              id="name"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeRename}
            />
          </WrapperInput>
        </DialogContent>
        <DialogActions>
          <DoubleContainer width="100%" padding="0 3% 3% 3%">
            <ButtonComponent
              className="btn-hover"
              type="button"
              onClick={() => setOpenModalForm(false)}
            >
              {t("zProjects.components.form.projectTutor.cancel")}
            </ButtonComponent>
            <ButtonComponent
              className="btn-hover"
              type="button"
              onClick={() => handleRenameProject()}
            >
              {t("zProjects.components.form.projectTutor.rename")}
            </ButtonComponent>
          </DoubleContainer>
        </DialogActions>

        <Loading open={isLoadingRenameProject} />
      </ModalGeneric>

      {/* Alerts response api */}
      <AlertComponent
        verify={"error"}
        open={isErrorModal}
        onClose={() => setIsErrorModal(false)}
        text={t(
          "zProjects.components.form.projectTutor.error-data-load"
        )}
      />
      <AlertComponent
        verify={"success"}
        open={isSuccessModal}
        onClose={() => setIsSuccessModal(false)}
        text={`${sucessText}`}
      />

      <BasicModal
        open={openInfo}
        setOpenModal={setOpenInfo}
        text={[t("zProjects.components.form.projectTutor.action-admin")]}
        title={t("zProjects.components.form.projectTutor.notice")}
      />
      <BasicModal
        open={openError}
        setOpenModal={setOpenError}
        text={[t(
          "zProjects.components.form.projectTutor.three-characters"
        )]}
        title={t("zProjects.components.form.projectTutor.error")}
      />
    </Container>
  );
};
