import { Controller, FieldValues } from "react-hook-form";
import { FormControlLabel, Checkbox } from "@mui/material";

//styles
import { ErrorMessage } from "../styles/Input";
//translate
import { useTranslation } from "react-i18next";
//interface
import { InterfaceStateRadioComponent } from "../interfaces/register";

export const CheckComponent = <T extends FieldValues>({
    
    formControl: { control, name },
    errors,
}: InterfaceStateRadioComponent<T>) => {
    const [t, i18n] = useTranslation("global");
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                return (
                    <>
                        <FormControlLabel
                            label={
                                <a
                                    href="https://eustory.es/aviso-legal/"
                                    target="_blank"
                                    style={{ color: "black" }}
                                >
                                    {t("zUsers.components.check.policity")}
                                </a>
                            }
                            control={<Checkbox {...field} />}
                        />
                        {errors && (
                            <ErrorMessage>{errors.message}</ErrorMessage>
                        )}
                    </>
                );
            }}
        ></Controller>
    );
};
