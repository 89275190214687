//style
import {
  DoubleContainer,
  TitleWrapper,
  Container,
  Wrapper,
  ContainerGeneral,
} from "../../../styles/globalComponents";

//translate
import { useTranslation } from "react-i18next";

//components
import { Items } from "../items";

//interace
import { InterfaceHomeAdminRenderStyle } from "../../interfaces/homeAdmin";

export const HomeAdminForm = ({
  props,
  dataRender,
}: InterfaceHomeAdminRenderStyle) => {
  const [t, i18n] = useTranslation("global");

  return (
    <Container width={props.width} heigth={props.heigth}>
      <TitleWrapper padding="3% 0 5% 0%">
        {" "}
        {t("zAdmin.components.form.homeAdmin.admin-panel")}{" "}
      </TitleWrapper>
      <Wrapper>
        <DoubleContainer
          gridTemplateColumns="auto"
          display="grid"
          width="100%"
        ></DoubleContainer>
        <ContainerGeneral width="90%;" heigth={props.heigth}>
          <TitleWrapper padding="5% 0 0% 0%">
            {t("zAdmin.components.form.homeAdmin.actions")}
          </TitleWrapper>
          {dataRender.map(
            ({ text1, text2, ComponentIcon, props: propsRender, link }) => {
              return (
                <Items
                  props={{ ...propsRender, imgWidth: props.imgWidth }}
                  text1={text1}
                  text2={text2}
                  ComponentIcon={ComponentIcon}
                  link={link}
                />
              );
            }
          )}
        </ContainerGeneral>
      </Wrapper>
    </Container>
  );
};
