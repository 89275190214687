//import modules react
import { forwardRef, useEffect } from "react";

//styles
import {
    WrapperInput, Input,
    ErrorMessage,LabelInput,
    SubLabelInput
} from '../styles/Input'

//interface

import { InterfaceInputBase } from '../../interfaces/interface';

//hooks
import { hooksInputHandler } from '../hooks/register'

export const InputComponent = forwardRef<HTMLInputElement, InterfaceInputBase>(
    ( { errors, label,subLabel, mask, ...rest }: InterfaceInputBase, ref:any) => {

    useEffect(() => {
      if (mask) {
        if (!rest.name) return;
        const inputRef = document.getElementById(rest.name);

        if (inputRef) {
          inputRef.addEventListener<any>(
            "input",
            (e) => hooksInputHandler(mask.values, mask.maxLength, e),
            false
          );
        }
      }
    }, [mask, ref, rest]);

    return (
      <WrapperInput>
        {label && <LabelInput htmlFor={rest.name}>{label}</LabelInput>}
        {subLabel && <SubLabelInput  >{subLabel}</SubLabelInput>}
        <Input {...rest} ref={ref} id={rest.name} isError={!!errors} />
        {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
      </WrapperInput>
    );
  }
);

