import { TFunction } from "react-i18next";
import {
    InterfaceColumnAsignations,
    InterfaceUsersProject,
} from "../interfaces/asignations";
import { InterfaceSelectData } from "../interfaces/table";
//interfaces

export const dataColumnsAsignations= (t: TFunction<"global", undefined>): InterfaceColumnAsignations[] => [
    { id: "name", label: "Proyecto", align: "center", minWidth: 100, fontWeight: "bold" },
    // {
    //     id: "role",
    //     label: "Rol",
    //     minWidth: 100,
    //     align: "center",
    //     format: (value: number) => value.toLocaleString("en-US"),
    //     fontWeight: "bold",
    // },
    {
        id: "email",
        label: t("zAdmin.data.asignations.tutor"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toFixed(2),
        fontWeight: "bold",
    },
    {
        id: "convocation",
        label: t("zAdmin.data.asignations.convocation"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "status",
        label: t("zAdmin.data.asignations.status"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "evaluation",
        label: t("zAdmin.data.asignations.evaluations"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "design",
        label: " ",
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
];

export const dataSelectAsignations=(t: TFunction<"global", undefined>): InterfaceSelectData[] => [
    {
        name: "announcement",
        placeholder: t("zAdmin.data.asignations.filter-conv"),
    },
];

export const INIT_DATA_TABLE_ASIGNATIONS: InterfaceUsersProject[] = []
