import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

interface InterfaceLoading {
    open:boolean
}

export const Loading = ({open}:InterfaceLoading) => {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
    <CircularProgress color="inherit" />
    </Backdrop>
  )
}
