//import module react
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { DialogContent, DialogActions } from "@mui/material";

//style
import { SubLabelInput } from "../../styles/Input";
import {
    Title,
    TitleWrapper,
    Container,
    Wrapper,
    ButtonComponent,
    WrapperButton,
} from "../../../styles/globalComponents";

//components
import { InputComponent } from "../Input";
import { AlertComponent } from "../../../components/alert";
import { Loading } from "../../../components/loading";
import { ModalGeneric } from "../../../components/modalGeneric";

//translate
import { useTranslation } from "react-i18next";

//data
import { dataRecovery2 } from "../../data/recovery2";

//interfaces
import { InterfaceRecovery2Props } from "../../interfaces/recovery2";
import { InterfaceStyle } from "../../../interfaces/interface";

//validators
import { schemaRecovery2 } from "../../validators/schemaRecovery2";

//apis
import { usePutChangePasswordMutation } from "../../api/auth";

//hooks
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

//slices
import { cleanRestoreAction, selectRestore } from "../../slices/auth";
import { Component404 } from "../../../views/404";

//data

export const FormRecovery2 = (props: InterfaceStyle) => {
    const [t, i18n] = useTranslation("global");
    //user confim
    const user = useAppSelector(selectRestore);

    //Event
    const dispatch = useAppDispatch();

    const { idRecovery, email } = useParams();
    //state
    const [openInfoSE, setOpenInfoSE] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [error, setError] = useState(true);

    //navegate
    const navigate = useNavigate();

    //api
    const [putChangePassword, { isLoading }] = usePutChangePasswordMutation();

    //handles
    const handleSumbitLoginRecovery2 = async (
        data: InterfaceRecovery2Props
    ) => {
        try {
            const responseReturn: any = await putChangePassword({
                password: data.password,
                id: idRecovery || "",
            }).unwrap();

            dispatch(cleanRestoreAction());
            setOpenInfoSE(true);
        } catch (error) {
            setAlertText(t("zUsers.components.form.recovery2.error-email"));
            setIsErrorModal(true);
        }
    };

    const handleSumbitSend = () => {
        setOpenInfoSE(false);
        return navigate("/auth/signin");
    };

    //form
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<InterfaceRecovery2Props>({
        resolver: yupResolver(schemaRecovery2(t)),
    });

    //useEffect

    /*useEffect(() => {
        console.log("Entrando en useEffect");
        if (user.email === email && user.confirm) {
            console.log("Sin Error");
            setError(false); // Actualiza error a false si se cumplen las condiciones
        } else {
            console.log(user.email);
            console.log(email);
            console.log(user.confirm);
            console.log("Error");
            setError(false); // Si no se cumplen las condiciones, mantén error como true
        }
    }, [user.email, email, user.confirm]); // A*/
    useEffect(() => {
        setError(false);
    }, [user.email, email, user.confirm]);
    
    return (
        <>
            {error ? (
                <Loading open={error} />
            ) : (
                <Container width={props.width}>
                    <Title>{t("zUsers.components.form.recovery2.password-recovery")}</Title>
                    <Wrapper padding="1% 5% 5% 5%">
                        <TitleWrapper padding="3% 0 0 5%">{t("zUsers.components.form.recovery2.s-step")}</TitleWrapper>

                        <form
                            onSubmit={handleSubmit(handleSumbitLoginRecovery2)}
                        >
                            {dataRecovery2(t, errors).map(
                                ({
                                    registerLabel,
                                    errors,
                                    subLabel,
                                    label,
                                    type,
                                    mask,
                                    placeholder,
                                }, index) => {
                                    return (
                                        <InputComponent
                                            key={index}
                                            {...register(registerLabel)}
                                            errors={errors}
                                            label={label}
                                            subLabel={subLabel}
                                            type={type}
                                            mask={mask}
                                            placeholder={placeholder}
                                        />
                                    );
                                }
                            )}

                            <WrapperButton paddingTop="0">
                                <ButtonComponent
                                    className="btn-hover"
                                    type="submit"
                                >
                                    {t("zUsers.components.form.recovery2.recovery-password")}
                                </ButtonComponent>
                            </WrapperButton>
                        </form>
                    </Wrapper>
                    <ModalGeneric
                        isOpen={openInfoSE}
                        handleClose={() => setOpenInfoSE(false)}
                    >
                        <DialogContent>
                            <SubLabelInput>
                                {t("zUsers.components.form.recovery2.password-success")}
                            </SubLabelInput>
                        </DialogContent>
                        <DialogActions style={{ padding: "2%" }}>
                            <ButtonComponent
                                className="btn-hover"
                                type="button"
                                onClick={() => handleSumbitSend()}
                            >
                                {t("zUsers.components.form.recovery2.accept")}
                            </ButtonComponent>
                        </DialogActions>
                    </ModalGeneric>
                    <Loading open={isLoading} />
                    <AlertComponent
                        verify={"error"}
                        open={isErrorModal}
                        onClose={() => setIsErrorModal(false)}
                        text={`${alertText}`}
                    />
                </Container>
            )}
        </>
    );
};
