import { MouseEvent } from "react";

import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    FirstPage as FirstPageIcon,
    LastPage as LastPageIcon,
} from "@mui/icons-material";

//translate
import { useTranslation } from "react-i18next"

import { Box, IconButton, TablePagination } from "@mui/material";

import { useTheme } from "@mui/material/styles";

//interfaces
import { InterfaceTablePaginationActionsProps } from "../interfaces/table";
import { InterfaceUseForm } from "../../interfaces/interface";

export const TablePaginationActions = (
    props: InterfaceTablePaginationActionsProps
) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const [t, i18n] = useTranslation("global")

    const handleFirstPageButtonClick = (
        event: MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
        event: MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={t("zAdmin.components.form.paginate.first-page")}
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label={t("zAdmin.components.form.paginate.previous-page")}
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t("zAdmin.components.form.paginate.next-page")}
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t("zAdmin.components.form.paginate.last-page")}
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
};

interface InterfacePropsPagination {
    page: number;
    rowsPerPage: number;
    onChange: ({ field,value}:InterfaceUseForm) => void
    count: number
}

export function TablePaginationGeneral({
    page,
    rowsPerPage,
    count,
    onChange
}: InterfacePropsPagination) {
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        onChange({field:'page',value:newPage});
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        console.log("event:", event)
        onChange({field:'rowsPerPage',value:parseInt(event.target.value, 10),aditionals:{page:0}});
        // if (parseInt(event.target.value, 10) != count ){
        //      onChange({field:'page',value:0});
        // }
    };

    const [t, i18n] = useTranslation("global")

    return (
        <TablePagination
            rowsPerPageOptions={[25, 50, 75, { label: "All", value: count }]}
            colSpan={5}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: {
                    "aria-label": "filas por página",
                },
                native: true,
            }}
            labelRowsPerPage={t("zAdmin.components.paginate.row-per-page")}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    );
}
