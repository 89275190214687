import { TFunction } from "react-i18next";
import * as Yup from "yup";
import {
    validateInputEmail,
} from "../../utils/validatesInput";

export const schemaInvitedUser = (t: TFunction<"global", undefined>) => Yup.object().shape({
    email: Yup.string()
        .email(t("zAdmin.validators.users.email-inv"))
        .required(t("zAdmin.validators.users.email-req"))
        .test(t("zAdmin.validators.users.email"), t("zAdmin.validators.users.email-n-v"), (value: string | undefined) =>
            validateInputEmail(value)
        ),

    //project: Yup.string().required(t("zAdmin.validators.users.project-req")),

    role: Yup.string().required(t("zAdmin.validators.users.rol-req")),
});
