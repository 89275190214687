import { TFunction } from "react-i18next";
import * as Yup from "yup";

export const schemaAsignations = (t: TFunction<"global", undefined>) => Yup.object().shape({
    id: Yup.string().required(t("zAdmin.validators.asignations.id")),

    project: Yup.lazy((val: unknown) => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string())),

    role: Yup.string().required(t("zAdmin.validators.asignations.id")),
});
