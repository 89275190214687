
//utils
import { Mobile, Desktop,Tablet } from '../../utils/responsive'

//components
import { ProjectRepresentantive } from '../components/form/projectReprentative';


export const HomeProjectRepresentative = () => {
  return (
    <>
    <Mobile>
        <ProjectRepresentantive    
            props= { {width:'80%;', heigth:'auto',imgWidth: 'calc(100% + 10px)'} } 
        />
    </Mobile>
    <Tablet>
        <ProjectRepresentantive    
            props= { {width:'65%;', heigth:'auto',imgWidth: '100%'} } 
        />
    </Tablet>
    <Desktop>
        <ProjectRepresentantive    
            props= { {width:'50%;', heigth:'auto',imgWidth: '100%'} } 
        />
            
    </Desktop>

    </>
  )
}
