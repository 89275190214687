//views
import { HomeProjectTutor } from "./views/projectTutor";

//interace
import { InterfaceRoutesArray } from "../interfaces/interface";
import { HomeProjectRepresentative } from './views/projectRepresentative';

export const RouterProject = (): InterfaceRoutesArray => [
    {
        path: "project/:idRepresentante",
        element: <HomeProjectTutor />,
    },
    {
        path: "project-admin/:idPA",
        element: <HomeProjectRepresentative />,
    },
];
