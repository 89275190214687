import { TFunction } from "react-i18next";
//interfaces
import { InterfaceProject } from "../../interfaces/project";
import { InterfaceColumnProjectJury } from "../interfaces/project";


export const dataColumnsProjectJury=(t: TFunction<"global", undefined>): InterfaceColumnProjectJury[] => [
    {
        id: "name",
        label: t("zAdmin.data.projectJury.project"),
        align: "center",
        minWidth: 100,
        fontWeight: "bold",
    },
    {
        id: "stateProject",
        label: t("zAdmin.data.projectJury.state"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "media",
        label: t("zAdmin.data.projectJury.media"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "createdAt",
        label: t("zAdmin.data.projectJury.date-create"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toFixed(2),
        fontWeight: "bold",
    },
    {
        id: "status",
        label: t("zAdmin.data.projectJury.status"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "evaluation",
        label: t("zAdmin.data.projectJury.evaluation"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
];

export const INIT_DATA_TABLE_PROJECT: InterfaceProject[] = [];
