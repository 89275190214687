
//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'

//components
import { FormRegisterInvited } from '../components/form/registerInvited'
  
export const RegisterInvited = () => {

  return (
    <>
   
    
    <Mobile>
    
        <FormRegisterInvited key={"2311"} width='80%;' heigth='auto'/>
    </Mobile>
    <Tablet>
        <FormRegisterInvited key={"21321"} width='65%;' heigth='auto'/>
    </Tablet>
    <Desktop>
         {/* Un comentario dentro JSX, esto si servirá*/}
 
         <FormRegisterInvited key={"434"} width='50%;' heigth='auto'/>
            
    </Desktop>

    </>
  )
}
