//views
import { Login } from './views/login'
import { Profile } from './views/profile'
import { Recovery } from './views/recovery'
import { Recovery2 } from './views/recovery2'
import { Register } from './views/register'
import { RegisterInvited } from './views/registerInvited'
import { RegisterNP } from './views/registerNP'
import { Confirm } from './components/confirm'


//interace
import { InterfaceRoutesArray } from '../interfaces/interface'


export const RouterUser = (): InterfaceRoutesArray => 
    [
        {
            path:"/profile",
            element: <Profile/>,
        },
        {
            path:"/profile/:idUserProfile",
            element: <Profile/>,
        },
        {
            path:"/auth/signup",
            element: <Register/>,
        },
        {
            path:"/auth/signin/:emailInvited/:roleInvited/:projectInvited",
            element: <RegisterInvited/>,
        },
        {
            path:"/auth/signin/:emailInvited/:roleInvited",
            element: <RegisterInvited/>,
        },
        {
            path:"/auth/signin",
            element: <Login/>,
        },
        {
            path:"/register/nameProject/",
            element: <RegisterNP/>,
        },
        {
            path:"/recovery",
            element: <Recovery/>
        },
        {
            path:"/recovery/continue/:email/:idRecovery",
            element: <Recovery2/>
        },
        {
            path:"/auth/confirm/:id",
            element: <Confirm/>
        }
    ]

        
        
