import moment from "moment";
import { InterfaceProject } from "../../interfaces/project";
import { InterfaceFileProject } from "../interfaces/file";

export const traductor = (dataProject: InterfaceProject[]) => {
    let dataPrint: InterfaceFileProject[] = [] as InterfaceFileProject[];
    console.log(dataProject)
    for (let i = 0; dataProject.length > i; i++) {
        const a = dataProject[i];

        let nameFinishConvocations =
            '';
        if (a.announcement) {
            const dateAnnonun = moment(
                a.announcement
                    .initialDate
            ).format("YYYY");
            const nameConvocations =
                a.announcement
                    .name;

            nameFinishConvocations = `${dateAnnonun} - ${nameConvocations}`;
        }

        if (a.tutorReport) {
            const file = a.tutorReport.key? a.tutorReport.key.split("/").pop() as string:''


            const dataPush = {
                id: a._id,
                name: a.name,
                createdAt: a.tutorReport.date,
                email: a.tutorReport.uploadedBy,
                file,
                typeFile: 'Informe del tutor',
                convocations: nameFinishConvocations,
                url: a.tutorReport.url
            };
            dataPrint.push(dataPush);
        }
        if  (a.workFile ) {
            const file = a.workFile.key? a.workFile.key.split("/").pop() as string:''
            const dataPush = {
                id: a._id,
                name: a.name,
                createdAt: a.workFile.date,
                email: a.workFile.uploadedBy,
                file,
                typeFile: 'Trabajo',
                convocations: nameFinishConvocations,
                url: a.workFile.url
            };
            dataPrint.push(dataPush);
        }
        if (a.workReport ) {
            const file = a.workReport.key? a.workReport.key.split("/").pop() as string:''
            const dataPush = {
                id: a._id,
                name: a.name,
                createdAt: a.workReport.date,
                email:  a.workReport.uploadedBy,
                file,
                typeFile: 'Informe de procedimiento',
                convocations: nameFinishConvocations,
                url: a.workReport.url
            };
            dataPrint.push(dataPush);
        }
    }
    return dataPrint
};