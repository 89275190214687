import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "../src/translation/es/global.json";
import global_en from "../src/translation/en/global.json";
import global_pt from "../src/translation/pt/global.json";


i18next.init({
  interpolation: {escapeValue:false},
  lng: "es",
  resources:{
    es:{
      global: global_es
    },
    en:{
      global: global_en
    },
    pt:{
        global: global_pt
      }
  }
});

window.React = React
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
