import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import { combineReducers } from "redux";

//modules
import { userReducer } from "../zUsers/slices/auth";
import { alertsReducer } from "../slices/alerts";

//apis
import { authApi } from "../zUsers/api/auth";
import { announcementsApi } from "../zAdmin/api/announcements";
import { usersApi } from "../zAdmin/api/users";
import { projectAdminApi } from "../zAdmin/api/project";

import { projectApi } from "../zProjects/api/project";
import { evaluateReducer } from "../zAdmin/slides/evaluate";
import { memberApi } from "../zMembers/api/member";
import { roleReducer } from "../zUsers/slices/role";
import { logApi } from "../zLogs/api/logs";


const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: [
        alertsReducer.name,
        authApi.reducerPath,
        announcementsApi.reducerPath,
        projectApi.reducerPath,
        usersApi.reducerPath,
        projectApi.reducerPath,
        memberApi.reducerPath,
        projectAdminApi.reducerPath,
        logApi.reducerPath
        
    ],
};

const reducer = combineReducers({
    user: userReducer,
    alerts: alertsReducer,
    evaluate: evaluateReducer,
    role: roleReducer,
    [authApi.reducerPath]: authApi.reducer,
    [announcementsApi.reducerPath]: announcementsApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [projectAdminApi.reducerPath]: projectAdminApi.reducer,
    [logApi.reducerPath]: logApi.reducer
    
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(
            authApi.middleware,
            announcementsApi.middleware,
            projectApi.middleware,
            usersApi.middleware,
            memberApi.middleware,
            projectAdminApi.middleware,
            logApi.middleware
        ),

    devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
