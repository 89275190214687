import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { INITIAL_EVALUATE } from "../../data/data";
import { InterfaceActionSaveEvaluated, InterfaceEvaluatedCache } from "../../interfaces/evaluate";

export const { reducer: evaluateReducer, actions: evaluateActions } =
    createSlice({
        name: "evaluate",
        initialState: INITIAL_EVALUATE,
        // The `reducers` field lets us define reducers and generate associated actions
        reducers: {
            save: (
                state,
                action: PayloadAction<InterfaceActionSaveEvaluated>
            ) => {
                const { idProject, evaluateScore,title,email,id } = action.payload;
                const { evaluate, info } = state;

                return {
                    ...state,
                    evaluate: {
                        ...evaluate,
                        [idProject]: evaluateScore,
                    },
                    info: {
                        ...info,
                        [idProject]:{
                            completed: false,
                            edit: false,
                            email,
                            title,
                            id
                        }
                    },
                };
            },
            edit: (
                state,
                action: PayloadAction<InterfaceActionSaveEvaluated>
            ) => {
                const { idProject, evaluateScore,title,email,id } = action.payload;
                const { info, evaluate } = state;
                return {
                    ...state,
                    evaluate: {
                        ...evaluate,
                        [idProject]: evaluateScore,
                    },
                    info: {
                        ...info,
                        [idProject]: {
                            edit: true,
                            completed: false,
                            email,
                            title,
                            id
                        },
                    },
                };
            },
            completed: (
                state,
                action: PayloadAction<InterfaceActionSaveEvaluated>
            ) => {
                const { idProject, evaluateScore,title,email,id } = action.payload;
                const { info, evaluate } = state;
                return {
                    ...state,
                    evaluate: {
                        ...evaluate,
                        [idProject]: evaluateScore,
                    },
                    info: {
                        ...info,
                        [idProject]: {
                            edit: false,
                            completed: true,
                            email,
                            title,
                            id
                        },
                    },
                };
            },

            clear: (state, action: PayloadAction<{ idProject: string }>) => {
                const { idProject } = action.payload;
               

                if (idProject in state.evaluate) {
                    delete state.evaluate[idProject];
                }
                if (idProject in state.info) {
                    delete state.info[idProject];
                }
                return state
            },
            clearAll: (state) => {
                return {
                    ...state,
                    evaluate: {},
                };
            },
        },
    });

export const selectEvaluate = (state: RootState) => state.evaluate;

export const {
    save: saveAction,
    edit: editAction,
    completed: completedAction,
    clear: clearAction,
    clearAll: clearAllAction,
} = evaluateActions;
