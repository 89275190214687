import { forwardRef, ReactElement, Ref,FC } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { InterfaceModalAlertGeneral } from '../interfaces/interface';


const Transition = forwardRef( (
    props: TransitionProps & {
        children: ReactElement<any, any>;
  },
    ref: Ref<unknown>,
) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalAlert:  FC<InterfaceModalAlertGeneral> = (
    { isOpen, handleClose, children }) =>{

  return (
    <div>
    
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {children}
      </Dialog>
    </div>
  );
}
