import { InterfaceMenuBase, InterfaceSelectData } from "../interfaces/table";
//interfaces
import {
    InterfaceColumnFile,
    InterfaceFileProject,
    InterfacePropsDataMenuFile,
} from "../interfaces/file";
import { TFunction } from "react-i18next";


export const dataColumnsFile=(t: TFunction<"global", undefined>): InterfaceColumnFile[] => [
    {
        id: "name",
        label: t("zAdmin.data.file.jury"),
        align: "center",
        minWidth: 100,
        fontWeight: "bold",
    },
    {
        id: "file",
        label: t("zAdmin.data.file.file"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "typeFile",
        label:  t("zAdmin.data.file.type-file"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "createdAt",
        label: t("zAdmin.data.file.date-up"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toFixed(2),
        fontWeight: "bold",
    },
    {
        id: "emailRepresent",
        label: t("zAdmin.data.file.up-by"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "convocations",
        label:  t("zAdmin.data.file.convocation"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "download",
        label: t("zAdmin.data.file.download"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "options",
        label:  t("zAdmin.data.file.options"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
];

export const dataMenuFile = (t: TFunction<"global", undefined>,{
    deleteFile,
    editNameFile,
}: InterfacePropsDataMenuFile): InterfaceMenuBase[] => [
    {
        text: t("zAdmin.data.file.change-name"),
        action: "patch",
        onClick: editNameFile,
    },
    {
        text: t("zAdmin.data.file.delete-file"),
        action: "delete",
        onClick: deleteFile,
    },
];

export const dataSelectFile=(t: TFunction<"global", undefined>): InterfaceSelectData[] => [
    {
        name: "file",
        placeholder: t("zAdmin.data.file.filter"),
    },
    {
        name: "convocations",
        placeholder: t("zAdmin.data.file.filter-conv"),
    },
];

export const INIT_DATA_TABLE_FILES: InterfaceFileProject[] = [];
