//modules react
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { DialogActions, DialogContent, Link, Typography } from "@mui/material";
import { Link as RouteLink, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment'

//style
import { Title, Container } from "../../../styles/globalComponents";
import {
    Wrapper,
    DoubleContainer,
    ButtonComponent,
    WrapperButton,
} from "../../../styles/globalComponents";
import { SubLabelInput, WrapperInput } from "../../styles/Input";
//translate
import { useTranslation } from "react-i18next";

//components
import { InputComponent } from "../Input";
import { SelectComponent } from "../../../components/select";
import { CheckComponent } from "../check";
import { ModalGeneric } from "../../../components/modalGeneric";
import { Loading } from "../../../components/loading";
import { AlertComponent } from "../../../components/alert";
import { CalendarComponent } from "../../../components/calendar";

//interace
import {
    InterfaceRegisterProps,
    InterfaceInputRegister,
} from "../../interfaces/register";
import { InterfaceStyle } from "../../../interfaces/interface";

///validators
import { schemaRegisterInvited } from "../../validators/schemaRegisterInvited";

//data
import { dataCountryArray, INIT_DATA_USER_FORM, dataBaseRegister } from "../../data/register";

//api
import { usePostSignupByInvitedMutation } from "../../api/auth";
import { useGetUsersIDAwaitMutation } from "../../../zAdmin/api/users";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

//slices
import { activeAlertAction } from "../../../slices/alerts";
import { singupAction } from "../../slices/auth";
import { selectRole } from "../../../zUsers/slices/role";

//utils 
import { getNameRol } from "../../utils/user"
import { CheckCircleRounded } from "@mui/icons-material";

export const FormRegisterInvited = (props: InterfaceStyle) => {
    const [t, i18n] = useTranslation("global");

    const { emailInvited, roleInvited, projectInvited } = useParams()

    const [confirmUser, setConfirmUser] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false)

    //text modal
    const [textModal, setTextModal] = useState('')

    const [
        postSignupByInvited,
        { isLoading, status, data: dataRecived, error: errorRecived },
    ] = usePostSignupByInvitedMutation();
    const [getUsersIDAwait, { isLoading: __, data }] = useGetUsersIDAwaitMutation();

    const navegate = useNavigate()

    //
    const dispatch = useAppDispatch();
    const roles = useAppSelector(selectRole);
    const typeRol = getNameRol(roles, roleInvited as string)

    let checkRoleTutor = typeRol === 'tutor' ? true : false
    let checkRoleJury = typeRol === 'jury' ? true : false

    INIT_DATA_USER_FORM.email = emailInvited as string
    INIT_DATA_USER_FORM.role = roleInvited as string
    INIT_DATA_USER_FORM.project = projectInvited as string


    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        setError

    } = useForm<InterfaceRegisterProps>({
        defaultValues: INIT_DATA_USER_FORM,
        resolver: yupResolver(schemaRegisterInvited(t)),
    });



    useEffect(() => {
        if (checkRoleTutor || checkRoleJury) {
            setValue('grade', 'no aplica')
        }
    }, [])


    const handleSumbitRegister = async (data: InterfaceRegisterProps) => {
        delete data.polity

        try {

            for (let prop in data) {
                //console.log(prop)
                if (typeof (prop) === 'string') {
                    prop = prop.trim()
                }
                //console.log(prop)
            }


            for (let i = 0; roles.length > i; i++) {
                if (roles[i]._id === data.role) {

                    //En caso de ser representante o jurado te obliga a poner el centro escolar
                    if (roles[i].name && (roles[i].name.toLocaleLowerCase() === 'representative' || roles[i].name.toLocaleLowerCase() === 'student')) {
                        if (!data.schoolCenter) {

                            return setError('schoolCenter', { type: 'custom', message: t("zUsers.validators.schemaRegisterInvited.schoolCenter-req") }, { shouldFocus: true })
                        }

                        if (!data.grade) {

                            return setError('grade', { type: 'custom', message: t("zUsers.validators.schemaRegisterInvited.grade-req") }, { shouldFocus: true })

                        }

                    }
                    break;
                }
            }

            // Verificar y formatear la fecha de cumpleaños
            if (data.birthday) {
                // Convertir la fecha a un objeto Date si no lo es ya
                const birthdayDate = new Date(data.birthday);
                if (!isNaN(birthdayDate.getTime())) {
                    data['birthday'] = moment(birthdayDate).format('YYYY-MM-DD');
                } else {
                    //console.error('Invalid birthday format');
                    return setError('birthday', { type: 'custom', message: t("zUsers.validators.schemaRegisterInvited.birthday-invalid") }, { shouldFocus: true });
                }
            }

            if (!data.population) {

                return setError('population', { type: 'custom', message: t("zUsers.validators.schemaRegisterInvited.population-req") }, { shouldFocus: true })

            }

            const userReturn = await postSignupByInvited(data).unwrap();
            
            if ("createdUser" in userReturn) {
                const { token, createdUser, message } = userReturn

                const response = await getUsersIDAwait({ id: createdUser._id }).unwrap();
                const request = {
                    user: response,
                    token: token,
                    message: message
                }

                dispatch(singupAction(request));
                return navegate("/");

            } else if ("user" in userReturn) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zUsers.components.form.registerInvited.error-register"),
                    })
                );
            }


        } catch (error) {
            
            setIsErrorModal(true)
            console.log(error)
        }
    };

    return (
        <Container width={props.width} heigth={props.heigth}>
            <Title>{t("zUsers.components.form.registerInvited.form-register")}</Title>
            <Wrapper>
                <form noValidate onSubmit={handleSubmit(handleSumbitRegister)} autoComplete="off">
                    <InputComponent
                        {...register("email")}
                        errors={errors.email}
                        label={t("zUsers.components.form.registerInvited.email")}
                        disabled
                        type="email"
                    />
                    {dataBaseRegister(t, errors).map((items) => {

                        if (items instanceof Array<InterfaceInputRegister>) {
                            return (
                                <DoubleContainer>
                                    {items.map(
                                        ({
                                            registerLabel,
                                            errors,
                                            subLabel,
                                            label,
                                            type,
                                            mask,
                                            placeholder,
                                        }) => {

                                            if (registerLabel === "roleType") {
                                                return null; // Exclude roleType here
                                            } else
                                                if (
                                                    registerLabel === "country"
                                                ) {
                                                    return (
                                                        <SelectComponent<InterfaceRegisterProps>
                                                            label={label}
                                                            data={dataCountryArray}
                                                            formControl={{
                                                                control,
                                                                name: "country",
                                                            }}
                                                            placeholder={""}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <InputComponent
                                                            {...register(
                                                                registerLabel
                                                            )}
                                                            errors={errors}
                                                            label={label}
                                                            subLabel={subLabel}
                                                            type={type}
                                                            mask={mask}
                                                            placeholder={
                                                                placeholder
                                                            }
                                                        />
                                                    );
                                                }
                                        }
                                    )}
                                </DoubleContainer>
                            );
                        } else {

                            if (!(items.registerLabel === "grade" && (checkRoleTutor || checkRoleJury))) {
                                if (items.registerLabel === "birthday") {
                                    return (
                                        <CalendarComponent<InterfaceRegisterProps>
                                            label={items.label}
                                            subLabel={items.subLabel}
                                            plataform={
                                                props.responsive ||
                                                "desktop"
                                            }
                                            formControl={{
                                                control,
                                                name: "birthday",
                                            }}
                                        />
                                    );

                                } else if (items.registerLabel === "roleType") {
                                    return null; // Exclude roleType here
                                } else {

                                    return (
                                        <InputComponent
                                            {...register(items.registerLabel)}
                                            errors={items.errors}
                                            label={items.label}
                                            subLabel={items.subLabel}
                                            placeholder={items.placeholder}
                                            type={items.type}
                                            mask={items.mask}
                                        />
                                    );
                                }

                            }
                        }
                    })}


                    {checkRoleTutor && (
                        <WrapperInput>
                            <InputComponent
                                key={"roleType"}
                                {...register("roleType")}
                                errors={errors.roleType}
                                label={t("zUsers.data.register.roleType")}
                                placeholder={t("zUsers.data.register.roleType-holder")}
                                type="text"
                            />
                        </WrapperInput>
                    )}

                    <WrapperInput paddingBottom="0" paddingTop="0">
                        <CheckComponent<InterfaceRegisterProps>
                            formControl={{ control, name: "polity" }}
                            errors={errors.polity}
                        />
                    </WrapperInput>

                    <WrapperInput>
                        <SubLabelInput>
                            {t("zUsers.components.form.registerInvited.already-account")}{" "}
                            <RouteLink to="/profile">
                                <Link color="black" underline="hover">
                                    {t("zUsers.components.form.registerInvited.login")}
                                </Link>
                            </RouteLink>
                        </SubLabelInput>
                    </WrapperInput>

                    <WrapperButton>
                        <ButtonComponent className="btn-hover">
                            {t("zUsers.components.form.registerInvited.sign-up")}
                        </ButtonComponent>
                    </WrapperButton>
                </form>
            </Wrapper>

            {/* Loading page */}
            <Loading open={isLoading} />

            {/* Alert Erros */}
            <AlertComponent
                verify={"error"}
                open={isErrorModal}
                onClose={() => setIsErrorModal(false)}
                text={`${t("zUsers.components.form.registerInvited.register-error")}`}
            />

            {/* Confirm Users */}
            <ModalGeneric isOpen={confirmUser} handleClose={() => setConfirmUser(false)} >
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {textModal}
                    </Typography>
                </DialogContent>
                <DialogActions style={{ padding: "2%" }}>
                    <ButtonComponent
                        className="btn-hover"
                        type="button"
                        onClick={() => navegate('/auth/signin')}
                    >
                        {t("zUsers.components.form.registerInvited.accept")}
                    </ButtonComponent>
                </DialogActions>
            </ModalGeneric>


        </Container>
    );
};
