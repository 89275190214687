//modules react
import styled  from 'styled-components'
import { TextField } from '@mui/material';

//styles
import { colors } from '../../styles/Info';

//interfaces
import { InterfaceInputRegisterError } from '../interfaces/register'
import { InterfaceStyle } from '../../interfaces/interface';


export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: colors.background.inputs ,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.background.dark,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.background.inputs,
      },
      '&:hover fieldset': {
        borderColor: colors.background.inputs,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.background.dark,
      },
    },
  });

export const WrapperInput = styled.div<InterfaceStyle>`
    height: auto;
    width: ${(props) => props.width || 'auto'};
    padding-top: ${(props) => props.paddingTop || '3%'};
    padding-bottom: ${(props) => props.paddingBottom || '3%'};
    overflow:hidden;
    text-overflow: ellipsis;
    align-items: ${(props) => props.alingItems || 'auto'};
`;


export const LabelInput = styled.label`
    color: ${colors.background.darkblue};
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
`

export const SubLabelInput = styled.p`
    color: ${colors.background.darkblue};
    font-style: italic;
    margin-bottom: 5px;
    display: block;
    padding-botton:4%;
`

export const Input = styled.input<InterfaceInputRegisterError>`
    height: 40px;
    width: 100%;
    display: flex;
    padding-left: 5px;
    border-radius: 4px;
    box-sizing: border-box;
    border: ${(props) => (
        props.isError ? 
        `2px solid ${colors.variants.redhaze}` : `1px solid ${colors.background.inputs}`
    )};
`;

export const ErrorMessage = styled.span`
  margin-top: 5px;
  display: block;
  color: #c1121f;
`;