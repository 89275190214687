//React Module
import { forwardRef } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

//interfaces
import { useAppDispatch, useAppSelector } from "../hooks/redux";

//slices
import { clearAlertAction, selectAlert } from "../slices/alerts";



const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertComponentGeneric = () => {

    //alerts 
    const alerts = useAppSelector(selectAlert);

    //events
    const dispatch = useAppDispatch();
    //handle Deactivate
    const handleCloseAlert = () => {
        dispatch(clearAlertAction())
    }
    
    return (
        <Snackbar open={alerts.isActive} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={alerts.isMode} sx={{ width: "100%" }}>
                {alerts.isText}
            </Alert>
        </Snackbar>
    );
};
