import styled  from 'styled-components'
import { createTheme } from '@mui/material/styles';
import black from '@mui/material/colors/blue';

//interface
import { InterfaceStyle } from '../../interfaces/interface'
import { colors } from '../../styles/Info';

export const Container = styled.div<InterfaceStyle>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.heigth || 'auto'};
    padding: ${(props) => props.padding || '0'};
    padding-left: ${(props) => props.paddingLeft || '5%'};
    margin: 0 auto;
    position:relative;
    padding-top: ${(props) => props.paddingTop || '0%'};
    word-break: break-all;
`;

export const Wrapper = styled.div<InterfaceStyle>`
    
    background: ${colors.background.light};
    display: flex;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px black;
    flex-wrap: wrap;

    form {
        padding: ${(props) => props.padding || '2rem'};
        gap: 0.3rem;
        width:100%;
        height:100%;
    }
   
`;

export const DoubleContainer = styled.div<InterfaceStyle>`
    display: ${(props) => props.display || 'grid'} ;
    grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};
    gap: 1.2rem;
    width: ${(props) => props.width || 'auto'};
    padding: ${(props) => props.padding || '-5%,-5%,-5%,-5%'};
    word-break: break-all;
    align-items: ${(props) => props.alingItems || 'auto'};

`;

export const TitleWrapper = styled.h1`
    padding: ${(props) => props.padding || '5% 0 0 5%'};
    margin: 0;
    font-size: ${(props:InterfaceStyle) => props.fontSize || '1.5em'};
    color: ${colors.textIcons.dark};
    display: inline-block;
    word-break: break-all;
`;

export const ButtonComponent = styled.button<InterfaceStyle>`
    width:  ${(props) => props.width || '100%'};
    font-weight: 600;
    background-color : ${(props) => props.background || colors.background.button};
    color: ${(props) => props.color || colors.background.dark};
    cursor: pointer;
    height: ${(props) => props.heigth || '50px'};
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    outline: none;
    padding-left: ${(props) => props.paddingLeft || 'auto'};
    padding-right: ${(props) => props.paddingRight || 'auto'};
    padding-botton: ${(props) => props.paddingBottom || 'auto'};
    padding: ${(props) => props.padding || 'auto'};
    margin-top: ${(props) => props.margin || '0'};
   
    text-transform: uppercase;

    &:hover { 
        box-shadow: 0 4px 15px 0 rgba(109, 109, 110, 0.75);
    }

`;
