import {
    Autocomplete,
    CircularProgress,
    DialogActions,
    DialogContent,
    TextField,
} from "@mui/material";
import { useState, Fragment } from "react";
import { ModalGeneric } from "../../../components/modalGeneric";
import {
    ButtonComponent,
    DoubleContainer,
} from "../../../styles/globalComponents";

import { LabelInput, SubLabelInput } from "../../../zUsers/styles/Input";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaAsignations } from "../../validators/asignations";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { selectRole } from "../../../zUsers/slices/role";
import { useGetListProjectAdminQuery, usePutAssignTJProjectMutation } from "../../api/project";
import { InterfaceProject } from "../../../interfaces/project";
import { projectsActiveConvocations, rolesChangues } from "../../utils/users";
import { InterfaceAsignationsProjects } from "../../interfaces/asignations";
import { activeAlertAction } from "../../../slices/alerts";
import { Loading } from "../../../components/loading";
import { useTranslation } from "react-i18next";
import { usePutProfileMutation } from "../../../zUsers/api/auth";
import { InterfaceRequestPutProfile } from "../../../zUsers/interfaces/profile";

interface InterfaceFormAsignationsID {
    idUser: string;
}

export const ButtonAsignations = ({ idUser }: InterfaceFormAsignationsID) => {
    //states
    const [openAsignations, setOpenAsignations] = useState(false);

    const [open, setOpen] = useState(false);
    const [openRole, setOpenRole] = useState(false);

    const [multiValue, setMultiValue] = useState(true);
    const [keyValue, setKeyValue] = useState('siseve');

    const [t, i18n] = useTranslation("global")
    

    //roles
    const roles = useAppSelector(selectRole);

    //apis
    const { isLoading, currentData: projectsData } =
        useGetListProjectAdminQuery({
            skip: 0,
        });
    const [ pPutAssignRProject, {isLoading:isLoadingAPR}] =
        usePutAssignTJProjectMutation()

    const [ putProfile, {isLoading:isLoadingPT}] =
        usePutProfileMutation()

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,

    } = useForm<InterfaceAsignationsProjects>({
        defaultValues: {
            id: idUser,
            project: [],
            role: "",
        },
        resolver: yupResolver(schemaAsignations(t)),
    });

    //Dispath redux
    const dispatch = useAppDispatch();

    const handleSumbitSendInvitation = async (
        data: InterfaceAsignationsProjects
    ) => {
        try {

            const requestProfile = {
                _id: idUser,
                role: data.role as string
            } as InterfaceRequestPutProfile
            
            console.log(requestProfile, "request")

            const responseProfile = await putProfile(requestProfile).unwrap()

            console.log(responseProfile, "put")
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "success",
                    isText: t("zAdmin.components.buttom.asignations.success"),
                })
            );

            console.log("Enviando solicitud de asignación de roles...");
            console.log("Datos a enviar:", data);
            const request = {
                user: idUser,
                projects: data.project
            }
            setOpenAsignations(false);
            const responseAsi = await pPutAssignRProject(request).unwrap();
     
            console.log("Respuesta de la API:", responseAsi);

         
        } catch (error) {
            console.error("Error al enviar la solicitud de asignación de roles:", error);
       
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zAdmin.components.buttom.asignations.error"),
                })
            );
        }
    };

    return (
        <DoubleContainer width="50%" display="flex">
            <ButtonComponent
                paddingLeft="1%"
                width="auto"
                heigth="45px"
                className="btn-hover"
                type="button"
                onClick={() => setOpenAsignations(true)}
            >
                {t("zAdmin.components.buttom.asignations.asign-projects")}
            </ButtonComponent>

            <ModalGeneric
                isOpen={openAsignations}
                handleClose={() => setOpenAsignations(false)}
            >
                <form onSubmit={handleSubmit(handleSumbitSendInvitation)}>
                    <DialogContent>
                        <LabelInput>{t("zAdmin.components.buttom.asignations.asign-project")}</LabelInput>
                        <SubLabelInput>
                            {t("zAdmin.components.buttom.asignations.choose-project")}
                        </SubLabelInput>
                        <Controller
                            control={control}
                            name={"project"}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        {multiValue ? (
                                            <Autocomplete
                                                key={keyValue}
                                                multiple
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                ref={ref}
                                                // value={value as unknown as InterfaceConvocationsTableBase}
                                                defaultValue={[]}
                                                size="small"
                                                onChange={(event, item) => {
                                                    onChange([
                                                        ...item.map(
                                                            (obj) => obj._id
                                                        ),
                                                    ]);
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option._id === value._id}
                                                getOptionLabel={(option) =>
                                                    option.name || ""
                                                }
                                                options={
                                                    projectsData
                                                        ? projectsActiveConvocations(
                                                              projectsData.results
                                                          )
                                                        : ([] as InterfaceProject[])
                                                }
                                                loading={isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={!!error}
                                                        helperText={
                                                            error
                                                                ? t("zAdmin.buttom.asignations.project-required")
                                                                : ""
                                                        }
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <Fragment>
                                                                    {isLoading ? (
                                                                        <CircularProgress
                                                                            color="inherit"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    {
                                                                        params
                                                                            .InputProps
                                                                            .endAdornment
                                                                    }
                                                                </Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Autocomplete
                                                open={open}
                                                key={keyValue}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                ref={ref}
                                                // value={value as unknown as InterfaceConvocationsTableBase}
                                                defaultValue={{} as InterfaceProject}
                                                size="small"
                                                onChange={(event, item) => {
                                                    onChange([
                                                        item ? item : "",
                                                    ]);
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option._id === value._id}
                                                getOptionLabel={(option) =>
                                                    option.name || ""
                                                }
                                                options={
                                                    projectsData
                                                        ? projectsActiveConvocations(
                                                              projectsData.results
                                                          )
                                                        : ([] as InterfaceProject[])
                                                }
                                                loading={isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={!!error}
                                                        helperText={
                                                            error
                                                                ? t("zAdmin.components.buttom.asignations.project-required")
                                                                : ""
                                                        }
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <Fragment>
                                                                    {isLoading ? (
                                                                        <CircularProgress
                                                                            color="inherit"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    {
                                                                        params
                                                                            .InputProps
                                                                            .endAdornment
                                                                    }
                                                                </Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />

                        <br />
                        <LabelInput>{"ROL ASIGNADO"}</LabelInput>
                        <SubLabelInput>
                            {t("zAdmin.components.buttom.asignations.choose-rol")}
                        </SubLabelInput>
                        <Controller
                            control={control}
                            name={"role"}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => {
                                return (
                                    <Autocomplete
                                      
                                        open={openRole}
                                        onOpen={() => {
                                            setOpenRole(true);
                                        }}
                                        onClose={() => {
                                            setOpenRole(false);
                                        }}
                                        ref={ref}
                                        // value={value as unknown as InterfaceConvocationsTableBase}
                                        size="small"
                                        onChange={(event, item) => {
                                        
                                            onChange(item ? item._id : "");
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option._id === value._id
                                        }
                                        getOptionLabel={(option) => option.name}
                                        options={
                                            roles
                                                ? rolesChangues(roles,t).filter(
                                                      (rol) =>
                                                         
                                                          !["Administrador"].includes(  (rol.name as string))
                                                  )
                                                : []
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!error}
                                                helperText={
                                                    error
                                                        ? t("zAdmin.components.buttom.asignations.rol-required")
                                                        : ""
                                                }
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <Fragment>
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .endAdornment
                                                            }
                                                        </Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                        
                    </DialogContent>
                    <DialogActions style={{ padding: "2%" }}>
                        <ButtonComponent className="btn-hover">
                            {t("zAdmin.components.buttom.asignations.send-inv")}
                        </ButtonComponent>
                    </DialogActions>
                </form>
            </ModalGeneric>

            <Loading open={isLoadingAPR} />
        </DoubleContainer>
    );
};
