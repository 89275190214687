import Typography from "@mui/material/Typography";
import {
    Container,
    Wrapper,
  } from "../../styles/globalComponents";
import { InterfaceProjectTutor } from "../interfaces/project";
import { TitleProject } from "../styles/project";
import { useNavigate } from "react-router-dom";
//translate
import { useTranslation } from "react-i18next"

export const ProjectTutorComponent = ({
    email,
    name,
    state,
    id,
}: InterfaceProjectTutor) => {

    //navigate
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global")

    return (
        <Wrapper>
            <Container paddingLeft="5%" paddingBottom="5%" paddingRight="5%" textDecoration="underline">
                <div>
                    <TitleProject onClick={() => navigate(`/project/${id}`)}>
                        {" "}
                        {name}{" "}
                    </TitleProject>
                    <Typography>
                        {" "}
                        {t("zProjects.components.form.projectTutor.state")} {state} <br /> 
                        {t("zProjects.components.form.projectTutor.representative")}
                        {email}
                    </Typography>
                </div>
            </Container>
        </Wrapper>
    );
};
