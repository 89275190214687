
//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'
import { FormRecovery } from '../components/form/recovery';

export const Recovery = () => {

   

  return (
    <>
        <Mobile>
            <FormRecovery width='80%;'/>
        </Mobile>
        <Tablet>
            <FormRecovery width='65%;'/>  
        </Tablet>
        <Desktop>
            <FormRecovery width='50%;'/>
        </Desktop>
    </>
  )
}
