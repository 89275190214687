//import module react
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DialogContent, DialogActions } from "@mui/material";

//style
import { SubLabelInput } from "../../styles/Input";
import {
    Title,
    TitleWrapper,
    Container,
    Wrapper,
    ButtonComponent,
    WrapperButton,
} from "../../../styles/globalComponents";

//components
import { InputComponent } from "../Input";
import { AlertComponent } from "../../../components/alert";
import { Loading } from "../../../components/loading";
import { ModalGeneric } from "../../../components/modalGeneric";

//data
import { dataRecovery } from "../../data/recovery";

//translate
import { useTranslation } from "react-i18next";

//interfaces
import { InterfaceRecoveryProps } from "../../interfaces/recovery";
import { InterfaceStyle } from "../../../interfaces/interface";

//validators
import { schemaRecovery } from "../../validators/schemaRecovery";

//apis
import { usePostRestorePEMutation } from "../../api/auth";

//hooks
import { useAppDispatch } from "../../../hooks/redux";

//slices
import { sendEmailAction } from "../../slices/auth";

export const FormRecovery = (props: InterfaceStyle) => {
    const [t, i18n] = useTranslation("global");
    //state
    const [openInfoSE, setOpenInfoSE] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [alertText, setAlertText] = useState("");

    //navegate
    const navigate = useNavigate();

    //Event
    const dispatch = useAppDispatch();

    //api
    const [postRestore, { isLoading }] = usePostRestorePEMutation();

    //handles
    const handleSumbitLoginRecovery = async (data: InterfaceRecoveryProps) => {
        try {
            const responseReturn = await postRestore(data).unwrap();

            if ("status" in responseReturn && responseReturn.status != true) {
                setAlertText(t("zUsers.components.form.recovery.n-exist"));
                setIsErrorModal(true);
            } else {
                dispatch(sendEmailAction(data));
                setOpenInfoSE(true);
            }
        } catch (error) {
            setAlertText(t("zUsers.components.form.recovery.error-email"));
            setIsErrorModal(true);
        }
    };

    const handleSumbitSend = () => {
        setOpenInfoSE(false);
        return navigate("/auth/signin");
    };

    //forms
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<InterfaceRecoveryProps>({
        resolver: yupResolver(schemaRecovery(t)),
    });

    return (
        <Container width={props.width}>
            <Title>{t("zUsers.components.form.recovery.password-recovery")}</Title>
            <Wrapper padding="1% 5% 5% 5%">
                <TitleWrapper padding="3% 0 0 5%">{t("zUsers.components.form.recovery.f-step")}</TitleWrapper>

                <form onSubmit={handleSubmit(handleSumbitLoginRecovery)}>
                    {dataRecovery(t,errors).map(
                        ({
                            registerLabel,
                            errors,
                            subLabel,
                            label,
                            type,
                            mask,
                            placeholder,
                        }) => {
                            return (
                                <InputComponent
                                    {...register(registerLabel)}
                                    errors={errors}
                                    label={label}
                                    subLabel={subLabel}
                                    type={type}
                                    mask={mask}
                                    placeholder={placeholder}
                                />
                            );
                        }
                    )}

                    <WrapperButton paddingTop="0">
                        <ButtonComponent className="btn-hover" type="submit">
                            {t("zUsers.components.form.recovery.recovery-password")}
                        </ButtonComponent>
                    </WrapperButton>
                </form>
            </Wrapper>
            <ModalGeneric
                isOpen={openInfoSE}
                handleClose={() => setOpenInfoSE(false)}
            >
                <DialogContent>
                    <SubLabelInput>
                        {t("zUsers.components.form.recovery.email-password")}
                    </SubLabelInput>
                </DialogContent>
                <DialogActions style={{ padding: "2%" }}>
                    <ButtonComponent
                        className="btn-hover"
                        type="button"
                        onClick={() => handleSumbitSend()}
                    >
                        {t("zUsers.components.form.recovery.accept")}
                    </ButtonComponent>
                </DialogActions>
            </ModalGeneric>
            <Loading open={isLoading} />
            <AlertComponent
                verify={"error"}
                open={isErrorModal}
                onClose={() => setIsErrorModal(false)}
                text={`${alertText}`}
            />
        </Container>
    );
};
