import { Box, Typography } from "@mui/material";
//style
import {
    TitleWrapper,
    DoubleContainer,
    SubTitleWrapperGeneral,
    Wrapper,
    Container,
} from "../../styles/globalComponents";
//translate
import { useTranslation } from "react-i18next"

export interface InterfaceProjectEvaluate {
    name: string;
}

export const ProjectEvaluate = ({ name }: InterfaceProjectEvaluate) => {
    const [t, i18n] = useTranslation("global")
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="89vh"
        >
            <Container>
                <DoubleContainer
                    gridTemplateColumns="auto"
                    display="grid"
                    width="100%"
                >
                    <SubTitleWrapperGeneral padding="0 5% 0% 5%">
                        {t("zProjects.components.projectEvaluate.project")}
                    </SubTitleWrapperGeneral>
                    <TitleWrapper padding="0 0 0 5%">{name}</TitleWrapper>
                </DoubleContainer>

                <Wrapper
                    margin="5%"
                    heigth="auto"
                    padding="3%"
                    alingItems="center"
                    style={{boxSizing:"border-box",wordBreak: "break-all"}}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        style={{ textAlign: "center" }}

                    >
                        {t("zProjects.components.projectEvaluate.project-eva")}
                     
                    </Typography>
                </Wrapper>
            </Container>
        </Box>
    );
};