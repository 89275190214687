
//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'

//components
import { HomeAdminForm } from '../components/form/homeAdmin'

//data
import { dataHomeAdmin } from '../data/homeAdmin'

//translate
import { useTranslation } from "react-i18next"



export const HomeAdmin = () => {
  const [t, i18n] = useTranslation("global")
  return (
    <>
    <Mobile>
        <HomeAdminForm
             dataRender={ dataHomeAdmin(t) }  
             props= { {width:'80%;', heigth:'auto', imgWidth: 'calc(100% + 5px)'} } 
        />
    </Mobile>
    <Tablet>
        <HomeAdminForm
             dataRender={ dataHomeAdmin(t) }  
             props= { {width:'65%;', heigth:'auto', imgWidth: 'calc(100% + 5px)'} } 
        />
    </Tablet>
    <Desktop>
        <HomeAdminForm
             dataRender={ dataHomeAdmin(t) }  
             props= { {width:'50%;', heigth:'auto'} } 
        />    
    </Desktop>
    </>
  )
}
