import { useAppSelector } from "../../../hooks/redux";
import {
  Wrapper,
  DoubleContainer,
  TitleWrapper,
  ContainerGeneral,
  TitleWrapperGeneral,
  Container,
} from "../../../styles/globalComponents";
import { selectUser } from "../../../zUsers/slices/auth";
//translate
import { useTranslation } from "react-i18next"

export const TutorS = () => {
  const [t, i18n] = useTranslation("global")

   //users
   const user = useAppSelector(selectUser);
     
     
  return (
    <Container width="40%" heigth="80%">
      <Wrapper>
        <DoubleContainer gridTemplateColumns="auto" display="grid" width="100%">
          <TitleWrapper> {t("zProjects.components.form.tutorS.projects")} </TitleWrapper>
        </DoubleContainer>
        <ContainerGeneral width="90%;" heigth="90%">
          <TitleWrapperGeneral padding="0">
            {user.firstName}, {t("zProjects.components.form.tutorS.w-assign")}
          </TitleWrapperGeneral>
        </ContainerGeneral>
      </Wrapper>
    </Container>
  );
};
