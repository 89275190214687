//styles react
import styled  from 'styled-components'

//iconos
import { AiOutlineFile  } from 'react-icons/ai'
import { FiUsers } from 'react-icons/fi' 
import { RiSuitcaseLine,RiBankLine } from 'react-icons/ri' 

//styles info
import { dataImg } from '../../styles/Info';

//Interfaces
import { InterfaceStyle } from '../../interfaces/interface';


export const UsersIcon = styled(FiUsers)<InterfaceStyle>`
    width: ${(props) => props.width || '70%'};
    ${dataImg}
`
export const SuitCaseIcon = styled(RiSuitcaseLine)`
    width: ${(props) => props.width || '70%'};
    ${dataImg}
`
export const FileIcon = styled(AiOutlineFile)<InterfaceStyle>`
    width: ${(props) => props.width || '70%'};  
    ${dataImg}
`
export const BankIcon = styled(RiBankLine)<InterfaceStyle>`
    width: ${(props) => props.width || '70%'};
    ${dataImg}
`