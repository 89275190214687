import { ActionCreatorWithPayload } from "@reduxjs/toolkit/dist/createAction";
import { useState } from "react";
import { useAppDispatch } from "../../hooks/redux";
import { InterfaceActionSaveEvaluated } from "../../interfaces/evaluate";
import { InterfaceUseForm } from "../../interfaces/interface";
import { InterfaceScore } from "../interfaces/score";

export const useFormEvaluate = (
    initState: InterfaceScore,
    idProject: string,
    email: string,
    title:string,
    id:string,
    action: ActionCreatorWithPayload<InterfaceActionSaveEvaluated, string>
) => {
    const [formulario, setformulario] = useState(initState);
    //Event
    const dispatch = useAppDispatch();

    const handleChange = ({ field, value }: InterfaceUseForm) => {

        const update: InterfaceActionSaveEvaluated = {
            evaluateScore:{
                ...formulario,
                [field]: value,
            },
            idProject,
            email,
            title,
            id
        }

        setformulario({
            ...formulario,
            [field]: value,
        });

        dispatch(action(update));
    };
    return {
        ...formulario,
        handleChange,
        formulario,
    };
};
