//modules react
import { TextField } from "@mui/material";

import {
    WrapperInput,
    LabelInput,
    SubLabelInput,
} from "../zUsers/styles/Input";

import { InterfaceStyle } from "../interfaces/interface";
import { StyledAutocomplete } from "../styles/globalComponents";

export interface InterfaceAutocompleteComponent {
    props?: InterfaceStyle;
    data: string[];
    handle: (data: string) => void;
    valueDefault: string;
    placeholder: string;
    label?:string
    subLabel?:string
}



export const AutocompleteComponent = ({
    data,
    handle,
    placeholder,
    valueDefault,
    label,
    subLabel,
    props
}: InterfaceAutocompleteComponent) => {
    return (
        <WrapperInput heigth={"100"} {...props}>
            {label && (
                <LabelInput htmlFor={label}>{label.toUpperCase()}</LabelInput>
            )}
            {subLabel && <SubLabelInput>{subLabel}</SubLabelInput>}
            <StyledAutocomplete
                size="small"
                inputValue={valueDefault}
                onInputChange={(event, newInputValue) => {
                    if (event){
                        console.log('que paso ')
                        handle(newInputValue);
                    
                    }
                }}
                id="controllable-states-demo"
                options={data}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        style={{ padding: 0 }}
                    />
                )}
            />
        </WrapperInput>
    );
};
