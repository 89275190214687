import { createTheme } from "@mui/material";

export const colors = {
    background:{
        dark: '#000000',
        darkGrey: '#C5C5C5',
        lightGrey: '#DEDEDE',
        light: '#FFFFFF',
        eustoryBlue:'#182D79',
        darkblue:'#202434',
        cloudSky: '#ECF0FF',
        redHaze:'#B85255',
        strawberry:'#FFE9E9',
        lemonade:'#FFF9DB',
        inputs:'#C4C4C4',
        button: '#ECF0FF'
    },
    textIcons:{
        active: '#FFFFFF',
        placeholderDisabled:'#9B9B9B',
        dark:'#000000   '
    },
    variants: {
        redhaze:'#B85255',
        mint: '#DBFFE3'
    }
}

export const fonts = {
    body: `serif`,
    heading: `serif`,
    titleArchive: { 
        fontFamily: 'sans-serif',
        fontStyle: 'normal',
        fontSize: '4px',
        lineHeight: '24px'
    },
    subtitleArchive: {
        fontFamily: 'sans-serif',
        fontStyle: 'normal',
        fontSize: '1px',
        lineHeight: '18px'
    },
    button: {
        fontFamily: 'sans-serif',
        fontStyle: 'normal',
        fontSize: '4px',
        lineHeight: '18px'
    }

}

export const modal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 200,
    bgcolor: colors.background.lightGrey,
    border: '2px solid grey radius',
    boxShadow: 5,
    p: 1,
    textAlign: "center",
    fontFamily: "sans-serif",
    boxSizing: "border-box",
    borderRadius: "3%",
    display:"grid",
    alignContent:"center",

}

export const dataImgBase = `
    align-items: center;
    height: auto;
    font-size: x-large;
    font-family: ${fonts.body};
    text-decoration: none;

    &:hover {
        color: ${colors.background.darkGrey};
    }
`

export const dataImg = `
    ${dataImgBase}
    
    border: 3px solid black;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 5%;

    &:hover {
        color: ${colors.background.darkGrey};
        border: 3px solid ${colors.background.darkGrey};
    }
`
