//interace
import { InterfaceRoutesArray } from '../interfaces/interface'

//views
import { Logs } from './views/logs'



export const RouterLogs = (): InterfaceRoutesArray => 
    [
        {
            path:"/logs/:idProjectLogs/:paramsNameLogs",
            element: <Logs/>
        }
    ]

 
