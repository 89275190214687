import { memo } from 'react';
import Slider from '@mui/material/Slider';
import { common } from "@mui/material/colors";
import { InterfaceSliderForm } from "../interfaces/score";


export const SlideFive = memo(({value, handleChange,name,disable}:InterfaceSliderForm) => {
    return (
        <Slider
          valueLabelDisplay="auto"
          marks
          min={0}
          max={5}
          sx={{ color: common['black'] }}
          name={name}
          disabled={disable}
          value={value as number}
        onChange= {(e, score) => handleChange({field:name, value:score})}
        />
    );
})
export const SlideTen =  memo(({value, handleChange,name,disable}:InterfaceSliderForm) => {
  return (
      <Slider
        valueLabelDisplay="auto"
        marks
        min={0}
        max={10}
        name={name}
        value={value as number}
        sx={{ color: common['black'] }}
        disabled={disable}
        onChange= {(e, score) => handleChange({field:name, value:score})}
      />
  );
})
export const SlideTwenty = memo(({value, handleChange,name,disable}:InterfaceSliderForm) =>{
  return (
      <Slider
        valueLabelDisplay="auto"
        marks
        min={0}
        max={20}
        sx={{ color: common['black'] }}
        name={name}
        value={value as number}
        disabled={disable}
        onChange= {(e, score) => handleChange({field:name, value:score})}
      />
  );
})
