//React Modules
import { memo, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputAdornment, IconButton } from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import SearchIcon from "@material-ui/icons/Search";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TextField,
    DialogContent,
    DialogActions,
} from "@mui/material";

// Interfaces
import { InterfacePropsDataMenuProject } from "../../interfaces/project";

import {
    InterfaceTableBase,
    InterfaceTableForm,
    InterfaceTableContent,
    InterfaceTableContentFilter,
} from "../../interfaces/table";

//styles
import { NavbarLink } from "../../../styles/nav";

import { StyledMultiline } from "../../styles/materialUi";

//translate
import { useTranslation } from "react-i18next";

import {
    ButtonComponent,
    DoubleContainer,
    TitleWrapper,
    ReturnIco
} from "../../../styles/globalComponents";
import {
    LabelInput,
    SubLabelInput,
    WrapperInput,
} from "../../../zUsers/styles/Input";

//Components
import { AutocompleteComponent } from "../../../components/autocomplete";
import { TablePaginationGeneral } from "../paginate";
import { MoreComponent } from "../more";
import { Loading } from "../../../components/loading";
import { ModalGeneric } from "../../../components/modalGeneric";

//hooks
import { useAppDispatch } from "../../../hooks/redux";
import { useForm } from "../../../hooks/form";

//data
import { dataMenuProject, stateProject } from "../../data/project";

//api
import { usePutDeactivateMutation } from "../../../zUsers/api/auth";
import {
    useGetListProjectAdminFileQuery,
    useGetListProjectAdminQuery,
} from "../../api/project";

//utils
import { dateComplet, dateYears } from "../../../utils/utils";
import {
    InterfaceFileProject,
    InterfacePropsDataMenuFile,
} from "../../interfaces/file";
import { dataMenuFile } from "../../data/file";
import { DownloadIcon } from "../../styles/file";
import moment from "moment";

//slices

export const TableFileForm =
    ({ title, colum, select, button, props }: InterfaceTableBase): JSX.Element => {
        const [t, i18n] = useTranslation("global");
        //form hooks
        const {
            handleChange,
            page,
            rowsPerPage,
            search,
            count,
            data
        } = useForm<InterfaceTableForm<InterfaceFileProject>>({
            count: 0,
            page: 0,
            rowsPerPage: 25,
            search: "",
            filter1: "",
            filter2: "",
            data: [],
        });

        const [filter1, setFilter1] = useState('')
        const [filter2, setFilter2] = useState('')

        const {
            isLoading,
            currentData: rows,
            refetch,
            isFetching,
        } = useGetListProjectAdminFileQuery({
            skip: rowsPerPage * page,
            limit: rowsPerPage
        });

        //filter data of select
        const filterDataSelect = (
            data: InterfaceFileProject[],
            key: keyof InterfaceFileProject
        ) => {
            const dataReturn: Array<string> = [];
            if (key === "file") {

                return [
                    ".pdf",
                    ".doc",
                    ".docx",
                    ".mp3",
                    ".mp4",
                    ".avi",
                    ".xls",
                    ".xlsx",
                    ".PPTX",
                    ".PPT",
                    ".jpg",
                    ".jpeg",
                    ".png",
                    ".gif",
                    ".bmp",
                    ".svg",
                    ".txt",
                    ".rtf",
                    ".odt",
                    ".ods",
                    ".odp",
                    ".zip",
                    ".rar",
                    ".7z",
                    ".wav",
                    ".flac",
                    ".mkv",
                    ".mov"
                ]
            }
            for (const items of data) {
                if (key === "convocations") {

                    if (!dataReturn.includes(items[key] as string)) {
                        dataReturn.push(items[key] as string);
                    }
                }
            }

            return dataReturn;
        };

        // Change generic select filter camp
        const changeSelectGeneric = (
            value: string,
            index: number,
            key: string
        ) => {
            if (select && select[index]) {
                const name = select[index].name as keyof InterfaceFileProject;
                if (key === 'filter1') {
                    setFilter1(value)
                } else if (key === 'filter2') {
                    setFilter2(value)
                }
                !value ? requestSearch("") : requestSearch(value, name);


            }
        };
        //handleSelect1 chaneges
        const handleChangeSelect1 = (value: string) => { changeSelectGeneric(value, 0, "filter1"); };


        //handleSelect2 chaneges
        const handleChangeSelect2 = (value: string) => {
            changeSelectGeneric(value, 1, "filter2");
        };

        //actions
        const handleAndSet = [
            {
                handle: handleChangeSelect1,
                data: filter1,
            },
            {
                handle: handleChangeSelect2,
                data: filter2,
            },
        ];



        const requestSearch = (
            searchedVal: string,
            key?: keyof InterfaceFileProject
        ) => {
            const filteredRows = rows
                ? rows.results.filter((row) => {
                    if (key) {
                        if (key === "file") {
                            const filename = row[key] as string

                            return filename
                                .toLowerCase()
                                .includes(searchedVal.toLowerCase());


                        } else if (key === "convocations") {
                            const dateAndName = row[key] as string
                            return dateAndName
                                .toLowerCase()
                                .includes(searchedVal.toLowerCase());
                        }
                    } else {
                        for (const [key, value] of Object.entries(
                            row
                        )) {
                            if (key === 'url' || key === 'id') continue
                            const valueString = value as string;
                            if (valueString
                                .toLowerCase()
                                .includes(searchedVal.toLowerCase())) {
                                return true
                            } else {
                                continue
                            }

                        }

                        return false

                    }

                })
                : [];
            handleChange({ field: "search", value: searchedVal });
            handleChange({ field: "data", value: filteredRows });
        };

        const cancelSearch = () => {
            // handleChange({ field: "search", value: "" });
            // requestSearch(search);
        };

        if (rows && rows.count) {
            if (count != rows.count) {
                handleChange({ field: "count", value: rows.count });
            }
        }

        useEffect(() => {
            if (rows) {
                handleChange({ field: "data", value: rows.results });
            }
        }, [rows]);


        return (
            <>
                <TableContainer
                    sx={{
                        height: "90%",
                        width: "90%",
                        margin: "auto",
                        marginTop: "2%",
                        padding: "2%",
                        maxHeight: props.maxHeight
                    }}
                >
                    {/* Title + reverse */}
                    <DoubleContainer
                        gridTemplateColumns="auto"
                        display="grid"
                        width="100%"
                    >
                        <NavbarLink paddingLeft="0" to="#" onClick={() => window.history.back()}>
                            {" "}
                            <ReturnIco />
                            {t("zAdmin.components.form.tableFile.back")}{" "}
                        </NavbarLink>
                        <TitleWrapper padding="0 0 2% 0">
                            {" "}
                            {title}{" "}
                        </TitleWrapper>
                    </DoubleContainer>

                    {/* Button actions  */}
                    {button}

                    {/* Filters */}
                    <DoubleContainer alingItems="baseline">
                        {/* Search  */}
                        <DoubleContainer>
                            <WrapperInput heigth={"100"} width="100%">
                                <TextField
                                    placeholder={title}
                                    size={"small"}
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        if (value === "") {
                                            cancelSearch();
                                        } else {
                                            requestSearch(value);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </WrapperInput>
                        </DoubleContainer>

                        {/* Select filter  */}
                        <DoubleContainer>
                            {select &&
                                select.map((items, index) => {
                                    return (
                                        <AutocompleteComponent
                                            placeholder={
                                                items.placeholder as string
                                            }
                                            data={filterDataSelect(
                                                rows ? rows.results : [],
                                                items.name as unknown as keyof InterfaceFileProject
                                            )}
                                            handle={handleAndSet[index].handle}
                                            valueDefault={
                                                handleAndSet[index]
                                                    .data as string
                                            }
                                            key={`select-${index}`}
                                        />
                                    );
                                })}
                        </DoubleContainer>
                    </DoubleContainer>

                    {/* Content Tabla */}
                    <TableContent colum={colum} rows={data} />
                    {/* Paginate */}
                    <TablePaginationGeneral
                        page={count <= 0 ? 0 : page}
                        rowsPerPage={rowsPerPage}
                        onChange={handleChange}
                        count={count}
                    />
                    {/* Loading */}
                    <Loading open={isLoading || isFetching} />
                </TableContainer>
            </>
        );
    }


const TableContent = memo(
    ({ colum, rows }: InterfaceTableContentFilter<InterfaceFileProject>) => {
        //navigate
        const navigate = useNavigate();
        const [t, i18n] = useTranslation("global");
        //ref
        const rowData = useRef({} as InterfaceFileProject);

        const actionsMenu: InterfacePropsDataMenuFile = {
            editNameFile: () =>
                navigate(`/project-admin/${rowData.current.id}`),
            deleteFile: () => navigate(`/project-admin/${rowData.current.id}`),
        };

        const [isLoadingSorting, setLoadingSorting] = useState(false);

        //Constantes para el tema del ordenador por campos
        const [sortField, setSortField] = useState("");
        const [sortDirection, setSortDirection] = useState("asc");
        const sortableColumns = ['name', 'file', 'typeFile', 'createdAt', 'emailRepresent', 'convocations'];

        const isColumnSortable = (columnName: string) => {
            //console.log(columnName);
            return sortableColumns.includes(columnName);
        };


        const compareStrings = (a: string, b: string, direction: string) => {
            if (a < b) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a > b) {
                return direction === 'asc' ? 1 : -1;
            }

            return 0;
        };

        const compareNumber = (a: number, b: number, direction: string) => {
            if (direction === 'asc') {
                return a - b
            } else {
                return b - a
            }
        }

        /*const compareRole = (a: string | InterfaceFileProject, b: string | InterfaceFileProject, direction: string) => {
            const arole: InterfaceFileProject = a as InterfaceFileProject;
            const brole: InterfaceFileProject = b as InterfaceFileProject;

            if (arole.name < brole.name) {
                return direction === 'asc' ? -1 : 1;
            }
            if (arole.name > brole.name) {
                return direction === 'asc' ? 1 : -1;
            }

            return 0;
        }*/


        const compareByField = (field: string, a: InterfaceFileProject, b: InterfaceFileProject, direction: string) => {
            console.log(field);
            switch (field) {
                case 'name':
                    return compareStrings(a.name || '', b.name || '', direction);
                case 'file':
                    return compareStrings(a.file || '', b.file || '', direction);
                case 'typeFile':
                    return compareStrings(a.typeFile || '', b.typeFile || '', direction);
                case 'createdAt':
                    return compareStrings(a.createdAt || '', b.createdAt || '', direction);
                case 'emailRepresent':
                    return compareStrings(a.email ? a.email : '', b.email ? b.email : '', direction);
                case 'convocations':
                    return compareStrings(a.convocations || '', b.convocations || '', direction)
                /*
                case 'name':
                    return compareStrings(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`, direction);
                case 'email':
                    return compareStrings(a.email ? a.email : '', b.email ? b.email : '', direction);
                case 'createdAt':
                    return compareStrings(a.createdAt || '', b.createdAt || '', direction);
                case 'role':
                    return compareRole(a.role || '', b.role || '', direction);
                case 'status':
                    return a.active === b.active ? 0 : (a.active ? 1 : -1);*/
                default:
                    return 0;
            }
        };

        const sortedData = [...rows].sort((a, b) => {
            return compareByField(sortField, a, b, sortDirection);
        });

        const renderSortIcon = (field: string) => {
            if (isColumnSortable(field) && field === sortField) {
                return sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />;
            }

            return null;
        };

        //Ordenado
        const handleSort = (field: string) => {
            setLoadingSorting(true);

            // Verifica si el mismo campo se está ordenando nuevamente para cambiar la dirección
            const direction = field === sortField && sortDirection === "asc" ? "desc" : "asc";

            setTimeout(() => {

                setSortField(field); // Actualiza el campo actualmente ordenado

                setSortDirection(direction);
                // Actualiza la dirección de ordenamiento

                setLoadingSorting(false);

            }, 500)


        };



        return (
            <>     <div style={{ overflowX: 'auto', minWidth: '100%' }}>
                <Table
                    sx={{ minWidth: 500 }}
                    aria-label="custom pagination table"
                >
                    <TableHead>
                        <TableRow>
                            {colum.map((items) => (
                                <TableCell
                                    key={items.id}
                                    align={items.align}
                                    style={{
                                        minWidth: items.minWidth,
                                        fontWeight: items.fontWeight,
                                        cursor: isColumnSortable(items.id) ? 'pointer' : 'default',
                                    }}
                                    onClick={isColumnSortable(items.id) ? () => handleSort(items.id) : undefined}
                                >
                                    {renderSortIcon(items.id)}
                                    {items.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedData &&
                            sortedData.map((row, index) => (
                                <TableRow key={`${row.id} ${index}`}>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160, wordBreak: 'break-all' }}
                                        align="center"

                                    >
                                        {row.file}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.typeFile}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {dateComplet(row.createdAt)}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.email === 'Sin autor' ? t('zAdmin.components.form.tableFile.email-not-author') : row.email}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.convocations}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        <a
                                            href={row.url}
                                            target="_blank"
                                            style={{ color: "black" }}
                                        >
                                            <DownloadIcon />
                                        </a>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            cursor: "pointer"
                                        }}
                                        align="center"
                                        onClick={() => {
                                            rowData.current = row;
                                        }}
                                    >
                                        <MoreComponent
                                            items={dataMenuFile(t, actionsMenu)}
                                            props={{}}
                                            key={row.id}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
                {/* Loading */}
                <Loading open={isLoadingSorting} />
            </>
        );
    }
);
