//import react
import { FC } from "react";

//libraries
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

//
import { styled } from "@mui/material/styles";

//interfaces
import {
    InterfaceModalInfo,
    InterfaceDialogTitleProps,
} from "../interfaces/interface";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props: InterfaceDialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, padding: "5% 10% 1% 3%" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export const BasicModal: FC<InterfaceModalInfo> = ({
    open,
    setOpenModal,
    text,
    title,
}) => {
    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <div>
            <BootstrapDialog
                fullScreen={false}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {text.map((items, index) => {
                        return (
                            <Typography key={index} gutterBottom>
                                {" "}
                                {items}
                            </Typography>
                        );
                    })}
                </DialogContent>
                <DialogActions></DialogActions>
            </BootstrapDialog>
        </div>
    );
};
