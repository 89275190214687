import { TFunction } from "react-i18next";
import * as Yup from "yup";
import { validateDigitLettersAndSimbols } from "../../utils/validatesInput";

export const schemaProjectForm = (t: TFunction<"global", undefined>) => Yup.object().shape({
    name: Yup.string().trim()
        .required(t("zAdmin.validators.project.name-required"))
        .min(3, t("zUsers.validators.schemaRegisterNP.char"))
        .test(t("zAdmin.validators.project.name"), t("zAdmin.validators.project.invalid"), (value: string | undefined) =>
            validateDigitLettersAndSimbols(value)
        ),

        dateAnnouncements: Yup.string()
        .required(t("zAdmin.validators.project.conv-required"))
      
});
