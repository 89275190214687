import { TFunction } from 'react-i18next';
import { InterfaceHomeAdminArray } from '../interfaces/homeAdmin';
import { SuitCaseIcon, UsersIcon, BankIcon,FileIcon } from '../styles/homeAdmin';
//interfaces

export const dataHomeAdmin = (t: TFunction<"global", undefined>):InterfaceHomeAdminArray =>  [
    {
        props: { width:'auto' } ,
        ComponentIcon: UsersIcon,
        text1: t("zAdmin.data.homeAdmin.users"),
        text2: t("zAdmin.data.homeAdmin.text-users"),
        link: '/users'
    },
    {
        props: { width:'auto' } ,
        ComponentIcon: SuitCaseIcon,
        text1: t("zAdmin.data.homeAdmin.projects"),
        text2: t("zAdmin.data.homeAdmin.text-projects"),
        link: '/project'
    }
    ,
    {
        props: { width:'auto' } ,
        ComponentIcon: FileIcon,
        text1: t("zAdmin.data.homeAdmin.files"),
        text2: t("zAdmin.data.homeAdmin.text-files"),
        link: '/file'
    }
    ,
    {
        props: { width:'auto' } ,
        ComponentIcon: BankIcon,
        text1: t("zAdmin.data.homeAdmin.convocations"),
        text2: t("zAdmin.data.homeAdmin.text-convocations"),
        link: '/convocations'
    }
   
]