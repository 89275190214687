import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TextField,
    DialogContent,
    DialogActions,
} from "@mui/material";

// Interfaces
import {
    InterfaceTableBase,
    InterfaceTableForm,
    InterfaceTableContentFilter,
    InterfaceTableContent,
} from "../../interfaces/table";
import { InterfaceProjectLarge } from "../../../interfaces/project";
import { InterfaceDataUser, InterfaceRoleUser } from "../../../interfaces/user";
import { InterfacePropsDataMenuUser } from "../../interfaces/users";

//styles
import { NavbarLink } from "../../../styles/nav";
import {
    ButtonComponent,
    DoubleContainer,
    TitleWrapper,
    ReturnIco
} from "../../../styles/globalComponents";
import {
    LabelInput,
    SubLabelInput,
    WrapperInput,
} from "../../../zUsers/styles/Input";

//Components
import { AutocompleteComponent } from "../../../components/autocomplete";
import { TablePaginationGeneral } from "../paginate";
import { MoreComponent } from "../more";
import { Loading } from "../../../components/loading";

//hooks
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useForm } from "../../../hooks/form";

//data
import { dataMenuUser } from "../../data/users";

//api
import {
    useGetListUserQuery,
    usePostUserEmailMutation,
    usePutDeactivateAdminMutation,
    useDeleteProfileMutation
} from "../../api/users";

//utils
import { dateComplet, rolesViews } from "../../../utils/utils";

//slices
import { activeAlertAction } from "../../../slices/alerts";
import moment from "moment";
import { ModalGeneric } from "../../../components/modalGeneric";
import { StyledMultiline } from "../../styles/materialUi";
import { selectRole } from "../../../zUsers/slices/role";
import { filterDataUsers, rolesChangues } from "../../utils/users";

//translate
import { useTranslation } from "react-i18next";

export const TableUserForm = memo(
    ({ title, colum, select, button, props }: InterfaceTableBase): JSX.Element => {
        //form hooks
        const {
            handleChange,
            page,
            rowsPerPage,
            search,
            count,
            data,
        } = useForm<InterfaceTableForm<InterfaceDataUser>>({
            count: 0,
            page: 0,
            rowsPerPage: 25,
            search: "",
            filter1: "",
            filter2: "",
            data: [],
        });

        const [filter1, setFilter1] = useState('');
        const [filter2, setFilter2] = useState('');
        const [sortField, setSortField] = useState('');
        const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

        //roles
        const roles = useAppSelector(selectRole);

        const [t, i18n] = useTranslation("global");

        //apis
        const {
            isLoading,
            isFetching,
            currentData: rows,
        } = useGetListUserQuery({
            skip: rowsPerPage * page,
            limit: rowsPerPage,
            search: search.trim(),
            sortField,
            sortDirection,
        });

        //filter data of select
        const filterDataSelect = (
            data: InterfaceDataUser[],
            key: keyof InterfaceDataUser
        ) => {
            const dataReturn: Array<string> = [];
            if (key === "role") {
                const rolesSpanish = roles ? rolesChangues(roles, t) : [];
                for (const itemRole of rolesSpanish) {
                    dataReturn.push(itemRole.name);
                }
            }
            for (const items of data) {
                if (key === "records") {
                    const arrayRecords = items[key] as InterfaceProjectLarge[];
                    for (const project of arrayRecords) {
                        let nameFinishConvocations = "Sin convocatoria";
                        if (project.project) {
                            if (project.project.announcement && project.project.announcement.initialDate) {
                                const dateAnnonun = moment(project.project.announcement.initialDate).format("YYYY");
                                const nameConvocations = project.project.announcement.name;
                                nameFinishConvocations = `${dateAnnonun} - ${nameConvocations}`;
                            } else {
                                nameFinishConvocations = "Sin convocatoria";
                            }
                        } else {
                            nameFinishConvocations = "Sin proyecto";
                        }

                        if (!dataReturn.includes(nameFinishConvocations)) {
                            dataReturn.push(nameFinishConvocations);
                        }
                    }
                }
            }
            return dataReturn;

        };

        // Change generic select filter camp
        const changeSelectGeneric = (
            value: string,
            index: number,
            key: string
        ) => {
            if (select && select[index]) {
                const name = select[index].name as keyof InterfaceDataUser;
                if (key === 'filter1') {
                    setFilter1(value);
                } else if (key === 'filter2') {
                    setFilter2(value);
                }
                !value ? requestSearch("") : requestSearch(value, name);
            }
        };

        //handleSelect1 changes
        const handleChangeSelect1 = (data: string) => changeSelectGeneric(data, 0, "filter1");

        //handleSelect2 changes
        const handleChangeSelect2 = (data: string) => changeSelectGeneric(data, 1, "filter2");

        //actions
        const handleAndSet = [
            {
                handle: handleChangeSelect1,
                data: filter1,
            },
            {
                handle: handleChangeSelect2,
                data: filter2,
            },
        ];

        //filter search all keys
        const requestSearch = (
            searchedVal: string,
            key?: keyof InterfaceDataUser
        ) => {
            handleChange({ field: "search", value: searchedVal });
        };

        const cancelSearch = () => {
            handleChange({ field: "search", value: "" });
            useGetListUserQuery({
                skip: rowsPerPage * page,
                limit: rowsPerPage,
                search: "", // Restablecer el parámetro de búsqueda
                sortField,
                sortDirection,
            });
        };

        if (rows && rows.count) {
            if (count != rows.count) {
                handleChange({ field: "count", value: rows.count });
            }
        }

        useEffect(() => {
            if (rows) {
                handleChange({ field: "data", value: rows.results });
            }
        }, [rows]);

        const handleSort = (field: string) => {
            const direction = field === sortField && sortDirection === "asc" ? "desc" : "asc";
            //console.log(field)
            setSortField(field);
            setSortDirection(direction);
        };

        return (
            <>
                <TableContainer
                    sx={{
                        height: "90%",
                        width: "90%",
                        margin: "auto",
                        marginTop: "2%",
                        padding: "2%",
                        maxHeight: props.maxHeight
                    }}
                >
                    {/* Title + reverse */}
                    <DoubleContainer
                        gridTemplateColumns="auto"
                        display="grid"
                        width="100%"
                    >
                        <NavbarLink paddingLeft="0" to="#" onClick={() => window.history.back()}>
                            {" "}
                            <ReturnIco />
                            {t("zAdmin.components.form.tableUser.back")}{" "}
                        </NavbarLink>
                        <TitleWrapper padding="0 0 2% 0">
                            {" "}
                            {title}{" "}
                        </TitleWrapper>
                    </DoubleContainer>

                    {/* Button actions  */}
                    {button}

                    {/* Filters */}
                    <DoubleContainer alingItems="baseline">
                        {/* Search  */}
                        <DoubleContainer>
                            <WrapperInput heigth={"100"} width="100%">
                                <TextField
                                    placeholder={title}
                                    size={"small"}
                                    value={search} // Asegúrate de que el valor del campo de búsqueda esté controlado
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        if (value === "") {
                                            cancelSearch();
                                        } else {
                                            requestSearch(value);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </WrapperInput>
                        </DoubleContainer>

                        {/* Select filter  */}
                        <DoubleContainer>
                            {select &&
                                select.map((items, index) => {
                                    return (
                                        <AutocompleteComponent
                                            placeholder={
                                                items.placeholder as string
                                            }
                                            data={filterDataSelect(
                                                rows ? rows.results : [],
                                                items.name as unknown as keyof InterfaceDataUser
                                            )}
                                            handle={handleAndSet[index].handle}
                                            valueDefault={
                                                handleAndSet[index]
                                                    .data as string
                                            }
                                            key={`select-${index}`}
                                        />
                                    );
                                })}
                        </DoubleContainer>
                    </DoubleContainer>

                    {/* Content Tabla */}
                    <TableContent colum={colum} rows={data} handleSort={handleSort} sortField={sortField} sortDirection={sortDirection} />

                    {/* Paginate */}
                    <TablePaginationGeneral
                        page={count <= 0 ? 0 : page}
                        rowsPerPage={rowsPerPage}
                        onChange={handleChange}
                        count={count}
                    />
                    {/* Loading */}
                    <Loading open={isLoading || isFetching} />
                </TableContainer>
            </>
        );
    }
);

const TableContent = memo(
    ({ colum, rows, handleSort, sortField, sortDirection }: InterfaceTableContentFilter<InterfaceDataUser> & { handleSort: (field: string) => void; sortField: string; sortDirection: 'asc' | 'desc' }) => {
        const [t, i18n] = useTranslation("global");
        //navigate
        const navigate = useNavigate();

        //ref
        const rowData = useRef({} as InterfaceDataUser);
        const textEmail = useRef(
            t("zAdmin.components.form.tableUser.info-text")
        );

        //
        const dispatch = useAppDispatch();
        const [openSendEmail, setOpenSendEmail] = useState(false);

        //api
        const [putDeactivate, { isLoading: isLoadingDeactivate }] =
            usePutDeactivateAdminMutation();
        const [postUserEmail, { isLoading: isLoadingEmail }] =
            usePostUserEmailMutation();
        const [deleteProfile, { isLoading: isLoadingDelete }] =
            useDeleteProfileMutation();

        const handleEditDataUser = () => {
            const id = rowData.current._id;
            return navigate(`/profile/${id}`);
        };

        const handleDeactivateUser = async () => {
            const { _id: id, active } = rowData.current;
            const textActive = active ? t("zAdmin.components.form.tableUser.inactive") : t("zAdmin.components.form.tableUser.active");
            try {

                const respDeactivate = await putDeactivate({
                    id,
                }).unwrap();

                if ("response" in respDeactivate) {
                    return dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            isText: `${t(
                                "zAdmin.components.form.tableUser.error-email"
                            )} ${textActive}`,
                        })
                    );
                }

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: `${t("zAdmin.components.form.tableUser.error-email")} 
                        ${textActive} 
                        ${t("zAdmin.components.form.tableUser.user-correctly")}`,
                    })
                );
            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: `${t("zAdmin.components.form.tableUser.error-ocurred")} 
                        ${textActive} 
                        ${t("zAdmin.components.form.tableUser.user")}`,
                    })
                );
            }
        };

        const handleSendEmailUser = async () => {
            const { _id: id } = rowData.current;
            setOpenSendEmail(false);
            try {
                const respDeactivate = await postUserEmail({
                    id,
                    textInformation: textEmail.current,
                }).unwrap();

                if ("response" in respDeactivate) {
                    return dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            isText: t(
                                "zAdmin.components.form.tableUser.error-email"
                            ),
                        })
                    );
                }

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t(
                            "zAdmin.components.form.tableUser.email-success"
                        ),
                    })
                );
            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t(
                            "zAdmin.components.form.tableUser.error-email"
                        ),
                    })
                );
            }
        };

        //Nueva opción para borrar usuarios (experimental)
        //Evento que se hace al pulsarlo
        const handleDeleteUser = async () => {
            try {
                const { _id: id } = rowData.current;
                const respDeleteProfile = await deleteProfile({
                    id,
                }).unwrap();

                if ("response" in respDeleteProfile) {
                    return dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            isText: `${t("zAdmin.components.form.tableUser.delete-profile-error")} 
                                     ${"Error"}`,
                        })
                    );
                }

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: `${t("zAdmin.components.form.tableUser.delete-profile-success")}`,
                    })
                );

            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: `${"Error"} 
                                 ${t("zAdmin.components.form.tableUser.delete-profile-error")}`,
                    })
                );
            }
        };

        const actionsMenu: InterfacePropsDataMenuUser = {
            editProjectAndRoles: () =>
                navigate(`/asignations/${rowData.current._id}`),
            editUser: () => handleEditDataUser(),
            off: () => handleDeactivateUser(),
            sendEmail: () => setOpenSendEmail(true),
            deleteUser: () => handleDeleteUser(),
        };

        const renderSortIcon = (field: string) => {
            if (field === sortField) {
                return sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />;
            }
            return null;
        };

        return (
            <>
                <div className="w-4 scrollbar-hide">
                    <Table
                        sx={{ minWidth: 500 }}
                        aria-label="custom pagination table"
                    >
                        <TableHead>
                            <TableRow>
                                {colum.map((items) => (
                                    <TableCell
                                        key={items.id}
                                        align={items.align}
                                        style={{
                                            minWidth: items.minWidth,
                                            fontWeight: items.fontWeight,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSort(items.id)}
                                    >
                                        {items.label} {renderSortIcon(items.id)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody >
                            {rows &&
                                rows.map((row, index) => (
                                    <TableRow key={row.email}>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {`${row.firstName} ${row.lastName}`}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {row.email}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {row.records.map((projects) => {

                                                const project =
                                                    projects as InterfaceProjectLarge;

                                                if (project.project) {


                                                    const dateAnnonun = project.project
                                                        .announcement
                                                        ? moment(
                                                            project.project
                                                                .announcement
                                                                .initialDate
                                                        ).format("YYYY")
                                                        : "Sin fecha";
                                                    const nameProject =
                                                        project.project.name;
                                                    const role = project.role.name;
                                                    return `${dateAnnonun} - ${nameProject} - ${role}`;
                                                } else {
                                                    return "Proyecto borrado"
                                                }
                                            })}
                                        </TableCell>

                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {dateComplet(row.createdAt)}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {row.active ? t("zAdmin.components.form.tableUser.active") : t("zAdmin.components.form.tableUser.inactive")}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {typeof row.role === "string"
                                                ? ""
                                                : rolesViews(row.role, t)}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {row.records.map((projects) => {
                                                const project =
                                                    projects as InterfaceProjectLarge;
                                                let nameFinishConvocations =
                                                    t("zAdmin.components.form.tableUser.w-conv");
                                                if (project.project) {
                                                    if (project.project.announcement) {
                                                        const dateAnnonun = moment(
                                                            project.project.announcement
                                                                .initialDate
                                                        ).format("YYYY");
                                                        const nameConvocations =
                                                            project.project.announcement
                                                                .name;

                                                        nameFinishConvocations = `${dateAnnonun} - ${nameConvocations}`;
                                                    }
                                                    return nameFinishConvocations;
                                                } else {
                                                    return "Proyecto borrado"
                                                }
                                            })}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                width: 160,
                                                cursor: "pointer"
                                            }}
                                            align="center"
                                            onClick={() => {
                                                rowData.current = row;
                                            }}
                                        >
                                            <MoreComponent
                                                items={dataMenuUser(t, actionsMenu)}
                                                props={{}}
                                                key={`moreComponent-${row.email}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>

                    <ModalGeneric
                        handleClose={() => setOpenSendEmail(false)}
                        isOpen={openSendEmail}
                    >
                        <DialogContent>
                            <WrapperInput>
                                <LabelInput htmlFor={"sendEmail"}>
                                    {t(
                                        "zAdmin.components.form.tableUser.send-email"
                                    )}
                                </LabelInput>
                                <SubLabelInput>
                                    {t(
                                        "zAdmin.components.form.tableUser.send-email-text"
                                    )}
                                </SubLabelInput>
                                <StyledMultiline
                                    id="outlined-multiline-static"
                                    onChange={(e) => {
                                        textEmail.current = e.target.value;
                                    }}
                                    style={{ width: "100%" }}
                                    multiline
                                    rows={4}
                                    defaultValue={t(
                                        "zAdmin.components.form.tableUser.info-text"
                                    )}
                                />
                            </WrapperInput>
                        </DialogContent>
                        <DialogActions style={{ padding: "2%" }}>
                            <ButtonComponent
                                className="btn-hover"
                                type="button"
                                onClick={() => handleSendEmailUser()}
                            >
                                {t("zAdmin.components.form.tableUser.send")}
                            </ButtonComponent>
                        </DialogActions>
                    </ModalGeneric>
                </div>

                {/* Loading */}
                <Loading open={isLoadingDeactivate || isLoadingEmail || isLoadingDelete} />
            </>
        );
    }
);
