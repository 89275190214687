//interfaces
import { TFunction } from "react-i18next";
import { useAppSelector } from "../../hooks/redux";
import {
    InterfaceErrorsInputRegister,
    InterfacedataInputArray,
    InterfaceCountryArray,
    InterfaceRegisterProps,
    InterfaceRadioComponen,
} from "../interfaces/register";

export const dataBaseRegister = (t: TFunction<"global", undefined>,
    errors: InterfaceErrorsInputRegister
): InterfacedataInputArray => [
        [
            {
                registerLabel: "firstName",
                errors: errors.firstName,
                label: t("zUsers.data.register.name"),
                type: "text",
            },
            {
                registerLabel: "lastName",
                errors: errors.lastName,
                label: t("zUsers.data.register.last-name"),
                type: "text",
            },
        ],
        {
            registerLabel: "phone",
            errors: errors.phone,
            label: t("zUsers.data.register.phone"),
            type: "text",
            placeholder: t("zUsers.data.register.phone-holder"),
        },
        {
            registerLabel: 'birthday',
            errors: errors.birthday,
            label: t("zUsers.data.register.birthday"),
            type: 'text',
        },
        {
            registerLabel: "grade",
            subLabel: t("zUsers.data.register.grade-not-required"),
            errors: errors.grade,
            label: t("zUsers.data.register.grade"),
            type: "text",
        },
        {
            registerLabel: "schoolCenter",
            errors: errors.schoolCenter,
            label: t("zUsers.data.register.schoolCenter"),
            type: "text",
        },
        {
            registerLabel: "schoolEmail",
            errors: errors.schoolEmail,
            label: t("zUsers.data.register.schoolEmail"),
            type: "text",
            placeholder: t("zUsers.data.register.email-holder"),
        },
        {
            registerLabel: "population",
            errors: errors.population,
            label: t("zUsers.data.register.population"),
            type: "text",
        },
        {
            registerLabel: "street",
            errors: errors.street,
            label: t("zUsers.data.register.street"),
            type: "text",
        },

        {
            registerLabel: "province",
            errors: errors.province,
            label: t("zUsers.data.register.province"),
            type: "text",
        },
        [
            {
                registerLabel: "country",
                errors: errors.country,
                label: t("zUsers.data.register.country"),
                type: "text",
            },
            {
                registerLabel: "zipCode",
                errors: errors.zipCode,
                label: t("zUsers.data.register.zip-code"),
                type: "text",
            },
        ],
        {
            registerLabel: "password",
            errors: errors.password,
            label: t("zUsers.data.register.password"),
            subLabel: t("zUsers.data.register.password-sL"),
            type: "password",
            placeholder: t("zUsers.data.register.password-holder")
        },
        {
            registerLabel: "confirmPassword",
            errors: errors.confirmPassword,
            label: t("zUsers.data.register.check-password"),
            type: "password",
            placeholder: t("zUsers.data.register.check-holder"),
        },
        {
            registerLabel: "roleType",
            errors: errors.roleType,
            label: t("zUsers.data.register.roleType"),
            type: "text",
            placeholder: t("zUsers.data.register.roleType-holder"),
        }
    ];

export const dataRegister = (t: TFunction<"global", undefined>,
    errors: InterfaceErrorsInputRegister
): InterfacedataInputArray => [
        {
            registerLabel: "email",
            errors: errors.email,
            label: t("zUsers.data.register.email"),
            type: "email",
            placeholder: t("zUsers.data.register.email-holder"),
        },
        ...dataBaseRegister(t, errors),
    ];

export const INIT_DATA_USER_FORM: InterfaceRegisterProps = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    birthday: "",
    grade: "",
    schoolCenter: "",
    schoolEmail: "",
    street: "",
    province: "",
    country: "",
    zipCode: "",
    population: "",
    role: "",
    roleType: "",
};


export const dataCountry: InterfaceCountryArray =
    require("../data/countries.json")["countries"];

dataCountry.sort((a, b) => {
    if (a.name_es > b.name_es) {
        return 1;
    }
    if (a.name_es < b.name_es) {
        return -1;
    }
    // a must be equal to b
    return 0;
});

export const dataCountryArray = dataCountry.map((items) => items["name_es"]);
