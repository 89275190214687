//React Module
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';

//Api
import { usePostConfirmMutation } from "../api/auth";

//Components
import { Loading } from "../../components/loading";
import { Component404 } from '../../views/404';

//hooks
import { useAppDispatch } from "../../hooks/redux";

//slices
import { singupAction } from "../slices/auth";

//interfaces
import { InterfaceProjectLarge } from "../../interfaces/project";
import { InterfaceRoleUser } from '../../interfaces/user';

export const Confirm = () => {

    //routes
    const navigate = useNavigate();
    const { id } = useParams();

    //states
    const [error, setError] = useState(false)

    //Event redux
    const dispatch = useAppDispatch();

    //apis
    const [postConfirm, { isLoading }] = usePostConfirmMutation();


    const confirmUser = async () => {
        try {
            const userReturn = await postConfirm({ id } as { id: string }).unwrap();

            const userRole = userReturn.user.role as InterfaceRoleUser

            dispatch(
                singupAction(userReturn)
            );

            if (userRole.name === "representative") {
                let projects = userReturn.user.records as InterfaceProjectLarge[]
                for (let i= projects.length -1; i >= 0; i--){
                    
                    if (projects[i].project.announcement.active){
                        return navigate("/");
                    }
                }
                navigate("/register/nameProject/");
            } else {
                navigate("/")
            }
            
        } catch (error) {
            setError(true)
        }
        
      
    };

    //Effects confirm users
    useEffect(() => {
        if (!id) {
            console.log(id)
            setError(true)
        } else {
            confirmUser();
        }
    
    },[])

    return (
        <>
            {/* Loading page */}
            <Loading open={isLoading} />
            {error && <Component404/>}
        </>
    );
};
