import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import {
    validateOnlyLetters,
    validateDigitAndLetters,
    validateDigitLettersAndSimbols
} from '../../utils/validatesInput';

export const schemaRegisterNP = (t: TFunction<"global", undefined>) =>  Yup.object().shape(
    {
        
        nameProject: Yup.string().trim()
            .required(t("zUsers.validators.schemaRegisterNP.name-req"))
            .min(3, t("zUsers.validators.schemaRegisterNP.char"))
            .test(
                'only-letters',
                t("zUsers.validators.schemaRegisterNP.valid-char"),
                (value: string | undefined) => validateDigitLettersAndSimbols(value)
            ),
                        
    },
);
