//import module react
import { useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

//style
import { 
    Title, 
    Container, Wrapper, 
    ButtonComponent, WrapperButton,
    TitleWrapper,
} from '../../../styles/globalComponents';
import { schemaRecovery } from "../../validators/schemaRecovery";
import { ModalGeneric } from "../../../components/modalGeneric";

//components
import { InputComponent } from '../Input'
import { Loading } from '../../../components/loading';
import { AlertComponent } from '../../../components/alert';

//data
import { dataRegisterNP } from '../../data/registerNP';

//interfaces
import { InterfaceRegisterNPProps } from '../../interfaces/registerNP';
import { InterfaceStyle } from '../../../interfaces/interface';

//hooks
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

//slices
import { projectAction, selectUser } from '../../slices/auth';

//translate
import { useTranslation } from "react-i18next";

//validators
import { schemaRegisterNP } from "../../validators/schemaRegisterNP";

//apis
import { usePostCreatePRMutation } from '../../../zProjects/api/project';
import { useGetListMAnnouncementsMutation } from '../../../zAdmin/api/announcements'

export const FormRegisterNP = (props:InterfaceStyle) => {
    const [t, i18n] = useTranslation("global");

    //users
    const user = useAppSelector(selectUser);

    //Event
    const dispatch = useAppDispatch();

    //apis
    const [ postCreatePR, { isLoading:isLoadingRNP } ] =
    usePostCreatePRMutation()

    const [ getListAnnouncements, { isLoading:isLoadingLA } ] =
    useGetListMAnnouncementsMutation()
    
    //state
    const [isErrorModal, setIsErrorModal] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<InterfaceRegisterNPProps>({
        resolver: yupResolver(schemaRegisterNP(t))
    }); 

    const navigate = useNavigate();

    const handleSumbitRegisterNP = async (data:InterfaceRegisterNPProps) => {

        const requestRNP = {
            name: data.nameProject,
            representative: user._id,
            announcement: ""
          }

        try {
            const responseRNP = await getListAnnouncements().unwrap()
            for (let i = 0; i < responseRNP.length; i++ ){
                if (responseRNP[i].active){
                    requestRNP.announcement = responseRNP[i]._id

                    const responseCP = await postCreatePR(requestRNP).unwrap()
                    await dispatch(projectAction(responseCP));

                    return navigate('/')
                }
            }
        } catch (error) {
            setIsErrorModal(true)
        }
            
    };

  return (
    <Container width={props.width}>
        <Title>{t("zUsers.components.form.registerNP.sign-eustory")}</Title>
        <Wrapper>
            
            <form onSubmit={handleSubmit((data) => handleSumbitRegisterNP(data))}>

                {
                    dataRegisterNP(t,errors).map(( 
                        {registerLabel, errors, subLabel,
                        label, type, mask,placeholder}) => {
                    
                        return (<InputComponent
                            {...register(registerLabel)}
                            errors={ errors }
                            label={ label }
                            subLabel={ subLabel }
                            type={ type }
                            mask={ mask }
                            placeholder={ placeholder }
                        />)
                    
                    })
                }
                
                <WrapperButton paddingTop='0'>
                    <ButtonComponent className="btn-hover" type="submit">
                        {t("zUsers.components.form.registerNP.continue")}
                    </ButtonComponent>
                </WrapperButton>
            </form>
        </Wrapper>
        <Loading open={isLoadingLA || isLoadingRNP} />
                <AlertComponent
                    verify={"error"}
                    open={isErrorModal}
                    onClose={() => setIsErrorModal(false)}
                    text={`${alert}`}
                />
    </Container>
  );
};
