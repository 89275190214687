

//utils
import { memo } from "react";
import { Mobile, Desktop, Tablet } from "../../utils/responsive";

//components
import { ButtonUser } from "../components/buttom/user";
//data
import { TableUserForm } from "../components/form/tableUser";
import { dataColumnsUser, dataSelectUser } from "../data/users";
//translate
import { useTranslation } from "react-i18next"

export const TableUser = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Mobile>
                <TableUserForm
                    colum={dataColumnsUser(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleUsers")}
                    button={<ButtonUser />}
                    select={dataSelectUser(t)}
                />
            </Mobile>
            <Tablet>
                <TableUserForm
                    colum={dataColumnsUser(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleUsers")}
                    button={<ButtonUser />}
                    select={dataSelectUser(t)}
                />
            </Tablet>
            <Desktop>
                <TableUserForm
                    colum={dataColumnsUser(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleUsers")}
                    button={<ButtonUser />}
                    select={dataSelectUser(t)}
                />
            </Desktop>
        </>
    );
};
