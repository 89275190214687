
//style
import {  Container } from '../../styles/globalComponents';
import { 
    DoubleContainer,
    SubTitleWrapperGeneral,
    WrapperGeneral
 } from '../../styles/globalComponents';

//interfaces
import { InterfaceLogs } from '../interfaces/logs';




export const History = (  { props, text, }: InterfaceLogs  ) => {

  return (
    <Container width={props.width || '100%'} heigth={props.heigth} style={ {display:'grid'}}>
    <WrapperGeneral  style={ {display:'grid'}}>
            <DoubleContainer gridTemplateColumns='auto' >
                <SubTitleWrapperGeneral style={{padding:'3%'}}>
                    { text }
                </SubTitleWrapperGeneral> 
            </DoubleContainer>
    </WrapperGeneral>
    </Container>
  )
}
