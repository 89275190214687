//interfaces
import {  interfaceArchiveArray } from "../interfaces/project";

//styles
//--icons
import { CheckIcon, FileUploadIcon, InfoIcon, MoreIcon } from "../styles/project";
import { dateComplet, dateDay, pathFile } from "../../utils/utils";
import { InterfaceProject } from "../../interfaces/project";
import { TFunction } from "react-i18next";


        

export const dataJury = (data: InterfaceProject, t: TFunction<"global", undefined>):interfaceArchiveArray =>  [
  
    {
        props: { width: "auto", textDecoration: "underline" },
        ComponentIcon: data.workFile ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: InfoIcon,
            menuItems: [],
            info: {
                content: [
                    t("zProjects.data.jurado.info"),
                ],
                title: t("zProjects.data.jurado.info-tutor-title"),
            },
        },
        text1: data.workFile ? pathFile(data.workFile.key) : t("zProjects.data.alumno.report-work"),
        text2: data.workFile
            ? t("zProjects.data.admin.upload") + dateComplet(data.workFile.date) + ( data.representative&&data.representative.firstName?t("zProjects.data.representative.by") + data.representative.firstName:'')
            : t("zProjects.data.admin.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.admin.days"),

        text3: data.workFile ? undefined : t("zProjects.data.jurado.c-upload"),
        typeFile: 'work',
        data: data.workFile
    },
    {
        props: { width: "auto", textDecoration: "underline" },
        ComponentIcon: data.workReport ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: InfoIcon,
            menuItems: [],
            info: {
                content: [
                    t("zProjects.data.jurado.iframe"),
                ],
                title: t("zProjects.data.jurado.info-title-report"),
            },
        },
        text1: data.workReport ? pathFile(data.workReport.key) : t("zProjects.data.alumno.report-e-work"),
        text2: data.workReport
            ? t("zProjects.data.admin.upload") + dateComplet(data.workReport.date) + ( data.representative&&data.representative.firstName?t("zProjects.data.representative.by") + data.representative.firstName:'')
            : t("zProjects.data.admin.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.admin.days"),

        text3: data.workReport ? undefined :  t("zProjects.data.jurado.c-upload"),
        typeFile: 'report',
        data: data.workReport
        
    },
    {
        props: { width: "auto" },
        ComponentIcon: data.tutorReport ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: InfoIcon,
            menuItems: [],
            info: {
                content: [
                    t("zProjects.data.jurado.info-tutor-content"),
                ],
                title: t("zProjects.data.jurado.iframe-title"),
            },
        },
        text1: data.tutorReport ? pathFile(data.tutorReport.key) : t("zProjects.data.jurado.report-tutor"),
        text2: data.tutorReport
            ? t("zProjects.data.jurado.upload") + dateComplet(data.tutorReport.date) + t("zProjects.data.jurado.by") + ( data.representative&&data.representative.firstName?t("zProjects.data.representative.by") + data.representative.firstName:'')
            : t("zProjects.data.jurado.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.jurado.days"),
        typeFile: 'tutor',
        data: data.tutorReport
    }
]