//React Module
import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query";

//store
import { RootState } from "./store";

//slices
import { logoutAction } from "../../eustory/zUsers/slices/auth";




/* A function that is called by the `baseQueryWithLogout` function. */
export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BACK,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).user.token;
        

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
    },
});

//
/**
 * It calls the baseQuery function, and if the result is an error with a 401 status code, it dispatches
 * the logoutAction
 * @param args - The arguments passed to the query function.
 * @param api - The api object that was passed to the useApi hook.
 * @param extraOptions - This is an object that can be passed to the query function. It can be used to
 * pass in extra options to the query function.
 * @returns The result of the baseQuery function, with the addition of a meta property.
 */
export const baseQueryWithLogout: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        const route = window.location.href.split('/');
        if (result.error.status === 401) {
            if (route[route.length - 1] !== "signin") {
                api.dispatch(logoutAction());
                window.location.href = '/auth/signin';
            }
        } else if (result.error.status === 403) {
            toast.error('No tienes permiso para ejecutar esta acción');
        } else {
            toast.error('Ocurrió un error. Por favor, inténtalo de nuevo.');
        }
    }

    return result;
};