import { InterfaceDataUser, InterfaceRoleUser } from "../interfaces/user";
import { InterfaceAuthUser } from "../zUsers/interfaces/login";
import { InterfaceAlertsGeneric } from '../interfaces/interface'
import { InterfaceEvaluatedCache } from "../interfaces/evaluate";

export const INITIAL_STATE_USER: InterfaceDataUser = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  birthday: "",
  grade: "",
  street: "",
  city: "",
  province: "",
  country: "",
  zipCode: "",
  role: {
    __v: 0,
    name: "",
    _id: "",
  },
  schoolCenter: "",
  schoolEmail: "",
  roleType: "",
  active: false,
  confirm: false,
  createdAt: "",
  records: [],
  updatedAt: "",
  __v: 0,
  _id: "",
};

export const INITIAL_AUTH_USER: InterfaceAuthUser = {
  user: INITIAL_STATE_USER,
  isLoggin: false,
  token: "",
  restorePW: {
    email: "",
    confirm: false,
  },
};

export const INITIAL_ROLES: InterfaceRoleUser[] = []

export const INITIAL_EVALUATE:InterfaceEvaluatedCache = {
    evaluate: {},
    info: {}
} 

export const DATA_ROL:InterfaceRoleUser[] = [
  {
    _id: "62dac9ef510ecd4dbd15f181",
    name: "student",
    __v: 0,
  },
  {
    _id: "62dac9f5510ecd4dbd15f183",
    name: "admin",
    __v: 0,
  },
  {
    _id: "62dac9fc510ecd4dbd15f185",
    name: "representative",
    __v: 0,
  },
  {
    _id: "62daca02510ecd4dbd15f187",
    name: "tutor",
    __v: 0,
  },
  {
    _id: "62daca07510ecd4dbd15f189",
    name: "jury",
    __v: 0,
  }
];


export const INITIAL_ALERTS:InterfaceAlertsGeneric = {
    isActive: false,
    isText: '',
    isMode: 'info',
}