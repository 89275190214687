//interfaces
import { InterfaceMenuBase, InterfaceSelectData } from "../interfaces/table";
import { InterfaceDataUser } from '../../interfaces/user';
import {
    InterfaceColumnUser,
    InterfacePropsDataMenuUser,
    TypeUser,
} from "../interfaces/users";
import { TFunction } from "react-i18next";



export const dataColumnsUser=(t: TFunction<"global", undefined>): InterfaceColumnUser[] => [
    { 
        id: "name", 
        label: t("zAdmin.data.users.name"), 
        align: "center", 
        minWidth: 100, 
        fontWeight: "bold" },
    {
        id: "email",
        label: t("zAdmin.data.users.email"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "project",
        label: t("zAdmin.data.users.project"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "createdAt",
        label: t("zAdmin.data.users.date-create"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toFixed(2),
        fontWeight: "bold",
    },
    {
        id: "status",
        label: t("zAdmin.data.users.state"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "role",
        label: t("zAdmin.data.users.rol"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "convocations",
        label: t("zAdmin.data.users.convocations"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "options",
        label: t("zAdmin.data.users.options"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
];

export const dataSelectUser=(t: TFunction<"global", undefined>): InterfaceSelectData[] => [
    {
        name: "role",
        placeholder:  t("zAdmin.data.users.filter-role"),
    },
    {
        name: "records",
        placeholder:  t("zAdmin.data.users.filter-conv"),
    },
];

export const dataMenuUser = (t: TFunction<"global", undefined>,{
    editProjectAndRoles,
    editUser,
    off,
    sendEmail,
    deleteUser,
}: InterfacePropsDataMenuUser): InterfaceMenuBase[] => [
    {
        text: t("zAdmin.data.users.edit-data"),
        action: "patch",
        onClick: editUser,
    },
    {
        text: t("zAdmin.data.users.edit-project"),
        action: "patch",
        onClick: editProjectAndRoles,
    },
    {
        text: t("zAdmin.data.users.d-a"),
        action: "patch",
        onClick: off,
    },
    {
        text: t("zAdmin.data.users.send-email"),
        action: "post",
        onClick: sendEmail,
    },
    //Asigno el texto/traducción, si no la encuentra pone la opción por defecto, en este caso "zAdmin.data.users.delete-user"
    //Luego borro el comentario, pero así recordaré que en "data" están las asignaciones de texto-idioma
    {
        text: t("zAdmin.data.users.delete-user"),
        action: "patch",
        onClick: deleteUser,
    },
];


export const dataTypeUser: TypeUser[] = [
    "admin",
    "student",
    "jurado",
    "representante",
    "tutor",
];

export const INIT_DATA_TABLE_USERS:InterfaceDataUser[] = [
    
]
