export const validatePassword = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  return /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/.test(value);
}

export const validateOnlyLetters = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  //Se amplia la validación para que realmente sean sólo letras, tampoco números
  return /[\D]{3}/.test(value); //<-- Antiguo
  //return /^[a-zA-ZáÁéÉíÍóÓúÚüÜ]+(?:\s[a-zA-ZáÁéÉíÍóÓúÚüÜ]+)*$/.test(value.trim());
  //return /^\p{L}[\p{L}\s'-]*$/u.test(value.trim());

}

export const validateOnlyLettersForName = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  //Se amplia la validación para que realmente sean sólo letras, tampoco números
  //return /[\D]{3}/.test(value); //<-- Antiguo
  //return /^[a-zA-ZáÁéÉíÍóÓúÚüÜ]+(?:\s[a-zA-ZáÁéÉíÍóÓúÚüÜ]+)*$/.test(value.trim());
  return /^\p{L}[\p{L}\s'-]*$/u.test(value.trim());

}



export const validateDigitAndLetters = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  //return /[a-zA-Z0-9-]{3}/.test(value);
  return /^[a-zA-ZáÁéÉíÍóÓúÚüÜ0-9]+(?:\s[a-zA-ZáÁéÉíÍóÓúÚüÜ0-9]+)*$/.test(value.trim());
}

export const validateDigitLettersAndSimbols = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  //return /^[a-zA-ZáÁéÉíÍóÓúÚüÜ0-9,.ºª/]+(?:\s[a-zA-ZáÁéÉíÍóÓúÚüÜ0-9,.ºª/]+)*$/.test(value.trim());
  return /^[\p{L}ñÑçÇ0-9,.ºª?¿!¡%():;&$"·/\s'\-]+$/u.test(value.trim());

}


export const validateInputEmail = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  return /^[a-zA-Z0-9.-/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value);
}

export const validateInputPhone = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  return /^\d+$/.test(value);
}

export const validateDate = (value: string | undefined): boolean => {
  if (value === undefined) return false;

  const date = new Date(value);
  return !isNaN(date.getTime());
}