import {
    BrowserRouter,
    Route,
    Routes, //Navigate
} from "react-router-dom";

//Components
import { Navbar } from "./components/navBar";
import { AlertComponentGeneric } from "./components/alertGeneric";

//Modules
import { RouterUser } from "./zUsers/routes";
import { RouterProject } from "./zProjects/routes";
import { RouterLogs } from "./zLogs/routes";
import { RouterMember } from "./zMembers/routes";
import { RouterAdmin } from "./zAdmin/routes";
import { appVersion } from "./components/version";

//General
import { Home } from './views/Home';

//styles
import GlobalStyle from "./styles/global";
import { PrivateRouter } from "./components/privateRouter";
import { Component404 } from "./views/404";

export const AppRouter = () => {
    const modulesRouter = [
        ...RouterUser(),
        ...RouterProject(),
        ...RouterLogs(),
        ...RouterMember(),
        ...RouterAdmin(),
    ];


    return (
        <>
            <GlobalStyle />
            <BrowserRouter>
                <Navbar />
                {/* Alert Success */}
                <AlertComponentGeneric />

                <Routes>
                    <Route
                        path="/"
                        element={
                            <PrivateRouter>
                                <Home />
                            </PrivateRouter>
                        }
                    ></Route>
                    <Route path="*" element={<Component404 />}></Route>
                    {modulesRouter.map(({ path, element }, index) => {
                        return (
                            <Route
                                path={path}
                                element={
                                    <PrivateRouter>{element}</PrivateRouter>
                                }
                                key={index}
                            />
                        );
                    })}
                </Routes>
            </BrowserRouter>
            <footer style={{ textAlign: "center", fontFamily: "Arial", fontSize: "14px", color: "#888888" }}>
                <p>v{appVersion}</p>
            </footer>
        </>
    );
};
