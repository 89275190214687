import { selectAuth } from "../zUsers/slices/auth";
import { useAppSelector } from "../hooks/redux";
import { Navigate, useLocation, useParams } from "react-router-dom";

export const PrivateRouter = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const isAuth = useAppSelector(selectAuth);
    const { id, email,emailInvited, roleInvited, projectInvited, idRecovery } = useParams();
    const views = [
        "/auth/signup",
        "/auth/signin",
        `/auth/confirm/${id}`,
        "/recovery",
        `/recovery/continue/${email}/${idRecovery}`,
        `/auth/signin/${emailInvited}/${roleInvited}/${projectInvited}`
    ];
    console.log(views.indexOf(location.pathname), location.pathname);

    return !(views.indexOf(location.pathname) > -1) ? (
        isAuth ? (
            children
        ) : (
            <Navigate to="/auth/signin" /> //children
        )
    ) : (
        children
    );
};
