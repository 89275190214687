//react
import React, { useState } from "react";

//styles
import { Container } from "../styles/member";
import { WrapperGeneral } from "../styles/member";
import { Grid } from "@mui/material";
import { DropdownItem } from "styled-dropdown-component";

//translate
import { useTranslation } from "react-i18next";

//apis
import {
    usePutAsingRepresentProjectMutation,
    usePutRemoveStudentMutation,
} from "../api/member";

//interfaces
import { InterfaceDataMember } from "../interfaces/member";
import { InterfaceRoleUser } from "../../interfaces/user";
import { selectUser } from "../../zUsers/slices/auth";

//hooks
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { InterfaceProject } from "../../interfaces/project";
import { Loading } from "../../components/loading";
import { activeAlertAction } from "../../slices/alerts";
import { MenuAnimation, More, MoreNotHover } from "../../styles/nav";
import { useNavigate } from "react-router-dom";

export const MemberList = ({ email, role, idUser }: InterfaceDataMember) => {

    const [t, i18n] = useTranslation("global");
    //apis
    const [putRemoveStudent, { isLoading: isLoadingRemove }] =
        usePutRemoveStudentMutation();

    const [putAsingRP, { isLoading: isLoadingRP }] =
        usePutAsingRepresentProjectMutation();

    const user = useAppSelector(selectUser);

    //project
    const project = user.records[user.records.length - 1];

    const userRole = user.role as InterfaceRoleUser;

    const emailRepresenative =
        project ? "project" in project
            ? typeof project.project != "string"
                ? project.project.representative ? project.project.representative.email: ''
                : ""
            : project.representative ? project.representative.email: '': '';

    const conditionRepresentante =
        emailRepresenative === user.email ? true : false;

    const conditionRoleRepresentante =
        userRole.name === "representative"
            ? conditionRepresentante
                ? true
                : false
            : false;

    const condition = conditionRoleRepresentante || userRole.name === "admin";

    //states
    const [hidden, setHidden] = useState(true);
    //event
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const handleRemoveStudent = async () => {
        if (!(userRole.name === "tutor" || userRole.name === "jury")) {
            const { _id: idProject } = project;
            const idRequestUser = condition ? idUser : user._id;
            const requestRemoveStudent = {
                project: idProject,
                user: idRequestUser,
            };

            try {
                const responseRemoveStudent = await putRemoveStudent(
                    requestRemoveStudent
                ).unwrap();

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t("zMembers.components.membersList.delete-success"),
                    })
                );
                if (!condition) {
                    return navigate("/");
                }
            } catch (error) {
                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zMembers.components.membersList.delete-error"),
                    })
                );
            }
        } else {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zMembers.components.membersList.no-permissions"),
                })
            );
        }
    };

    const handleAsingRP = async () => {
        if (userRole.name === "representative" || userRole.name === "admin") {
            const { _id: idProject } = project;

            const requestAsingRP = {
                id: idProject,
                user: idUser,
            };

            try {
                const responseAsingRP = await putAsingRP(
                    requestAsingRP
                ).unwrap();
                if ( typeof responseAsingRP === 'object'  && responseAsingRP && "response" in  responseAsingRP){
                    dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            isText: t("zMembers.components.membersList.representative-exist"),
                        })
                    );
                } else {
                    dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "success",
                            isText: t("zMembers.components.membersList.assing-representative"),
                        })
                    );
                }
                
            } catch (error) {
                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zMembers.components.membersList.error-representative"),
                    })
                );
            }
        } else {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zMembers.components.membersList.no-permissions"),
                })
            );
        }
    };

    const verifyAR = () => {
        if (conditionRepresentante) {
            if (user.email === email) {
                return <MoreNotHover style={{ color: "white" }} />;
            } else {
                if (role === "Tutor") {
                    return <ComponentTutor />;
                } else {
                    return <ComponentRepresentante />;
                }
            }
        } else {
            if (user.email === email) {
                return <ComponentAlumno />;
            } else {
                return <MoreNotHover style={{ color: "white" }} />;
            }
        }
    };

    const verifyRoleMenu = () => {
        switch (userRole.name) {
            case "representative":
                return verifyAR();
            case "student":
                return verifyAR();
            case "admin":
                if (role === "Representante") {
                    return <MoreNotHover style={{ color: "white" }} />;
                } else if (role === "Tutor") {
                    return <ComponentTutor />;
                } else {
                    return <ComponentRepresentante />;
                }
            case "tutor":
                if (user.email === email) {
                    return <ComponentAlumno />;
                } else {
                    return <MoreNotHover style={{ color: "white" }} />;
                }
            default:
                return <MoreNotHover style={{ color: "white" }} />;
        }
    };

    const ComponentRepresentante = () => {
        return (
            <>
                <More onClick={() => setHidden(!hidden)} />
                <MenuAnimation
                    right={true}
                    hidden={hidden}
                    toggle={() => setHidden(!hidden)}
                >
                    <DropdownItem onClick={() => handleRemoveStudent()}>
                        {t("zMembers.components.membersList.delete")}
                    </DropdownItem>

                    <DropdownItem onClick={() => handleAsingRP()}>
                        {t("zMembers.components.membersList.assing-represent")}
                    </DropdownItem>
                </MenuAnimation>
            </>
        );
    };

    const ComponentAlumno = () => {
        return (
            <>
                <More onClick={() => setHidden(!hidden)} />
                <MenuAnimation
                    right={true}
                    hidden={hidden}
                    toggle={() => setHidden(!hidden)}
                >
                    <DropdownItem onClick={() => handleRemoveStudent()}>
                        {t("zMembers.components.membersList.abandon-project")}
                    </DropdownItem>
                </MenuAnimation>
            </>
        );
    };

    const ComponentTutor = () => {
        return (
            <>
                <More onClick={() => setHidden(!hidden)} />
                <MenuAnimation
                    right={true}
                    hidden={hidden}
                    toggle={() => setHidden(!hidden)}
                >
                    <DropdownItem onClick={() => handleRemoveStudent()}>
                        {t("zMembers.components.membersList.delete")}
                    </DropdownItem>
                </MenuAnimation>
            </>
        );
    };

    return (
        <Grid container>
            <Container width="100%" padding="0 5% 0% 5%">
                <WrapperGeneral style={{ padding: "3%" }}>
                    <Grid item xs={10}>
                        {email}
                    </Grid>
                    {}
                    <Grid item xs={2}>
                        <div>
                            {verifyRoleMenu()}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        {role}
                    </Grid>
                </WrapperGeneral>
                <Loading open={isLoadingRemove || isLoadingRP} />
            </Container>
        </Grid>
    );
};
