//React Module
import { useEffect, useState } from "react";

//views
import { HomeProjectRepresentative } from "../zProjects/views/projectRepresentative";
import { HomeAdmin } from "../zAdmin/views/HomeAdmin";

//hoks
import { useAppSelector } from "../hooks/redux";

//slices
import { selectUser } from "../zUsers/slices/auth";

//interfaces
import { InterfaceDataUser, InterfaceRoleUser } from "../interfaces/user";
import { InterfaceProjectLarge } from "../interfaces/project";

//components
import { NotProject } from "../zProjects/components/notProject";
import { TutorS } from "../zProjects/components/form/tutorS";
import { TableProjectJury } from "../zAdmin/views/projectJury";
import { Tutor } from "../zProjects/components/form/tutor";

//translate
import { useTranslation } from "react-i18next"

//apis
import { useGetUsersIDAwaitMutation } from "../zAdmin/api/users";
import { ProjectEvaluate } from "../zProjects/components/projectEvaluate";
import { Loading } from "../components/loading";
import { AlertComponent } from "../components/alert";
import { useGetRolesQuery } from "../zUsers/api/auth";



export const Home = () => {
    //users 
    const user = useAppSelector(selectUser);

    //states
    const [projectEvaluate, setprojectEvaluate] = useState(false);
    const [notProject, setNotProject] = useState(false);
    const [notProjectTutor, setNotProjectTutor] = useState(false);
    const [nameProject, setNameProject] = useState("");
    const [isErrorModal, setIsErrorModal] = useState(false);

    const [t, i18n] = useTranslation("global")

    const [getUsersIDAwait, { isLoading, data }] = useGetUsersIDAwaitMutation();
    const { isLoading:roles, isError} = useGetRolesQuery()
    
    const consultProjectUser = async () => {
        try {
            const respUser = await getUsersIDAwait({ id: user._id }).unwrap();
            const roleUser = respUser.role as InterfaceRoleUser
            if (roleUser.name != "jury" && roleUser.name != "admin") {
                if (respUser.records.length != 0) {
                    const project = respUser.records[
                        respUser.records.length - 1
                    ] as InterfaceProjectLarge;
                    if (!project.activeInProject){
                        return setNotProject(true)
                    }
                    else if ( new Date(project.project.announcement.endDate) <
                    new Date() || !project.project.announcement.active){
                        return setNotProject(true)
                    }
                    else if (
                        new Date(project.project.announcement.limitDate) <
                        new Date()
                    ) {
                        setNameProject(project.project.name);
                        return setprojectEvaluate(true);
                    } else if (!project.project.announcement.active) {
                        if (
                            roleUser.name in ["representative", "student"]
                        ) {
                            return setNotProject(true);
                        } else {
                            return setNotProjectTutor(true);
                        }
                    }
                } else {
                    if (roleUser.name in ["representative", "student"]) {
                        return setNotProject(true);
                    } else if (roleUser.name === "tutor") {
                        return setNotProjectTutor(true);
                    }
                }
            }
        } catch (error) {
            
        }
    };

    const switchTypeUserHome = (dataUser: InterfaceDataUser) => { 
        const roleUser = dataUser.role as InterfaceRoleUser
        switch ( roleUser.name ) {
            case "admin":
                return <HomeAdmin />;

            case "tutor":
                return <Tutor />;

            case "jury":
                return <TableProjectJury/>

            default:
                return <HomeProjectRepresentative />;
        }
    };

    useEffect(() => {
        consultProjectUser();
    }, []);

    return (
        <>
            {data && (
                <>
                    {projectEvaluate && <ProjectEvaluate name={nameProject} />}
                    {notProject && <NotProject />}
                    {notProjectTutor && <TutorS />}
                    {!projectEvaluate &&
                        !notProject &&
                        !notProjectTutor &&
                        switchTypeUserHome(user)}
                </>
            )}
            <Loading open={isLoading} />
            <AlertComponent
                verify={"error"}
                open={isErrorModal}
                onClose={() => setIsErrorModal(false)}
                text={ t("views.Home.error") }
            />
        </>
    );
};
