import {
    InterfaceEvaluation,
    InterfaceProject,
} from "../../interfaces/project";
import {
    InterfaceDataEvaluated,
    InterfaceEvaluationsResponse,
} from "../interfaces/evaluations";
import { dateComplet } from "../../utils/utils";
import { InterfaceDataUser } from "../../interfaces/user";
import { TFunction } from "react-i18next";

export const traductorEvaluate = (dataProject: InterfaceProject) => {
    let dataPrint: InterfaceEvaluationsResponse = {
        title: "",
        data: [],
        project: dataProject,
    } as InterfaceEvaluationsResponse;
    dataPrint.title = dataProject.name;
    for (let i = 0; dataProject.evaluations.length > i; i++) {
        const a = dataProject.evaluations[i];

        const dataPush: InterfaceDataEvaluated = {
            id: a.author._id,
            email: a.author.email,
            createdAt: dateComplet(a.date),
            score: promediScore(a),
            status: statusEvaluation(a),
            evaluation:a
        };

        dataPrint.data.push(dataPush);
    }
    return dataPrint;
};

export const promediScore = (data: InterfaceEvaluation, sum=false): number => {
    let prom: number = 0;
    const {
        collectionMaterial,
        presentation,
        conclusions,
        procedureReport,
        sources,
    } = data;

    if (collectionMaterial > -1) {
        prom += collectionMaterial;
    }
    if (presentation > -1) {
        prom += presentation;
    }
    if (conclusions > -1) {
        prom += conclusions;
    }
    if (procedureReport > -1) {
        prom += procedureReport;
    }
    if (sources > -1) {
        prom += sources;
    }
    return !sum?parseFloat((prom/5).toFixed(2)): prom
;
};
type TypeStatus = "Pendiente de evaluar" | "Completada";

export const statusEvaluation = (data: InterfaceEvaluation): TypeStatus => {
    let status: TypeStatus = "Pendiente de evaluar"
    const {
        collectionMaterial,
        presentation,
        conclusions,
        procedureReport,
        sources,
    } = data;

    if (
        collectionMaterial > -1 &&
        presentation > -1 &&
        conclusions > -1 &&
        procedureReport > -1 &&
        sources > -1
    ) {
        status = "Completada";
    }
    return status;
};

export const verifyPublish = (
    user: InterfaceDataUser,
    dataProject: InterfaceEvaluationsResponse
): InterfaceEvaluationsResponse | false => {
    let dataPrint: InterfaceEvaluationsResponse = {
        title: dataProject.title,
        data: [],
        project: dataProject.project,
    } as InterfaceEvaluationsResponse;
    const { email } = user;
    

    for (let i = 0; dataProject.project.evaluations.length > i; i++) {
        const a = dataProject.project.evaluations[i];

        if (email === a.author.email) {
            dataPrint.data = [{
                id: a.author._id,
                email: a.author.email,
                createdAt: dateComplet(a.date),
                score: promediScore(a),
                status: statusEvaluation(a),
                evaluation:a
            }];
            return dataPrint;
        }
    }

    return false;
};

export const mediaTotal = (data: InterfaceEvaluation[]) :number => {
    let sum:number = 0
    for (const item of data){
        sum += promediScore(item)
    }
    return sum ?parseFloat((sum/data.length).toFixed(2)):0
} 