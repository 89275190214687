import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { INITIAL_ALERTS } from "../data/data";
import { InterfaceAlertsGeneric } from "../interfaces/interface";

export const { reducer: alertsReducer, actions: alertsActions } = createSlice({
    name: "alerts",
    initialState: INITIAL_ALERTS,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        clear: (state) => {
            return {
                ...state,
                isActive: false,
                isMode: "info",
                isText: "",
            };
        },
        active: (state, action: PayloadAction<InterfaceAlertsGeneric>) => {
            const { isMode, isText } = action.payload;

            return {
                ...state,
                isActive: true,
                isMode,
                isText,
            };
        },
    },
});

export const selectAlert = (state: RootState) => state.alerts;

export const { clear: clearAlertAction, active: activeAlertAction } =
    alertsActions;
