//React Modules
import { useEffect, useState } from "react";

//translate
import { useTranslation } from "react-i18next"

//styles
import {
    Container,
    ContainerGeneral,
    DoubleContainer,
    TitleWrapper,
    TitleWrapperGeneral,
    Wrapper,
} from "../../../styles/globalComponents";

//components
import { ProjectTutorComponent } from "../projectTutorComponent";
import { Loading } from "../../../components/loading";
import { AlertComponent } from "../../../components/alert";

//hoks
import { useAppSelector } from "../../../hooks/redux";

//slices
import { selectUser } from "../../../zUsers/slices/auth";

//apis
import { useGetUsersIDQuery } from "../../../zAdmin/api/users";
import { InterfaceProjectLarge } from "../../../interfaces/project";
import Typography from "@material-ui/core/Typography";


export const Tutor = () => {
    const [t, i18n] = useTranslation("global")
    //users
    const user = useAppSelector(selectUser);

    //apis
    const { data, isLoading, isError } = useGetUsersIDQuery({ id: user._id });

    //state
    const [isErrorAlert, setIsErrorAlert] = useState(false);

    //useEffect
    useEffect(() => {
        if (isError) {
            setIsErrorAlert(true);
        }
    }, [isError]);

    return (
        <Container width="50%" heigth="80%">
            <Wrapper>
                <DoubleContainer
                    gridTemplateColumns="auto"
                    display="grid"
                    width="100%"
                >
                    <TitleWrapper> {t("zProjects.components.form.tutor.projects")} </TitleWrapper>
                </DoubleContainer>
                <ContainerGeneral width="90%;" heigth="90%">
                    <TitleWrapperGeneral padding="0">
                        {user.firstName}, {t("zProjects.components.form.tutor.view-projects")}
                    </TitleWrapperGeneral>
                    <Container>
                        
                        <DoubleContainer
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                        >
                            {data &&
                                data.records.map((items) => {
                                    const records =
                                        items as InterfaceProjectLarge;
                                    let state: string;
                                    let email:string = ''
                                    const {
                                        name,
                                        workFile,
                                        workReport,
                                        tutorReport,
                                        announcement: { endDate },
                                        _id,
                                        tutors,
                                    } = records.project;

                                    if (records.project.representative){
                                        email = records.project.representative.email
                                    }

                                    if (workFile && workReport) {
                                        if (tutors) {
                                            if (tutorReport) {
                                                state = t("zProjects.components.form.tutor.all-suplied");
                                            } else {
                                                state =
                                                t("zProjects.components.form.tutor.lack-files");
                                            }
                                        } else {
                                            state = t("zProjects.components.form.tutor.all-suplied");
                                        }
                                    } else {
                                        state = t("zProjects.components.form.tutor.lack-files");
                                    }

                                    if (new Date(endDate) < new Date()) {
                                        state = t("zProjects.components.form.tutor.time-out");
                                    }

                                    return (
                                        
                                        <ProjectTutorComponent
                                            name={name}
                                            email={email}
                                            state={state}
                                            id={_id}
                                        />
                                    );
                                })}
                        </DoubleContainer>
                    </Container>
                </ContainerGeneral>
            </Wrapper>
            <Loading open={isLoading} />
            <AlertComponent
                verify={t("zProjects.components.form.tutor.error")}
                open={isErrorAlert}
                onClose={() => setIsErrorAlert(false)}
                text={t("zProjects.components.form.tutor.error-o")}
            />
        </Container>
    );
};
