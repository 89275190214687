
//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'
import { FormRegisterNP } from '../components/form/registerNP';

export const RegisterNP = () => {

   

  return (
    <>
        <Mobile>
            <FormRegisterNP width='80%;'/>
        </Mobile>
        <Tablet>
            <FormRegisterNP width='65%;'/>  
        </Tablet>
        <Desktop>
            <FormRegisterNP width='50%;'/>
        </Desktop>
    </>
  )
}
