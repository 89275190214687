import { TFunction } from "react-i18next";
import { InterfaceRoleUser, TypeRoleUser } from "../../interfaces/user";
import { rolesViews } from "../../utils/utils";
import { InterfaceRadioComponen } from "../interfaces/register";

export const getIDRol = (data: InterfaceRoleUser[], key: TypeRoleUser) => {
    for (const item of data) {
        if (item["name"] === key) {
            return item["_id"];
        }
    }
    return "";
};

export const getNameRol = (data: InterfaceRoleUser[], key: string) => {
    for (const item of data) {
        if (item["_id"] === key) {
            return item["name"];
        }
    }
    return "";
};

export const getRadioRoles = (data: InterfaceRoleUser[],t: TFunction<"global", undefined>) => {
    const dataResponse: InterfaceRadioComponen[] = [];
    for (const item of data) {
        if (item["name"] === "representative" || item["name"] === "tutor") {
            dataResponse.push({
                name: rolesViews(item,t),
                value: item["_id"],
                disabled: false,
            });
        }
    }
    return dataResponse
};
