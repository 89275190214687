//imports modules
import styled, { keyframes } from 'styled-components';
import { Link } from "react-router-dom";
import { DropdownMenu } from 'styled-dropdown-component';
  

//Style General
import { colors, fonts } from './Info'


//icons
import { FaUserAlt } from 'react-icons/fa';
import { FiMoreVertical } from "react-icons/fi";
import { AiOutlineCheckCircle } from 'react-icons/ai'

//interfaces
import { InterfaceStyle } from '../interfaces/interface'

export const NavbarContainer = styled.nav`
    color: ${colors.background.dark};
    font-family: ${fonts.heading};
    display: flex;
    flex-direction: column;
    border-bottom:3px solid ${colors.background.dark};
    align-items: center;
    justify-content: space-between;
`;


export const LeftContainer = styled.div`
    flex: 70%;
    display: flex;
    align-items: center;
    padding-left: 4%;
`;

export const RightContainer = styled.div`
    flex: 30%;
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
`;

export const NavbarInnerContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
`;

export const NavbarLinkContainer = styled.div`
    display: flex;    
`;

export const NavbarLink = styled(Link)<InterfaceStyle>`
    color: ${(props) => props.color || colors.textIcons.dark};
    text-decoration: ${(props) => props.textDecoration || 'underline'};
    margin: ${(props) => props.margin || '10px'};
    padding-left: ${(props) => props.paddingLeft || '0%'};
   
`;

export const NavbarLinkExtended = styled(Link)`
    color: white;
    font-size: x-large;
    font-family: ${fonts.body};
    margin: 10px;
`;

//icons
export const Account = styled(FaUserAlt)`
    align-items: center;
    height: auto;
    font-size: x-large;
    font-family: ${fonts.body};
    text-decoration: none;
    &:hover {
        color: ${colors.background.darkGrey};
      }
`
export const More = styled(FiMoreVertical)<InterfaceStyle>`
    align-items: center;
    height: auto;
    font-size: x-large;
    margin: ${(props) => props.margin || '10px'};;
    &:hover {
        color: ${colors.background.darkGrey};
      }
`
export const MoreNotHover = styled(FiMoreVertical)<InterfaceStyle>`
    align-items: center;
    height: auto;
    font-size: x-large;
    margin: ${(props) => props.margin || '10px'};
`

export const CheckEmail = styled(AiOutlineCheckCircle)`
    align-items: center;
    height: auto;
    font-size: x-large;
    margin: 10px;
    color: ${(props:InterfaceStyle) => props.color ? 'green': 'red'}
`

//animations
export const frames =  keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;



export const MenuAnimation = styled(DropdownMenu)`
    animation: ${frames} 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-direction: alternate;
`;
export const ImgComponent = styled.img`
    width: 110px;
    height: 40px;
  
`;
