//utils
import { Mobile, Desktop, Tablet } from "../../utils/responsive";

//components
import { TableConvocationsForm } from "../components/form/tableConvocations";
import { useEffect } from 'react';
//data
import {
    dataColumnsConvocations,
} from "../data/convocations";

//translate
import { useTranslation } from "react-i18next"

export const TableConvocations = () => {

    const [t, i18n] = useTranslation("global")

    return (
        <>
            <Mobile>
                <TableConvocationsForm
                    colum={dataColumnsConvocations(t)}
                    title={t("zAdmin.views.convocations.titleConv")} props={{}}                
                />
            </Mobile>
            <Tablet>
                <TableConvocationsForm
                    colum={dataColumnsConvocations(t)}
                    title={t("zAdmin.views.convocations.titleConv")} props={{}}                
                />
            </Tablet>
            <Desktop>
                <TableConvocationsForm
                    colum={dataColumnsConvocations(t)}
                    props={{}}
                    title={t("zAdmin.views.convocations.titleConv")}
                />
            </Desktop>
        </>
    );
};
