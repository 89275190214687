import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TextField,
} from "@mui/material";

// Interfaces
import { InterfaceTableBase, InterfaceTableContentFilter, InterfaceTableForm } from "../../interfaces/table";
import { InterfaceProject } from "../../../interfaces/project";

//styles

import { ReturnIco } from "../../../styles/globalComponents";
import { NavbarLink } from "../../../styles/nav";

import {
    ButtonComponent,
    DoubleContainer,
    TitleWrapper,
} from "../../../styles/globalComponents";
import { WrapperInput } from "../../../zUsers/styles/Input";

//Components
import { AutocompleteComponent } from "../../../components/autocomplete";
import { TablePaginationGeneral } from "../paginate";
import { Loading } from "../../../components/loading";
import { ButtonAsignations } from "../buttom/asignations";

//hooks
import { useAppDispatch } from "../../../hooks/redux";
import { useForm } from "../../../hooks/form";

//api
import { useGetProjectsAsignationsQuery, useDeleteRemoveUserProjectMutation } from "../../api/project";

//utils
import { dateYears, filesProjectState } from "../../../utils/utils";
import { InterfaceTableContentAsignations } from "../../interfaces/asignations";
import { useGetUsersIDQuery } from "../../api/users";
import { activeAlertAction } from "../../../slices/alerts";

//translate
import { useTranslation } from "react-i18next";
import { filterDataUsers } from "../../utils/users";
import { filterDataAsignations } from "../../utils/asignations";

export const TableAsignationsForm = memo(
    ({ title, colum, select, button, props }: InterfaceTableBase): JSX.Element => {
        const { idUserAsignations } = useParams();
        const [t] = useTranslation("global");
        const [filter1, setFilter1] = useState('');
        const [filter2, setFilter2] = useState('');

        //form hooks
        const {
            handleChange,
            page,
            rowsPerPage,
            search,
            count,
            data
        } = useForm<InterfaceTableForm<InterfaceProject>>({
            count: 0,
            page: 0,
            rowsPerPage: 25,
            search: "",
            filter1: "",
            filter2: "",
            data: [],
        });

        const {
            isLoading,
            currentData: rows,
            isFetching,
        } = useGetProjectsAsignationsQuery({
            skip: rowsPerPage * page,
            limit: rowsPerPage,
            id: idUserAsignations as string,
        });

        const { isLoading: isLoadingUser, currentData } = useGetUsersIDQuery({
            id: idUserAsignations as string,
        });


        //console.log(rows.result);
        console.log(rows)
        console.log(currentData)

        useEffect(() => {
            if (rows && rows.count !== count) {
                handleChange({ field: "count", value: rows.count });
            }
            if (rows && rows.result !== data) {
                handleChange({ field: "data", value: rows.result });
            }
        }, [rows, count, data, handleChange]);

        //filter data of select
        const filterDataSelect = (data: InterfaceProject[], key: keyof InterfaceProject) => {
            const dataReturn: Array<string> = [];
            for (const items of data) {
                if (key === "jurors") {
                    if (items.jurors) {
                        for (const itemsRecords of items.jurors) {
                            if (!dataReturn.includes(itemsRecords.firstName)) {
                                dataReturn.push(itemsRecords.firstName);
                            }
                        }
                    }
                } else if (key === "announcement") {
                    if (items.announcement &&
                        !dataReturn.includes(
                            items.announcement.name +
                            " " +
                            dateYears(items.announcement.initialDate)
                        )
                    ) {
                        dataReturn.push(
                            items.announcement.name +
                            " " +
                            dateYears(items.announcement.initialDate)
                        );
                    }
                }
            }
            return dataReturn;
        };

        // Change generic select filter camp
        const changeSelectGeneric = (
            value: string,
            index: number,
            key: string
        ) => {
            if (select && select[index]) {
                const name = select[index].name as keyof InterfaceProject;
                if (key === 'filter1') {
                    setFilter1(value)
                } else if (key === 'filter2') {
                    setFilter2(value)
                }
                !value ? requestSearch("") : requestSearch(value, name);
            }
        };

        //handleSelect1 changes
        const handleChangeSelect1 = (data: string) => changeSelectGeneric(data, 0, "filter1");

        //handleSelect2 changes
        const handleChangeSelect2 = (data: string) => changeSelectGeneric(data, 1, "filter2");

        //actions
        const handleAndSet = [
            {
                handle: handleChangeSelect1,
                data: filter1,
            },
            {
                handle: handleChangeSelect2,
                data: filter2,
            },
        ];

        //filter search all keys
        const requestSearch = (
            searchedVal: string,
            key?: keyof InterfaceProject
        ) => {
            const filteredRows = rows
                ? rows.result.filter((row) => {
                    if (key) {
                        if (key === "announcement") {
                            const dateAndName =
                                row.announcement.name +
                                " " +
                                dateYears(row.announcement.initialDate);
                            return dateAndName
                                .toLowerCase()
                                .includes(searchedVal.toLowerCase());
                        }
                    } else {
                        for (const [key, value] of Object.entries(
                            filterDataAsignations(row, t)
                        )) {
                            const valueString = value as string;
                            if (valueString
                                .toLowerCase()
                                .includes(searchedVal.toLowerCase())) {
                                return true;
                            } else {
                                continue;
                            }
                        }
                        return false;
                    }
                })
                : [];
            handleChange({ field: "data", value: filteredRows });
        };

        const cancelSearch = () => {
            handleChange({ field: "search", value: "" });
            requestSearch(search);
        };

        return (
            <>
                <TableContainer
                    sx={{
                        height: "90%",
                        width: "90%",
                        margin: "auto",
                        marginTop: "2%",
                        padding: "2%",
                        maxHeight: props.maxHeight
                    }}
                >
                    {/* Title + reverse */}
                    <DoubleContainer
                        gridTemplateColumns="auto"
                        display="grid"
                        width="100%"
                    >
                        <NavbarLink paddingLeft="0" to="#" onClick={() => window.history.back()}>
                            {" "}
                            <ReturnIco />
                            {t("zAdmin.components.form.tableAsignations.back")}{" "}
                        </NavbarLink>
                        <TitleWrapper padding="0 0 2% 0">
                            {`${title} ${currentData
                                ? currentData.firstName +
                                " " +
                                currentData.lastName +
                                " " +
                                currentData.email
                                : ""
                                } `}
                        </TitleWrapper>
                    </DoubleContainer>

                    {/* Filters */}
                    <DoubleContainer alingItems="baseline">
                        {/* Search  */}
                        <DoubleContainer>
                            <WrapperInput heigth={"100"} width="100%">
                                <TextField
                                    placeholder={""}
                                    size={"small"}
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        if (value === "") {
                                            cancelSearch();
                                        } else {
                                            requestSearch(value);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </WrapperInput>
                        </DoubleContainer>

                        {/* Select filter  */}
                        <DoubleContainer>
                            {select &&
                                select.map((items, index) => {
                                    return (
                                        <AutocompleteComponent
                                            placeholder={
                                                items.placeholder as string
                                            }
                                            data={filterDataSelect(
                                                rows ? rows.result : [],
                                                items.name as unknown as keyof InterfaceProject
                                            )}
                                            handle={
                                                handleAndSet[index].handle
                                            }
                                            valueDefault={
                                                handleAndSet[index]
                                                    .data as string
                                            }
                                            key={`select-${index}`}
                                        />
                                    );
                                })}
                        </DoubleContainer>
                    </DoubleContainer>

                    {/* Button */}
                    <ButtonAsignations idUser={idUserAsignations as string} />

                    {/* Content Tabla */}
                    {isLoading || isFetching ? (
                        <Loading open={true} />
                    ) : (
                        <TableContent colum={colum} rows={data} />
                    )}
                    {/* Paginate */}
                    <TablePaginationGeneral
                        page={count <= 0 ? 0 : page || 0} // Manejo de valores nulos o indefinidos
                        rowsPerPage={rowsPerPage}
                        onChange={handleChange}
                        count={count || 0} // Proporcionar valor por defecto
                    />
                </TableContainer>
            </>
        );
    }
);

const TableContent = memo(
    ({ colum, rows }: InterfaceTableContentFilter<InterfaceProject>) => {
        const navigate = useNavigate();
        const dispatch = useAppDispatch();
        const { idUserAsignations } = useParams();
        const [t, i18n] = useTranslation("global");

        const [putRemoveUser, { isLoading: isLoadingRS }] =
            useDeleteRemoveUserProjectMutation();

        const handleRemoveUser = async (row: InterfaceProject) => {
            if (row.announcement && row.announcement.active) {
                try {
                    const request = {
                        user: idUserAsignations as string,
                        project: row._id,
                    };
                    await putRemoveUser(request).unwrap();

                    dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "success",
                            isText: t("zAdmin.components.form.tableAsignations.user-design"),
                        })
                    );
                } catch (error) {
                    dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            isText: t("zAdmin.components.form.tableAsignation.error-design"),
                        })
                    );
                }
            }
        };

        return (
            <>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {colum.map((items) => (
                                <TableCell
                                    key={items.id}
                                    align={items.align}
                                    style={{
                                        minWidth: items.minWidth,
                                        fontWeight: items.fontWeight,
                                    }}
                                >
                                    {items.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.length > 0 ? (
                            rows.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            textDecorationLine: "underline",
                                        }}
                                        align="center"
                                        onClick={() =>
                                            navigate(`/project-admin/${row._id}`)
                                        }
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="center">
                                        {row.tutors && row.tutors.length > 0
                                            ? row.tutors[row.tutors.length - 1].email
                                            : t("zAdmin.components.form.tableAsignations.w-tutor")}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="center">
                                        {row.announcement
                                            ? `${dateYears(
                                                row.announcement.initialDate
                                            )}-${row.announcement.name}`
                                            : t("zAdmin.components.form.tableAsignations.w-convo")}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="center">
                                        {filesProjectState(row, t)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            textDecorationLine: "underline",
                                        }}
                                        align="center"
                                        onClick={() =>
                                            navigate(`/evaluations/${row._id}`)
                                        }
                                    >
                                        {t("zAdmin.components.form.tableAsignations.see-eva")}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            color: row.announcement && row.announcement.active ? "black" : "gray",
                                            textDecorationLine: "underline",
                                        }}
                                        align="center"
                                        onClick={() => handleRemoveUser(row)}
                                    >
                                        {t("zAdmin.components.form.tableAsignations.desing")}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colum.length} align="center">
                                    {t("zAdmin.components.form.tableAsignations.no-data")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Loading open={isLoadingRS} />
            </>
        );
    }
);

