import { TFunction } from "react-i18next";
import { 

    InterfaceErrorsInputRecovery2, 
    InterfaceRecovery2Array

} from "../interfaces/recovery2";


export const dataRecovery2 = (t: TFunction<"global", undefined>,
        errors: InterfaceErrorsInputRecovery2
    ): InterfaceRecovery2Array => [

    
    {
        registerLabel: 'password',
        errors: errors.password,
        label: t("zUsers.data.recovery2.password"),
        subLabel: t("zUsers.data.recovery2.password-sL"),
        type: 'password',
        placeholder: t("zUsers.data.recovery2.holder-password"),
    },
    {
        registerLabel: 'confirmPassword',
        errors: errors.confirmPassword,
        label: t("zUsers.data.recovery2.check-password"),
        type: 'password',
        placeholder: t("zUsers.data.recovery2.holder-check"),
    },
    

]