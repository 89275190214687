import styled  from 'styled-components'
import { Link as RouteLink } from "react-router-dom";

//styles
import { colors } from '../../styles/Info'



export const ButtonRegister = styled.button`
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.background.dark};
    cursor: pointer;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    outline: none;
   
    text-transform: uppercase;

    &:hover { 
        box-shadow: 0 4px 15px 0 rgba(109, 109, 110, 0.75);
    }

`

export const LostPassword = styled(RouteLink)`
    color: black;
`
