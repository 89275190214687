//interfaces
import { interfaceArchiveArray } from "../interfaces/project";

//styles
//--icons
import { MoreIcon,CheckIcon, FileUploadIcon, InfoIcon } from "../styles/project";
import { InterfaceProject } from "../../interfaces/project";
import { dateComplet, dateDay, pathFile } from "../../utils/utils";
import { TFunction } from "react-i18next";

export const dataAlumno = (data: InterfaceProject, t: TFunction<"global", undefined>): interfaceArchiveArray => [
    {
        props: { width: "auto", textDecoration: "underline" },
        ComponentIcon: data.workFile ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: data.workFile ? MoreIcon : InfoIcon,
            more: data.workFile ? true : false,
            menuItems: data.workFile
                ? [
                      {
                          text: t("zProjects.data.alumno.rename"),
                          action: "update",
                      }
                  ]
                : [],
            info: data.workFile
                ? {}
                : {
                      content: [
                          t("zProjects.data.alumno.info"),
                      ],
                      title: t("zProjects.data.alumno.info-title"),
                  },
        },
        text1: data.workFile ? pathFile(data.workFile.key) : t("zProjects.data.alumno.report-work"),
        text2: data.workFile
            ? t("zProjects.data.admin.upload") + dateComplet(data.workFile.date) + (data.tutors && data.tutors.length > 0 && data.tutors[data.tutors.length - 1].firstName ? t("zProjects.data.representative.by") + data.tutors[data.tutors.length - 1].firstName : '')
            : t("zProjects.data.admin.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.admin.days"),
        text3: data.workFile ? undefined : t("zProjects.data.admin.upload-file"),
        typeFile: 'work',
        data: data.workFile
    },
    {
        props: { width: "auto", textDecoration: "underline" },
        ComponentIcon: data.workReport ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: data.workReport ? MoreIcon : InfoIcon,
            more: data.workReport ? true : false,
            menuItems: data.workReport
                ? [
                      {
                          text: t("zProjects.data.alumno.rename"),
                          action: "update",
                      }
                  ]
                : [],
            info: data.workReport
                ? {}
                : {
                      content: [
                          t("zProjects.data.alumno.iframe"),
                      ],
                      title: t("zProjects.data.alumno.iframe-title"),
                  },
        },
        text1: data.workReport ? pathFile(data.workReport.key) : t("zProjects.data.alumno.report-e-work"),
        text2: data.workReport
            ? t("zProjects.data.admin.upload") + dateComplet(data.workReport.date) + (data.tutors && data.tutors.length > 0 && data.tutors[data.tutors.length - 1].firstName ? t("zProjects.data.representative.by") + data.tutors[data.tutors.length - 1].firstName : '')
            : t("zProjects.data.admin.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.admin.days"),
        text3: data.workReport ? undefined : t("zProjects.data.admin.upload-file"),
        typeFile: 'report',
        data: data.workReport
    },
    {
        props: { width: "auto" },
        ComponentIcon: data.tutorReport ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: InfoIcon,
            menuItems: [],
            info: {
                content: [
                    t("zProjects.data.alumno.info-tutor-content"),
                ],
                title: t("zProjects.data.alumno.info-title-tutor"),
            },
        },
        text1: data.tutorReport ? pathFile(data.tutorReport.key) : t("zProjects.data.alumno.report-tutor"),
        text2: data.tutorReport
            ? t("zProjects.data.admin.upload") + dateComplet(data.tutorReport.date) + (data.tutors && data.tutors.length > 0 && data.tutors[data.tutors.length - 1].firstName ? t("zProjects.data.representative.by") + data.tutors[data.tutors.length - 1].firstName : '')
            : t("zProjects.data.admin.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.admin.days"),
        text3: data.tutorReport ? undefined : t("zProjects.data.admin.file-tutor"),
        typeFile: 'tutor',
        data: data.tutorReport
    }
];
