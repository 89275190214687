import { InterfaceMenuBase, } from '../interfaces/table';
//interfaces
import { InterfaceColumnConvocations, InterfaceConvocationsTableBase, InterfacePropsDataMenuConvocations } from "../interfaces/convocations";
import { TFunction } from 'react-i18next';



export const dataColumnsConvocations=(t: TFunction<"global", undefined>): InterfaceColumnConvocations[] => [
  
    {
        id: "name",
        label: t("zAdmin.data.convocations.name"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "initialDate",
        label: t("zAdmin.data.convocations.initial-date"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "limitDate",
        label: t("zAdmin.data.convocations.limit-date"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toFixed(2),
        fontWeight: "bold",
    },
    {
        id: "endDate",
        label: t("zAdmin.data.convocations.close-date"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    // {
    //     id: "createdAt",
    //     label: t("zAdmin.data.convocations.project-create"),
    //     minWidth: 100,
    //     align: "center",
    //     format: (value: number) => value.toLocaleString("en-US"),
    //     fontWeight: "bold",
    // },
    {
        id: "active",
        label: t("zAdmin.data.convocations.state"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "options",
        label: t("zAdmin.data.convocations.options"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
];

export const dataMenuConvocations = (t: TFunction<"global", undefined>,{
    data,
    active,
    editConvocations
   }: InterfacePropsDataMenuConvocations):InterfaceMenuBase[] => [
    {
        text:data.active? t('zAdmin.data.convocations.inactive'): t('zAdmin.data.convocations.active'),
        action:'patch',
        onClick: active
       
    },
    {
        text:t('zAdmin.data.convocations.edit'),
        action:'patch',
        onClick: editConvocations
      
    }
 ]


 export const  INIT_DATA_CONVOCATIONS = {
    name: "",
    initialDate: "",
    limitDate: "",
    endDate: ""
}

export const INIT_DATA_TABLE_CONVOCATIONS:InterfaceConvocationsTableBase[] = [
    
]

