import moment from 'moment'
import { InterfaceProject } from '../interfaces/project';
import { InterfaceRoleUser } from '../interfaces/user';
import { TFunction } from "react-i18next";

/* export const pathFile = (filePath: string): string => {
    if (filePath) {
        filePath.split("/").pop() || "";
    }
    return "";
}
*/

export const pathFile = (filePath: string): string =>
    filePath.split("/").pop() || "";

export const dateComplet = (date: string): string => date.split("T")[0];

export const dateDay = (date: string): string => {
    const nowDate = moment(new Date());
    const limitDate = moment(date)
    return limitDate.diff(nowDate, 'days').toString()
}

export const dateYears = (date: string): string => {
    return moment(date).year().toString()
}

export const rolesViews = (role: InterfaceRoleUser, t: TFunction<"global", undefined>): string => {

    switch (role.name) {
        case 'jury':
            return t("utils.utils.jury")
        case 'representative':
            return t("utils.utils.representative")
        case 'student':
            return t("utils.utils.student")
        case 'tutor':
            return t("utils.utils.tutor")
        case 'admin':
            return t("utils.utils.admin")
        default:
            return t("utils.utils.w-o-rol")
    }
}

export const filesProjectState = (projectData: InterfaceProject, t: TFunction<"global", undefined>) => {
    let state: string = "";
    const {
        workFile,
        workReport,
        tutorReport,
        // announcement: { endDate },
        tutors,
    } = projectData;

    if (workFile && workReport) {
        if (tutors) {
            if (tutorReport) {
                state = t("utils.utils.delivered");
            } else {
                state = t("utils.utils.w-o-files");
            }
        } else {
            state = t("utils.utils.w-o-files");
        }
    } else {
        state = t("utils.utils.w-o-files");
    }

    // if (new Date(endDate) < new Date()) {
    //     state = "Fuera de plazo ";
    // }
    return state;
};
