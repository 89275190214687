//React Module
import { forwardRef } from "react";
import { Snackbar, styled } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

//interfaces
import { InterfaceAlertComponent } from "../interfaces/interface";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return (           
        <MuiAlert  elevation={6} ref={ref} variant="filled" {...props} />           
)
});

const AlertA = styled(Alert)`
    & .MuiPaper-root {
        background-color:red;
    }

`


export const AlertComponent = ({
    open,
    onClose,
    text,
    verify = "success",
}: InterfaceAlertComponent) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
                <AlertA onClose={onClose} severity={verify} sx={{ width: "100%" }}>
                    {text}
                </AlertA>


        </Snackbar>
    );
};


