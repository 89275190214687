import { TFunction} from "react-i18next";
import { MoreIcon,CheckIcon, FileUploadIcon, InfoIcon } from "../styles/project";

export const baseFileSuplementary = (t: TFunction<"global", undefined>) => {
    return {
         props: { width:'auto',textDecoration: "underline" } ,
    ComponentIcon: FileUploadIcon,
    ComponentAction: {
        element: InfoIcon,
        menuItems: [],
        info: {
            content: [
                t("zProjects.data.project.iframe")
            ],
            title: t("zProjects.data.project.iframe-title")
        }
    },
    text1: t("zProjects.data.project.text1"),
    text2: t("zProjects.data.project.text2"),
    text3: t("zProjects.data.project.text3"),
}
   
}

export const baseFileCheck= (t: TFunction<"global", undefined>) => {
    return{
    props: { width: 'auto' },
    ComponentIcon: CheckIcon,
    ComponentAction: {
        element: MoreIcon,
        more: true,
        info: {},
        menuItems: [
            {
                text: t("zProjects.data.project.rename"),
                action: "update",
            },
            {
                text: t("zProjects.data.project.delete"),
                action: "Delete",
            },
        ]
    },
    text1: "",
    text2: ""
}}

export const alumnoComponentAction = (t: TFunction<"global", undefined>) => {
    return{
    element: MoreIcon,
    more: true,
    info: {},
    menuItems: [
        {
            text: t("zProjects.data.project.rename"),
            action: "update",
        },
        {
            text: t("zProjects.data.project.delete"),
            action: "Delete",
        },
    ]
}
}

export const baseFileInfo = (t: TFunction<"global", undefined>) => {
    return{
    props: { width: 'auto' },
    ComponentIcon: CheckIcon,
    ComponentAction: {
        element: InfoIcon,
        menuItems: [],
        info: {
            content: [
                t("zProjects.data.project.iframe")
            ],
            title:  t("zProjects.data.project.iframe-title")
        }
    },
    text1: "",
    text2: ""
}}