import { Desktop, Mobile, Tablet } from "../../utils/responsive";
import { Score } from "../components/form/score";

export const ScoreV = () => {
    return (
        <>
            <Mobile>
                <Score props={{ width: "80%;", heigth: "auto" }} />
            </Mobile>
            <Tablet>
                <Score props={{ width: "65%;", heigth: "auto" }} />
            </Tablet>
            <Desktop>
                <Score props={{ width: "50%;", heigth: "auto" }} />
            </Desktop>
        </>
    );
};
