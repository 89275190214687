import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { INITIAL_ROLES} from "../../data/data";
import { InterfaceRoleUser } from '../../interfaces/user';

export const { reducer: roleReducer, actions: roleActions } = createSlice({
    name: "roles",
    initialState: INITIAL_ROLES,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
       
        saveRole: (state, action: PayloadAction<InterfaceRoleUser[]>) => {
            const { payload } = action
            return [
                ...payload
            ]
        }
    },
});

export const selectRole = (state: RootState) => state.role;


export const {
    saveRole: saveRoleAction
} = roleActions;
