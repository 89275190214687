import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import {
    validateInputEmail,
} from '../../utils/validatesInput';

export const schemaRecovery = (t: TFunction<"global", undefined>) => Yup.object().shape(
    {
        
        email: Yup.string()
        .email(t("zUsers.validators.schemaRecovery.email-inv"))
            .required(t("zUsers.validators.schemaRecovery.email-req"))
            .test(t("zUsers.validators.schemaRecovery.email"), t("zUsers.validators.schemaRecovery.invalid"), (value: string | undefined) =>
                validateInputEmail(value)
            ),
    
    },
);
