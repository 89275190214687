//modules react
import { useState } from "react";
import { Dropdown, DropdownItem } from "styled-dropdown-component";
import {
    DialogContent,
    DialogActions,
    TextField,
    DialogTitle,
    DialogContentText,
} from "@mui/material";
import { DropzoneDialog } from "material-ui-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

//style
import { ButtonComponent, Container } from "../../styles/globalComponents";
import { MenuAnimation } from "../../styles/nav";
import { LabelImg } from "../styles/project";
import {
    LabelInput,
    WrapperInput,
    SubLabelInput,
} from "../../zUsers/styles/Input";
import {
    DoubleContainer,
    TripleContainer,
    SubTitleWrapperGeneral,
    TitleWrapperGeneral,
    WrapperGeneral,
} from "../../styles/globalComponents";

//interfaces
import { interfaceArchive } from "../interfaces/project";
import { InterfaceFile } from "../../interfaces/file";
import { InterfaceProject } from "../../interfaces/project";

//components
import { BasicModal } from "../../components/basicModal";
import { ModalAlert } from "../../components/modalAlert";
import { ModalGeneric } from "../../components/modalGeneric";
import { Loading } from "../../components/loading";
import { AlertComponent } from "../../components/alert";

//apis
import {
    usePutChangeFileNameMutation,
    usePutRemoveFileNameMutation,
    usePutSuplementaryFileMutation,
    usePutUploadTutorReportMutation,
    usePutUploadWorkFileMutation,
    usePutUploadWorkReportMutation,
} from "../api/project";

//hooks
import { useAppSelector } from "../../hooks/redux";

//slices
import { selectUser } from "../../zUsers/slices/auth";

//validators
import { schemaArchive } from "../validators/schemaArchive";
import { InterfaceRoleUser } from '../../interfaces/user';

//translate
import { useTranslation } from "react-i18next"

export const Archivo = ({
    props,
    ComponentIcon,
    ComponentAction,
    text1,
    text2,
    text3,
    typeFile,
    data,
}: interfaceArchive) => {
    const user = useAppSelector(selectUser);
    //project
    const project = user.records[user.records.length - 1] as InterfaceProject;

    const [t, i18n] = useTranslation("global")

    //apis
    const [putChangeFile, { isLoading: isLoadingRename }] =
        usePutChangeFileNameMutation();

    const [putRemoveFile, { isLoading: isLoadingRemove }] =
        usePutRemoveFileNameMutation();

    const [putTutorReport, { isLoading: isLoadingTutorReport }] =
        usePutUploadTutorReportMutation();

    const [putWorkReport, { isLoading: isLoadingWorkReport }] =
        usePutUploadWorkReportMutation();

    const [putWorkFile, { isLoading: isLoadingWorkFile }] =
        usePutUploadWorkFileMutation();

    const [putSuplementary, { isLoading: isLoadingSuplementary }] =
        usePutSuplementaryFileMutation();

    //STATES
    const [hidden, setHidden] = useState(true);
    const [openForm, setOpenModalForm] = useState(false);
    const [createFile, setCreateFile] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openInfoRemoveFile, setOpenInfoRemoveFile] = useState(false);
    const [openRemove, setOpenModalRemove] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [isSuccessModal, setIsSuccessModal] = useState(false);

    const [sucessText, setSucessText] = useState("");

    //forms validate
    const { handleSubmit, control } = useForm<{ nameFile: string }>({
        defaultValues: { nameFile: "" },
        resolver: yupResolver(schemaArchive(t)),
    });

    //Handles

    const handleRenameFile = async (dataNameFile: { nameFile: string }) => {
        const { key } = data as InterfaceFile;
        const { _id } = project;

        const rename = key.split("/");
        const nameOld = rename.pop() as string;
        const newRename = `${dataNameFile.nameFile.replace(' ', '')}`

        const requestRename = {
            newName: newRename,
            oldKey: key,
            proyect: _id,
        };
        try {
            const responseRenameFile = await putChangeFile(
                requestRename
            ).unwrap();
            //console.log(responseRenameFile);
            if ("project" in responseRenameFile) {
                setSucessText(t("zProjects.components.archivo.correct-rename"));
                setIsSuccessModal(true);
            } else {
                setIsErrorModal(true);
            }
        } catch (error) {
            setIsErrorModal(true);
        }

        setOpenModalForm(false);
    };
    const handleRemoveFile = async () => {
        const userRole = user.role as InterfaceRoleUser;

        if (!userRole || !userRole.name) {
            console.error("Invalid user role");
            setIsErrorModal(true);
            return;
        }

        if (userRole.name === "representative" || userRole.name === "admin" || userRole.name === 'tutor') {
            const { key } = data as InterfaceFile;
            const { _id } = project;

            if (!key || !_id) {
                console.error("Invalid data or project");
                setIsErrorModal(true);
                return;
            }

            const requestRename = {
                key,
                project: _id,
            };

            try {
                const responseRenameFile = await putRemoveFile(requestRename).unwrap();
                if ("project" in responseRenameFile) {
                    setSucessText(t("zProjects.components.archivo.delete-success"));
                    setIsSuccessModal(true);
                    // Cerrar la modal de eliminar archivo
                    setOpenModalRemove(false);
                    // Cerrar la modal de éxito después de un breve delay
                    setTimeout(() => {
                        setIsSuccessModal(false);
                    }, 2000); // Ajusta el delay según sea necesario
                } else {
                    console.error("Unexpected response format", responseRenameFile);
                    setIsErrorModal(true);
                }
            } catch (error) {
                console.error("Error removing file", error);
                setIsErrorModal(true);
            }
        } else {
            setOpenInfoRemoveFile(true);
        }
    };

    const handleClickFileUpload = () => {
        setCreateFile(true);
    };

    const handleCreateFile = async (file: File[]) => {
        const { _id } = project;
        const requestData = new FormData();
        let fileName = file[0].name.split('.')[0];
        let extension = file[0].name.split('.')[1];
        fileName = fileName.replace(/[^a-zA-Z0-9]/g, '');


        requestData.append("file", file[0], fileName + '.' + extension);
        requestData.append("proyect", _id);
        //requestData.append("uploadedBy",user._id);

        setCreateFile(false);

        console.log(typeFile);

        try {
            switch (typeFile) {
                case "tutor":
                    const responseTutor = await putTutorReport(
                        requestData
                    ).unwrap();
                    break;

                case "report":
                    const responseReport = await putWorkReport(
                        requestData
                    ).unwrap();

                    break;

                case "work":
                    const responseWork = await putWorkFile(
                        requestData
                    ).unwrap();

                    break;

                case "suplementary":
                    const responseSuplementary = await putSuplementary(
                        requestData
                    ).unwrap();
                    break;
            }

        } catch (error) {
            setIsErrorModal(true);
        }

        setSucessText(t("zProjects.components.archivo.success-upload"));
        setIsSuccessModal(true);
    };

    return (
        <Container width={props.width || "100%"} heigth={props.heigth}>
            <input type="file" id="raised-button-file" hidden />

            <WrapperGeneral>
                <TripleContainer width="100%" padding="1% 3% 1% 3%">
                    {text3 && text3 === "Subir archivo" ? (
                        <LabelImg onClick={() => handleClickFileUpload()}>
                            <ComponentIcon style={{ width: props.imgWidth }} />
                        </LabelImg>
                    ) : (
                        <LabelImg>
                            <a
                                href={data && data.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                            >
                                <ComponentIcon
                                    style={{ width: props.imgWidth }}
                                />
                            </a>
                        </LabelImg>
                    )}

                    <DoubleContainer gridTemplateColumns="auto" display="block">
                        {text1.indexOf(".") > -1 ? (
                            <a
                                href={data && data.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                {" "}
                                <TitleWrapperGeneral>
                                    {text1}
                                </TitleWrapperGeneral>
                            </a>
                        ) : (
                            <TitleWrapperGeneral>{text1}</TitleWrapperGeneral>
                        )}

                        <SubTitleWrapperGeneral>{text2}</SubTitleWrapperGeneral>
                        {text3 && text3 === "Subir archivo" ? (
                            <label
                                onClick={() => handleClickFileUpload()}
                                style={{ textDecoration: props.textDecoration }}
                            >
                                {" "}
                                {text3}
                            </label>
                        ) : (
                            <SubTitleWrapperGeneral>
                                {text3}
                            </SubTitleWrapperGeneral>
                        )}
                    </DoubleContainer>

                    {ComponentAction.more ? (
                        <Dropdown>
                            <ComponentAction.element
                                style={{ margin: props.margin }}
                                onClick={() => setHidden(!hidden)}
                            />
                            <MenuAnimation
                                right={true}
                                hidden={hidden}
                                toggle={() => setHidden(!hidden)}
                            >
                                {ComponentAction.menuItems.map(
                                    (items, index) => {
                                        return (
                                            <DropdownItem
                                                value={items.action}
                                                onClick={
                                                    items.action === "update"
                                                        ? () =>
                                                            setOpenModalForm(
                                                                true
                                                            )
                                                        : () =>
                                                            setOpenModalRemove(
                                                                true
                                                            )
                                                }
                                                key={`${items.action}/${index}`}
                                            >
                                                {items.text}
                                            </DropdownItem>
                                        );
                                    }
                                )}
                            </MenuAnimation>
                        </Dropdown>
                    ) : (
                        <>
                            <ComponentAction.element
                                style={{ margin: props.margin }}
                                onClick={() => setOpenInfo(true)}
                            />
                            <BasicModal
                                open={openInfo}
                                setOpenModal={setOpenInfo}
                                text={
                                    ComponentAction.info
                                        .content as Array<string>
                                }
                                title={ComponentAction.info.title as string}
                            />
                        </>
                    )}
                </TripleContainer>

                {/* Modal add file */}
                <DropzoneDialog
                    acceptedFiles={[
                        ".pdf",
                        ".doc",
                        ".docx",
                        ".mp3",
                        ".mp4",
                        ".avi",
                        ".xls",
                        ".xlsx",
                        ".pptx",
                        ".ppt",
                        ".jpg",
                        ".jpeg",
                        ".png",
                        ".gif",
                        ".bmp",
                        ".svg",
                        ".txt",
                        ".rtf",
                        ".odt",
                        ".ods",
                        ".odp",
                        ".zip",
                        ".rar",
                        ".7z",
                        ".wav",
                        ".flac",
                        ".mkv",
                        ".mov"
                    ]}
                    cancelButtonText={t("zProjects.components.archivo.cancel")}
                    submitButtonText={t("zProjects.components.archivo.send")}
                    maxFileSize={1000000000}
                    open={createFile}
                    onClose={() => setCreateFile(false)}
                    onSave={(files) => {
                        handleCreateFile(files);
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    dropzoneText={t("zProjects.components.archivo.click")}
                    dialogTitle={t("zProjects.components.archivo.upload-file")}
                />

                {/* Modal rename file */}
                <ModalGeneric
                    isOpen={openForm}
                    handleClose={() => setOpenModalForm(false)}
                >
                    <form onSubmit={handleSubmit(handleRenameFile)}>
                        <DialogContent>
                            <WrapperInput>
                                <LabelInput>
                                    {t("zProjects.components.archivo.change-name-file")}
                                </LabelInput>
                                <SubLabelInput>
                                    {t("zProjects.components.archivo.example")}
                                </SubLabelInput>

                                <Controller
                                    name={"nameFile"}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            onChange={onChange}
                                            value={value}
                                            variant="outlined"
                                            fullWidth
                                            autoFocus
                                            margin="dense"
                                            error={!!error}
                                            helperText={
                                                error ? error.message : null
                                            }
                                        />
                                    )}
                                />
                            </WrapperInput>
                        </DialogContent>
                        <DialogActions>
                            <DoubleContainer width="100%" padding="0 3% 3% 3%">
                                <ButtonComponent
                                    className="btn-hover"
                                    type="button"
                                    onClick={() => setOpenModalForm(false)}
                                >
                                    {t("zProjects.components.form.projectRepresentative.cancel")}
                                </ButtonComponent>
                                <ButtonComponent className="btn-hover">
                                    {t("zProjects.components.form.projectRepresentative.rename")}
                                </ButtonComponent>
                            </DoubleContainer>
                        </DialogActions>
                    </form>

                    <Loading open={isLoadingRename} />
                </ModalGeneric>

                {/* Modal questing remove file */}
                <ModalAlert
                    isOpen={openRemove}
                    handleClose={() => setOpenModalRemove(false)}
                >
                    <DialogTitle>{t("zProjects.components.archivo.delete-file")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t("zProjects.components.archivo.ask-delete")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DoubleContainer width="100%" padding="0 3% 3% 3%">
                            <ButtonComponent
                                className="btn-hover"
                                type="button"
                                onClick={() => setOpenModalRemove(false)}
                            >
                                {t("zProjects.components.form.projectRepresentative.cancel")}
                            </ButtonComponent>
                            <ButtonComponent
                                className="btn-hover"
                                type="button"
                                onClick={() => handleRemoveFile()}
                            >
                                {t("zProjects.components.archivo.delete")}
                            </ButtonComponent>
                        </DoubleContainer>
                    </DialogActions>
                    <BasicModal
                        open={openInfoRemoveFile}
                        setOpenModal={setOpenInfoRemoveFile}
                        text={[t("zProjects.components.archivo.action-repre")]}
                        title={t("zProjects.components.archivo.notice")}
                    />
                    <Loading open={isLoadingRemove} />
                </ModalAlert>

                {/* Alerts messages response api */}
                <AlertComponent
                    verify={'error'}
                    open={isErrorModal}
                    onClose={() => setIsErrorModal(false)}
                    text={t("zProjects.components.archivo.error-text")}
                />
                <AlertComponent
                    verify={'success'}
                    open={isSuccessModal}
                    onClose={() => setIsSuccessModal(false)}
                    text={`${sucessText}`}
                />

                {/* loading for files */}
                <Loading
                    open={
                        isLoadingTutorReport ||
                        isLoadingWorkReport ||
                        isLoadingWorkFile ||
                        isLoadingSuplementary
                    }
                />
            </WrapperGeneral>
        </Container>
    );
};
