import { Mobile, Desktop, Tablet } from '../../utils/responsive';

//Form
import { FormProfile } from '../components/form/profile'



export const Profile = () => {
  return (
    <>
    <Mobile>
        <FormProfile key={'profileMobile'} width='80%;' heigth='auto' />
    </Mobile>
    <Tablet>
        <FormProfile key={'profileTablet'} width='65%;' heigth='auto' />
    </Tablet>
    <Desktop>
        <FormProfile key={'profileDesktop'} width='50%;' heigth='auto' />
            
    </Desktop>

    </>
  )
}
