//import modules react
import { Skeleton } from "@mui/material";

//styles
import { WrapperInput, LabelInput } from "../zUsers/styles/Input";

export const SkeletonInputComponent = () => {
    return (
        <WrapperInput>
            <LabelInput>
                <Skeleton width={"20%"} variant="text" />
            </LabelInput>
            <Skeleton variant="rectangular" width={"100%"} height={40} />
        </WrapperInput>
    );
};
