import { AppRouter } from './eustory/routes';
import { store } from './eustory/app/store'
import { Provider } from 'react-redux';

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Loading } from './eustory/components/loading';
import React, { useState, ChangeEvent, FormEvent } from 'react';

//style
import { Title, Container } from "./eustory/styles/globalComponents";
import {
    Wrapper,
    DoubleContainer,
    ButtonComponent,
    WrapperButton,
} from "./eustory/styles/globalComponents";
import { Input, LabelInput, SubLabelInput, WrapperInput } from "./eustory/zUsers/styles/Input";


import AWS from 'aws-sdk';

function App() {

    const persistor = persistStore(store);
    const [underConstruction, setUnderConstruction] = useState(false);

    const [language, setLanguage] = useState('es');

    const switchLanguage = () => {
        setLanguage((prevLanguage) => (prevLanguage === 'es' ? 'pt' : 'es'));
    };

    const containerStyles = {
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        width: '50%',
        margin: '0 auto',
        fontFamily: "'Poppins', Helvetica, Arial, Lucida, sans-serif",
    };


    const headingStyles = {
        fontSize: '24px',
        marginBottom: '10px',
    };

    const imageStyles = {
        top: '10px', // Ajustamos la distancia desde la parte superior
        left: '10px', // Ajustamos la distancia desde la izquierda
        width: '170px',
        height: '70px',
    };

    const buttonStyles = {
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    };

    const languageSwitchStyles = {
        marginTop: '20px',
    };

    const languageButtonStyles = {
        padding: '5px 10px',
        border: 'none',
        backgroundColor: 'transparent',
        color: '#007bff',
        cursor: 'pointer',
    };


    return (
        <Container width={'100%'} heigth={'auto'}>
            <div className="App">

                {underConstruction ? (

                    <div style={containerStyles}>
                        <div style={languageSwitchStyles}>
                            <button style={languageButtonStyles} onClick={switchLanguage}>
                                {language === 'es' ? 'Português' : 'Español'}
                            </button>
                        </div>

                        <a>
                            <img src="/static/media/eustory.5e3f582e.webp" alt="Logo" style={imageStyles} />
                        </a>

                        <h1 style={headingStyles}>{language === 'es' ? '¡Enhorabuena por tu decisión de participar en el concurso de historia para jóvenes Eustory Iberia!' : 'Parabéns pela sua decisão de participar no concurso de história para jovens Eustory Iberia!'}</h1>
                        <div>
                            <p>
                                {language === 'es'
                                    ? 'Esperamos que disfrutes mucho el proceso, escríbenos a '
                                    : 'Esperamos que aproveites muito o processo, escreve-nos ou para '}
                                <a href="mailto:concurso@eustory.es">concurso@eustory.es</a>
                            </p>
                            <p>

                                {language === 'es'
                                    ? 'Una vez realizado el trabajo, deberás enviarnos todos los documentos - trabajo, informe de procedimiento, y en caso haberlo, informe del tutor - a la misma dirección de correo electrónico o, si excede el tamaño admitido, por WeTransfer, Dropbox, etc.'
                                    : 'Depois de concluir o trabalho, você deve nos enviar todos os documentos - trabalho, relatório de procedimento e, se houver, relatório do tutor - para o mesmo endereço de e-mail ou, se exceder o tamanho permitido, por meio de WeTransfer, Dropbox, etc.'}

                            </p>
                            <p>
                                {language === 'es'
                                    ? 'Muchas gracias, y ¡mucho ánimo!'
                                    : 'Muito obrigado e muita sorte!'}
                            </p>
                        </div>
                        <hr style={{ margin: '2rem 0' }} /> {/* Línea separadora */}

                    </div>

                ) : (
                    <div>
                        <Provider store={store}>
                            <PersistGate loading={<Loading open={true} />} persistor={persistor}>
                                <AppRouter />
                            </PersistGate>
                        </Provider>
                    </div>
                )}

            </div>
        </Container>


    );
}



export default App;
