import { TFunction } from "react-i18next";
import * as Yup from "yup";
import {
    validateInputEmail,
    validateInputPhone,
    validateOnlyLetters,
    validatePassword,
    validateDigitLettersAndSimbols,
    validateOnlyLettersForName,
    validateDate
} from "../../utils/validatesInput";

import { DATA_ROL } from '../../data/data';

const getRoleNameById = (roleId: string) => {
    const role = DATA_ROL.find((item) => item._id === roleId);
    return role ? role.name : '';
};

export const schemaRegister = (t: TFunction<"global", undefined>) => Yup.object().shape(
    {
        email: Yup.string()
            .email(t("zUsers.validators.schemaRegister.email-inv"))
            .required(t("zUsers.validators.schemaRegister.email-req"))
            .test(t("zUsers.validators.schemaRegister.email"), t("zUsers.validators.schemaRegister.invalid"), (value: string | undefined) =>
                validateInputEmail(value)
            ),
        firstName: Yup.string().trim()
            .required(t("zUsers.validators.schemaRegister.name-req"))
            .min(3, t("zUsers.validators.schemaRegister.char"))
            .test(
                "only-letters",
                t("zUsers.validators.schemaRegister.only"),
                (value: string | undefined) => validateOnlyLettersForName(value)
            ),
        lastName: Yup.string().trim()
            .required(
                t("zUsers.validators.schemaRegister.last-req")
            )
            .min(3, t("zUsers.validators.schemaRegister.char"))
            .test(
                "only-letters",
                t("zUsers.validators.schemaRegister.only"),
                (value: string | undefined) => validateOnlyLettersForName(value)
            ),
        /*phone: Yup.string().when(
            "phone",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string().test(
                            t("zUsers.validators.schemaRegister.only-d"),
                            t("zUsers.validators.schemaRegister.only-d-text"),
                            (value: string | undefined) =>
                                validateInputPhone(value)
                        );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),*/
        phone: Yup.string()
            .when('phone', {
                is: (value: string | undefined) => value && value.length > 0,
                then: Yup.string()
                    .test(
                        t("zUsers.validators.schemaRegister.only-d"),
                        t("zUsers.validators.schemaRegister.only-d-text"),
                        (value: string | undefined) => validateInputPhone(value)
                    )
            }),
        birthday: Yup.string()
            .nullable() // Permite que el valor sea nulo
            .required(t("zUsers.validators.schemaRegister.birthday-req")) // Mensaje personalizado cuando el campo está vacío
            .test(
                "invalid-date",
                t("zUsers.validators.schemaRegister.birthDate-invalid"), // Mensaje personalizado cuando la fecha no es válida
                (value: string | null) => value === null || validateDate(value)
            ),
        role: Yup.string().required(t("zUsers.validators.schemaRegister.rol-req")),
        grade: Yup.string().trim()
            .when(['grade', 'role'], {
                is: (value: string | undefined, role: string) => {
                    return getRoleNameById(role) === 'representative'
                },
                then: Yup.string()
                    .required(t("zUsers.validators.schemaRegister.role"))
                    .min(3, t("zUsers.validators.schemaRegister.char"))
                    .test(
                        "only-letters",
                        t("zUsers.validators.schemaRegister.only"),
                        (value: string | undefined) => validateDigitLettersAndSimbols(value)
                    )
            })
            .when('grade', {
                is: (value: string | undefined) => value && value.length > 0,
                then: Yup.string()
                    .min(3, t("zUsers.validators.schemaRegister.char"))
                    .test(
                        "only-letters",
                        t("zUsers.validators.schemaRegister.only"),
                        (value: string | undefined) => validateDigitLettersAndSimbols(value)
                    )
            }),

        /*grade: Yup.string().trim()
            .when('grade', {
                is: (value: string | undefined) => value && value.length > 0,
                then: Yup.string()
                    .min(3, t("zUsers.validators.schemaRegister.char"))
                    .test(
                        "only-letters",
                        t("zUsers.validators.schemaRegister.only"),
                        (value: string | undefined) => validateDigitLettersAndSimbols(value)
                    )
            }),*/
        /*grade: Yup.string()
            .when(
                "grade",
                (val: string | any[], schema: any) => {
                    if (val) {
                        if (val.length > 0) {
                            return Yup.string().test(
                                t("zUsers.validators.schemaRegister.only"),
                                t("zUsers.validators.schemaRegister.char"),
                                (value: string | undefined) =>
                                    validateDigitLettersAndSimbols(value)
                            );
                        } else {
                            return Yup.string().notRequired();
                        }
                    } else {
                        return Yup.string().notRequired();
                    }
                }
            ),*/
        password: Yup.string()
            .min(6, t("zUsers.validators.schemaRegister.password-char"))
            .required(t("zUsers.validators.schemaRegister.password-req"))
            .test(
                t("zUsers.validators.schemaRegister.masc-num"),
                t("zUsers.validators.schemaRegister.letters-num"),
                (value: string | undefined) => validatePassword(value)
            ),
        confirmPassword: Yup.string()
            .required(t("zUsers.validators.schemaRegister.check-password"))
            .oneOf([Yup.ref("password"), null], t("zUsers.validators.schemaRegister.agree-password")),
        schoolCenter: Yup.string()
            .required(
                t("zUsers.validators.schemaRegister.schoolCenter-req")
            )
            .test(
                t("zUsers.validators.schemaRegister.only"),
                t("zUsers.validators.schemaRegister.char"),
                (value: string | undefined) =>
                    validateOnlyLetters(value)
            ),
        schoolEmail: Yup.string()
            .required(t("zUsers.validators.schemaRegister.school-email-req"))
            .test(t("zUsers.validators.schemaRegister.email"), t("zUsers.validators.schemaRegister.invalid"), (value: string | undefined) =>
                validateInputEmail(value)
            ),
        population: Yup.string()
            .required(
                t("zUsers.validators.schemaRegister.population-req")
            )
            .test(
                t("zUsers.validators.schemaRegister.only"),
                t("zUsers.validators.schemaRegister.char"),
                (value: string | undefined) =>
                    validateOnlyLetters(value)
            ),
        street: Yup.string().when(
            "street",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string().test(
                            t("zUsers.validators.schemaRegister.only"),
                            t("zUsers.validators.schemaRegister.char"),
                            (value: string | undefined) =>
                                validateDigitLettersAndSimbols(value)
                        );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        city: Yup.string().when("city", (val: string | any[], schema: any) => {
            if (val) {
                if (val.length > 0) {
                    return Yup.string().test(
                        t("zUsers.validators.schemaRegister.only"),
                        t("zUsers.validators.schemaRegister.char"),
                        (value: string | undefined) =>
                            validateOnlyLetters(value)
                    );
                } else {
                    return Yup.string().notRequired();
                }
            } else {
                return Yup.string().notRequired();
            }
        }),
        province: Yup.string().when(
            "province",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string().test(
                            t("zUsers.validators.schemaRegister.only"),
                            t("zUsers.validators.schemaRegister.char"),
                            (value: string | undefined) =>
                                validateOnlyLetters(value)
                        );
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        country: Yup.string().when(
            "country",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string();
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        zipCode: Yup.string().when(
            "zipCode",
            (val: string | any[], schema: any) => {
                if (val) {
                    if (val.length > 0) {
                        return Yup.string();
                    } else {
                        return Yup.string().notRequired();
                    }
                } else {
                    return Yup.string().notRequired();
                }
            }
        ),
        roleType: Yup.string()
            .when('role', {
                is: (role: string) => role === 'tutor',
                then: Yup.string().required(t("zUsers.validators.schemaRegister.roleType")).test(t("zUsers.validators.schemaRegister.roleType"), t("zUsers.validators.schemaRegister.invalid"), (value: string | undefined) =>
                    validateDigitLettersAndSimbols(value)
                ),
                otherwise: Yup.string().notRequired()
            }),
        //role: Yup.string().required(t("zUsers.validators.schemaRegister.rol-req")),
        polity: Yup.boolean()
            .required(
                t("zUsers.validators.schemaRegister.cookies")
            ).test(
                t("zUsers.validators.schemaRegister.accept"),
                t("zUsers.validators.schemaRegister.cookies"),
                (value: boolean | undefined) => {
                    return value ? true : false
                }
            ),

    },
    [
        ["phone", "phone"],
        ["street", "street"],
        ["city", "city"],
        ["grade", "grade"],
        ["province", "province"],
        ["zipCode", "zipCode"],
        ["country", "country"],
        ["birthday", "birthday"],
        ["role","role"]
    ]
);
