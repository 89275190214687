//styles react
import styled  from 'styled-components'

//iconos
import { FaEdit } from 'react-icons/fa';
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { AiOutlineInfoCircle } from 'react-icons/ai' 
import { VscNewFile } from 'react-icons/vsc' 

//styles info
import { dataImg, colors, fonts, dataImgBase } from '../../styles/Info';

//interfaces
import { InterfaceStyle } from '../../interfaces/interface';
import { FiMoreVertical } from 'react-icons/fi';

export const EditIcon = styled(FaEdit)<InterfaceStyle>`
    width: ${(props) => props.width || 'auto'};
    ${dataImgBase}
    `
export const InfoIcon = styled(AiOutlineInfoCircle)<InterfaceStyle>`
    width: ${(props) => props.width || 'auto'};
    ${dataImgBase}
`
export const CheckIcon = styled(IoMdCheckmarkCircle)<InterfaceStyle>`
    width: ${(props) => props.width || '70%'};
    ${dataImgBase}
`
export const FileUploadIcon = styled(VscNewFile)<InterfaceStyle>`
    width: ${(props) => props.width || '70%'};
    ${dataImg}
`
export const TitleProject = styled.h3`    
    text-decoration: underline;
`

export const LabelImg = styled.label<InterfaceStyle>`
    width: ${(props) => props.width || '70%'};
`

export const MoreIcon = styled(FiMoreVertical)<InterfaceStyle>`
    ${dataImgBase}
`
