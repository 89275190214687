
//utils
import { Mobile, Desktop, Tablet } from '../../utils/responsive'

//components
import { LogsForm } from '../components/form/logs'

//data
import { dataLogs } from '../data/logs'


export const Logs = () => {
  return (
    <>
    <Mobile>
        <LogsForm    
            props= { {width:'80%;', heigth:'auto'} } 
        />
    </Mobile>
    <Tablet>
        <LogsForm    


            props= { {width:'65%;', heigth:'auto'} } 
        />
    </Tablet>
    <Desktop>
        <LogsForm    
            props= { {width:'50%;', heigth:'auto'} } 
        />
            
    </Desktop>

    </>
  )
}
