//modules react
import { Link as RouteLink, useParams } from "react-router-dom";
import Link from "@mui/material/Link";

//style
import {
    Wrapper,
    DoubleContainer,
    TitleWrapperGeneral,
    ContainerGeneral,
    ReturnIco,
    Container,
    TitleWrapper,
} from "../../../styles/globalComponents";

//components
import { History } from "../history";

//interace
import { InterfaceHomeRenderStyle } from "../../interfaces/logs";
import { useAppSelector } from "../../../hooks/redux";
import { selectUser } from "../../../zUsers/slices/auth";
import { InterfaceRoleUser } from "../../../interfaces/user";
import { InterfaceProject } from "../../../interfaces/project";
import { useGetLogsQuery } from "../../api/logs";

//translate
import { useTranslation } from "react-i18next";



export const LogsForm = ({
    props,
}: InterfaceHomeRenderStyle) => {


    const { idProjectLogs, paramsNameLogs } = useParams();
    const nameProject = paramsNameLogs || ""

    const idProjectCache = idProjectLogs || ""
    //api
    const { currentData, isLoading } = useGetLogsQuery({id:idProjectCache})
    const [t, i18n] = useTranslation("global");
    

    return (
        <Container width={props.width} heigth={props.heigth}>
            <Wrapper>
                <DoubleContainer
                    gridTemplateColumns="auto"
                    display="grid"
                    width="100%">   
                    
                    <TitleWrapper>
                        <RouteLink to="#" onClick={() => window.history.back()}>
                            <Link color="black" underline="hover">
                                <ReturnIco /> {t("zLogs.components.form.logs.back")}
                            </Link>
                        </RouteLink>
                    </TitleWrapper>
                    
                    <TitleWrapper> {nameProject} </TitleWrapper>
                </DoubleContainer>
                <ContainerGeneral width="90%;" heigth={props.heigth}>
                    <TitleWrapperGeneral padding="0">
                        {t("zLogs.components.form.logs.change-text")}
                    </TitleWrapperGeneral>
                    { currentData && currentData.trace && currentData.trace.map(({ message}) => {
                        return <History props={{ width:'auto' }} text={message} />;
                    })}
                </ContainerGeneral>
            </Wrapper>
        </Container>
    );
};
