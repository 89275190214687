import { Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, Ref } from "react";

//onterfaces
import { InterfaceModalGeneric } from "../interfaces/interface";

const Transition = forwardRef( (
    props: TransitionProps & {
        children: ReactElement<any, any>;
  },
    ref: Ref<unknown>,
) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalGeneric = ({
    isOpen,
    handleClose,
    children,
    maxWidth,
    fullScreen = true,
}: InterfaceModalGeneric) => {
    
    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            onClose={handleClose}
            maxWidth={maxWidth}
            fullWidth={fullScreen}
        >
            {children}
        </Dialog>
    );
};
