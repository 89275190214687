import { TFunction } from "react-i18next";
import { 

    InterfaceErrorsInputRecovery, 
    InterfaceRecoveryArray

} from "../interfaces/recovery";


export const dataRecovery = (t: TFunction<"global", undefined>,
        errors: InterfaceErrorsInputRecovery
    ): InterfaceRecoveryArray => [

    
    {
        registerLabel: 'email',
        errors: errors.email,
        label: t("zUsers.data.recovery.email"),
        subLabel: t("zUsers.data.recovery.email-sL"),
        type: 'email'
    }
    

]