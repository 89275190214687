// modules react
import { Dialog, DialogContent, DialogContentText, DialogTitle, Link, Typography } from "@mui/material";
import { useState } from "react";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

//styles
import {
    Container,
    Wrapper,
    WrapperButton,
} from "../../../styles/globalComponents";
import { ErrorMessage, Input, SubLabelInput } from "../../styles/Input";
import { ButtonRegister, LostPassword } from "../../styles/button";

//components
import { Loading } from "../../../components/loading";
import { AlertComponent } from "../../../components/alert";

//interfaces
import { InterfaceStyle } from "../../../interfaces/interface";
import { InterfaceLogin } from "../../interfaces/login";

//api
import { usePostSigninMutation } from "../../api/auth";

//translate
import { useTranslation } from "react-i18next";

//slices
import { singupAction } from "../../slices/auth";

//hooks
import { useAppDispatch } from "../../../hooks/redux";
import { BasicModal } from "../../../components/basicModal";

export const Loginn = (props: InterfaceStyle) => {
    const [t, i18n] = useTranslation("global");
    //Apis
    const [postSignin, { isLoading }] = usePostSigninMutation();

    //states
    const [alert, setAlert] = useState("");
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);


    //Forms
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<InterfaceLogin>({
        defaultValues: { email: "", password: "" },
    });

    //Navegate
    const navigate = useNavigate();

    //Event
    const dispatch = useAppDispatch();

    //Handles
    const handleSubmitLogin = async (data: InterfaceLogin) => {
        //dispatch(loginAction());

        try {
            const userReturn = await postSignin(data).unwrap();
            dispatch(singupAction(userReturn));
            return navigate("/");
        } catch (error: any) {
            if (typeof error === "object" && error && "status" in error) {
                if (error.status === 400) {
                    setIsErrorModal(true);
                    setAlert(t("zUsers.components.form.login.incorrect"));
                } else if (error.status === 401) {
                    if (error.data.errorMessage === "Account not active") {
                        setAlert(t("zUsers.components.form.login.user-inactive"));
                        setIsErrorModal(true);
                    } else if (
                        error.data.errorMessage === "Account not confirm"
                    ) {
                        setIsConfirmModal(true);
                    } else {
                        setAlert(t("zUsers.components.form.login.error"));
                        setIsErrorModal(true);
                    }
                } else {
                    setIsErrorModal(true);
                    setAlert(t("zUsers.components.form.login.error"));
                }
            } else {
                setIsErrorModal(true);
                setAlert(t("zUsers.components.form.login.error"));
            }
        }
    };

    return (
        <>
            <Container width={props.width} heigth={props.heigth}>
                <h1> {t("zUsers.components.form.login.login")} </h1>
                <Wrapper>
                    <form
                        autoComplete="off"
                        noValidate
                        onSubmit={handleSubmit(handleSubmitLogin)}
                    >
                        <div>
                            <h1> {t("zUsers.components.form.login.login")} </h1>
                        </div>
                        <h4>{t("zUsers.components.form.login.email")} </h4>
                        <Input
                            {...register("email")}
                            isError={!!errors.email}
                            autoFocus
                            className="form-control txt-input"
                            name="email"
                            type="email"
                            placeholder={t("zUsers.components.form.login.into-email")}
                        />
                        {errors.email && (
                            <ErrorMessage>{errors.email.message}</ErrorMessage>
                        )}
                        <div>
                            <h4>{t("zUsers.components.form.login.password")}</h4>
                            <Input
                                {...register("password")}
                                isError={!!errors.password}
                                className="form-control txt-input"
                                name="password"
                                type="password"
                                placeholder={t("zUsers.components.form.login.into-password")}
                            />
                            {errors.password && (
                                <ErrorMessage>
                                    {errors.password.message}
                                </ErrorMessage>
                            )}
                        </div>
                        <div>
                            <LostPassword to="/recovery">
                                <p>{t("zUsers.components.form.login.forget-password")}</p>
                            </LostPassword>
                        </div>
                        <SubLabelInput>
                            {t("zUsers.components.form.login.already-account")}{" "}
                            <RouteLink to="/auth/signup">
                                <Link underline="hover" color="black">
                                    {t("zUsers.components.form.login.sign-up")}
                                </Link>
                            </RouteLink>
                        </SubLabelInput>
                        <div>
                            <WrapperButton>
                                <ButtonRegister className="btn-hover">
                                    {t("zUsers.components.form.login.access")}
                                </ButtonRegister>
                            </WrapperButton>
                        </div>
                    </form>
                </Wrapper>
                <Loading open={isLoading} />

                {/* User bloqued */}
                <BasicModal
                    open={isConfirmModal}
                    setOpenModal={setIsConfirmModal}
                    text={[
                        t("zUsers.components.form.login.inactive-user"),
                    ]}
                    title={t("zUsers.components.form.login.notice")}
                />

                {/* Alerts Error */}
                <AlertComponent
                    verify={"error"}
                    open={isErrorModal}
                    onClose={() => setIsErrorModal(false)}
                    text={`${alert}`}
                />
            </Container>
        </>
    );
};
